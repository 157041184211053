import { ReorderStatus } from '@api/models/reorderAlimtalk/reorderAlimtalkModels';

// 필터 인덱스로 상태를 가져오는 함수
export const getStatusFromFilterIndex = (index: number): ReorderStatus | null => {
    switch (index) {
        case 0:
            return null; // 전체
        case 1:
            return ReorderStatus.STANDBY; // 전송전
        case 2:
            return ReorderStatus.SENT; // 전송됨
        case 3:
            return ReorderStatus.CANCELED; // 취소
        default:
            return null;
    }
};
