import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListTitle } from '@components/listTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import _, { last } from 'lodash';
import { useRecoilValue } from 'recoil';
import { selectedUserState } from '@recoils/oauth';
import 'react-calendar/dist/Calendar.css';
import { DoctorOnAPI } from '@api/request';
import { isRequestSucceed } from '@api/reponses';
import { ListSubTitle } from 'pages/Operator/ListPage/components';
import usePaginationStateHooks from '@hooks/common/usePaginationStateHooks';
import { useAlimtalkListQuery } from '@queries/alimtalk/useAlimtalkListQuery';
import { ITReorderAlimTalk } from '@api/models/reorderAlimtalk/reorderAlimtalkModels';
import { OoParamId } from '@api/params';
import { useSuspenseHook } from '@hooks/suspense';
import ReorderAlimTalkListTable from './ReorderAlimTalkListTable';
import { useChangeReorderMessageStatusMutate } from '@queries/reorderAlimtalk/useChangeReorderMessageStatusMutate';
import useToggle from '@hooks/common/useToggle';
import ConfirmModal from '@components/modal/basicModal/ConfirmModal';
import { useUpdateReorderMessageMutate } from '@queries/reorderAlimtalk/useUpdateReorderMessageMutate';
import { toast } from 'react-toastify';
import { getStatusFromFilterIndex } from './functions/getStatusFromFilter';

const ReorderAlimTalkManagePage = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [target, setTarget] = useState<string>('');
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>();
    const [reorderAlimtalkListData, setReorderAlimTalkListData] = useState<ITReorderAlimTalk[]>();
    const [reorderMessageId, setReorderMessageId] = useState('');
    const [selectedFilterReorderStatus, setSelectedFilterReorderStatus] = useState<number>(0);
    const [reorderFilterDropdown, setReorderFilterDropdown] = useState<boolean>(false);

    const [editStates, setEditStates] = useState<Record<string, boolean>>({});
    const [mobileValues, setMobileValues] = useState<Record<string, string>>({});

    const [onCancelReorderAlimtalkModal, setOnCancelReorderAlimtalkModal] = useToggle();
    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

    // const [searchValue, setSearchValue] = useState<string>('');
    // const rtdbList = useRecoilValue(unospayListState);

    const { setPage, currentPage, pageGroup, handlePageGroup, reorderStatus, setReorderStatus, setSearch, queryParams, setPageGroup, setCurrentPage } =
        usePaginationStateHooks(reorderAlimtalkListData);
    const { data: alimtalkList, isLoading: isAlimttalkLoading, refetch: refetchAlimtalk } = useAlimtalkListQuery(currentPage, 10, getStatusFromFilterIndex(reorderStatus));
    const { mutateAsync: changeReorderStatus, isLoading: isReorderStatusLoading } = useChangeReorderMessageStatusMutate();
    const { mutateAsync: updateReorderMessage, isLoading: isUpdateReorderMessageLoading } = useUpdateReorderMessageMutate();

    const selectedUser = useRecoilValue(selectedUserState);

    // target: alimtalkManage

    // useEffect(() => {
    //     const searchValue = queryParams.get('search') || '';
    //     setSearchValue(searchValue);
    // }, [queryParams.get('search')]);


    useEffect(() => {
        setReorderAlimTalkListData(alimtalkList?.alimtalks);
        setTotalCount(alimtalkList?.pagination?.totalCount);
        setTotalPages(alimtalkList?.pagination?.totalPages || 0);
    }, [alimtalkList]);

    useEffect(() => {
        const path = last(pathname.split('/') || '') || '';
        setTarget(path);
    }, [pathname]);

    const clickList = async (id: string) => {
        const findTargetResponse = async () => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.unospay.findById(new OoParamId(id));
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setStopLoading();
                setErrorStatus(e);
            }
        };

        const data = await findTargetResponse();

        navigate('/unospay/unospayOrderDetail', { state: { data: data, calendarParam: { id: '', beginTimestamp: '', endTimestamp: '', count: '' }, searchList: { userName: '' } } });
    };

    // 전송 현황 전송 취소 버튼 클릭
    const clickCancelReorderButton = (id: string) => {
        setOnCancelReorderAlimtalkModal();
        setReorderMessageId(id);
    };

    // 전송 취소 modal에서 취소 버튼
    const clickCancelButtonInReorderModal = () => {
        setOnCancelReorderAlimtalkModal();
        setReorderMessageId('');
    };

    // 전송 취소 modal에서 변경 버튼
    const clickChangeReorderStatusButton = async () => {
        const changeReorderStatusResponse = await changeReorderStatus({ id: reorderMessageId, status: 2 });
        if (changeReorderStatusResponse) {
            await refetchAlimtalk();
            setOnCancelReorderAlimtalkModal();
            setReorderMessageId('');
            toast.success('알림톡 전송이 취소되었습니다.');
        }
    };

    // reorder message update
    const handleUpdateReorder = async (id: string) => {
        const updateReorderStatusResponse = await updateReorderMessage({ id: id, mobile: mobileValues[id] });
        if (updateReorderStatusResponse) {
            await refetchAlimtalk();
            toast.success('전화번호가 변경되었습니다.');
            setEditStates({});
            setMobileValues({});
        }
    };

    // table 편집 버튼 클릭 처리
    const handleEditClick = (id: string) => {
        setEditStates((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    // table 입력값 변경 처리
    const handleInputChange = (id: string, value: string) => {
        if (/^[0-9-]{0,}$/g.test(value))
            setMobileValues((prev) => ({
                ...prev,
                [id]: value,
            }));
    };

    // 재구매 알림톡 전송 현황 Dropdown Func
    const handleReorderStateDropdown = (idx: number) => {
        setReorderStatus(idx);
        setCurrentPage(1);
        setPageGroup(0);

        queryParams.set('reorderState', idx.toString());
        queryParams.set('page', '1');
        queryParams.set('pageGroup', '0');

        navigate({ search: queryParams.toString() });
    };
    // // search button click handler
    // const clickSearchButton = () => {
    //     setSearch(searchValue);
    // };

    // // search value delete button click handler
    // const clickSearchValueDeleteButton = () => {
    //     setSearchValue('');
    //     setSearch('');
    //     // setPage(1, 0);
    // };

    return (
        <Layout>
            {onCancelReorderAlimtalkModal && (
                <ConfirmModal
                    title='알림톡 전송을 취소하시겠습니까?'
                    content='해당 알림톡이 더 이상 전송되지 않습니다.'
                    firstButton='아니오'
                    secondButton='예'
                    setIsLeaveModal={clickCancelButtonInReorderModal}
                    clickConfirmButton={clickChangeReorderStatusButton}
                />
            )}
            <ListTitle title='재구매 알림톡' totalCount={totalCount} titleWidth='17%'>
                <SearchContainer>
                    {/* <SearchInput searchValue={searchValue} onChange={setSearchValue} onChangeDeleteButton={clickSearchValueDeleteButton} handleSearchFilter={clickSearchButton} />
                    <BasicButton $width='100px' $height='40px' $type='outLine' onClick={clickRegisterButton}>
                        등록
                    </BasicButton> */}
                </SearchContainer>
            </ListTitle>
            <ListSubTitle
                target={target}
                level={selectedUser && selectedUser.user.level}
                selectedFilterReorderStatus={reorderStatus}
                reorderFilterDropdown={reorderFilterDropdown}
                setReorderFilterDropdown={setReorderFilterDropdown}
                handleDropdown={handleReorderStateDropdown}
            />
            {reorderAlimtalkListData ? (
                <ReorderAlimTalkListTable
                    data={reorderAlimtalkListData}
                    // totalCount={totalCount}
                    currentPage={currentPage}
                    onChangeCurrentPage={setPage}
                    onChangeSectionIdx={handlePageGroup}
                    sectionIndex={pageGroup}
                    totalPages={totalPages}
                    clickList={clickList}
                    clickChangeReorderStatusButton={clickCancelReorderButton}
                    handleUpdateReorder={handleUpdateReorder}
                    editStates={editStates}
                    mobileValues={mobileValues}
                    handleEditClick={handleEditClick}
                    handleInputChange={handleInputChange}
                />
            ) : (
                <NoListContainer>표시 할 내용이 없습니다.</NoListContainer>
            )}
        </Layout>
    );
};

export default ReorderAlimTalkManagePage;

const Layout = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 4px;
`;

const SearchContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 24px;
`;

const NoListContainer = styled.div`
    height: 100%;
    @media (min-height: 768px) {
        height: 585px;
    }

    @media (min-height: 1024px) {
        height: 75%;
    }
`;
