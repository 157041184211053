import { ChangeReorderMessageStatusRqData, ITReorderAlimTalk, UpdateReorderMessageRqData } from '@api/models/reorderAlimtalk/reorderAlimtalkModels';
import { IOoDataResponse } from '@api/reponses';
import { AxiosInstance } from 'axios';
import { endPoint } from './base';

class ReorderAlimtalkAPI {
    constructor(private readonly axiosInstance: AxiosInstance) {}

    async changeReorderMessageStatus(rqData: ChangeReorderMessageStatusRqData): Promise<IOoDataResponse<ITReorderAlimTalk>> {
        return await this.axiosInstance.post<IOoDataResponse<ITReorderAlimTalk>>(endPoint.reorderMessage.changeReorderMessageStatus, rqData);
    }

    async updateReorderMessage(rqData: UpdateReorderMessageRqData): Promise<IOoDataResponse<ITReorderAlimTalk>> {
        return await this.axiosInstance.put<IOoDataResponse<ITReorderAlimTalk>>(endPoint.reorderMessage.updateReorderMessage, rqData);
    }
}

export { ReorderAlimtalkAPI };
