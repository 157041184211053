import { IOoUnospayDelivery, IOoUnospaySalesMenu, OoDeliveryStatus, OoProvider } from '@api/models'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import { ITProduct } from '@api/models/product/product';
const ExcelJS = require("exceljs");

/**
 * @description 신앙촌 발주서 양식 다운로드
 */

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
    const model = new ExcelExportModel();
                  model.name = e.recipientName;
                  model.address1 = e.address;
                  model.address2 = e.addressDetail;
                  model.tel1 = e.phoneNumber?.replace("\t", "").split('-').join('');
                  model.tel2 = e.mobile?.replace("\t", "").split('-').join('');
                  model.zipCode = e.zipCode;
                  model.memo = e.memo;
                  model.subMemo = e.subMemo;
                  model.quantity = "1";
                  model.deliveryCost = e.id;
    return model;
  }

const  findProductInfo = (productList:ITProduct[], salesItem: IOoUnospaySalesMenu) => {
    const productInfo = productList.filter(item => item.externalCode === `${salesItem.externalCode}`)[0];
    return productInfo;
}

const ssentakOrder = async (list:IOoUnospayDelivery[], productList:ITProduct[]) => {
    
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.ssentakProvider));
    let data_array: Array<ExcelExportModel> = [];

    console.log('excelList :: ', excelList);
    
    excelList.forEach((e, index) => {
        if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
            let orderNumberCnt = 0;
            let orderNumberCntStr = moment(e.timestamp).format('YYYYMMDD') + String(orderNumberCnt + 1).padStart(4, '0');
            e.salesMenu?.forEach(salesItem => {
                const findProductData = findProductInfo(productList, salesItem);
                let model = createModel(e, salesItem);
                if(!_.isUndefined(salesItem.salesOption) && salesItem.salesOption.length > 0){
                    const optionInfo = findProductData.optionList?.find(item => item.optionCode === salesItem.salesOption![0].externalCode);
                    model.product = optionInfo?.optionOrderName;
                    model.productCode = _.split(optionInfo?.optionOrderCode, '/')[0];
                    model.unit = _.split(optionInfo?.optionOrderCode, '/')[1];
                    model.orderNumber = orderNumberCntStr;
                    data_array.push(model);
                } else {
                    model.product = findProductData.productName;
                    model.productCode = _.split(findProductData.orderProductCode, '/')[0];
                    model.unit = _.split(findProductData.orderProductCode, '/')[1];
                    model.orderNumber = orderNumberCntStr;
                    data_array.push(model);
                }
            })
            orderNumberCnt++;
        }
    });

    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferSsentak = await (await fetch("/assets/ssentak.xlsx")).arrayBuffer();
    const workbookSsentak = new ExcelJS.Workbook();  
    await workbookSsentak.xlsx.load(arrayBufferSsentak);
    const ws_ssentak = workbookSsentak.getWorksheet("Sheet1");
    console.log(data_array);
    
    data_array.map((data: ExcelExportModel, index: number) => {
        const row = ws_ssentak.getRow(index + 2);
        [
            "",
            "",
            data.orderNumber,
            data.product,
            data.quantity,
            data.name,
            data.name,
            data.tel2 ? `${data.tel2?.substring(0, 3)}-${data.tel2?.substring(3, 7)}-${data.tel2?.substring(7)}` : "",
            "",
            data.zipCode,
            `${data.address1} ${data.address2}`,
            ""
        ].forEach((value , index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
            cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            };
        })
    });
    const excelBuffer = await workbookSsentak.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName+"_"+moment().format("YYYYMMDD")+ "(쎈닭)" + excelFileExtension
    );
}

export default ssentakOrder