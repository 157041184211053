import 'moment/locale/ko';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DoctorOnAPI, userLevel } from './api';
import { selectedUserState } from './recoils';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import {
    DiagnosisDashBoardPage,
    DiagnosisDetailPage,
    DoctorDashBoardPage,
    DoctorDetailInfoDetailPage,
    DoctorDiagnosisDetailPage,
    DoctorInfoPage,
    DoctorInfoRegisterPage,
    DoctorReservation,
    HospitalInfoDetailPage,
    HospitalInfoRegisterPage,
    ListPage,
    PharmacyDashBoardPage,
    PharmacyDiagnosisDetail,
    PharmacyInfo,
    PharmacyInfoDetail,
    PharmacyInfoRegisterPage,
    PharmacyLedgerPage,
    UnosPayDashBoardPage,
    UnospayOrderDetailPage,
} from './pages';
import { ThemeProvider } from 'styled-components';
import { theme } from '@styles/theme';
import { Navigate, useRoutes } from 'react-router-dom';
import { UnospayDeliveryListPopUp } from '@components/unospayDeliveryListPopUp';
import { HeaderLayout, HeaderMarginLayout } from '@components/layout';
import { SignInPage } from './pages/SignIn';
import { UnospayHdOrderPopUp } from '@components/unospayHdOrderData';
import AllMealsScheduleListPopUp from 'pages/Operator/AllMealsScheduleListPopUp';
import MealsScheduleItemPopup from 'pages/Operator/MealsScheduleItemPopup';
import DoctorCalculateBoardPage from 'pages/Doctor/CalculateBoardPage';
import ProductSettingPage from 'pages/Operator/ProductSettingPage';
import UnospayAddCompanyPopup from '@components/unospayAddCompanyPopup';
import CalculateDetailPage from 'pages/Operator/CalculateDetailPage';
import CalculateMonthDetail from 'pages/Operator/CalculateMonthDetail';
import DoctorCalculateMonthDetail from 'pages/Doctor/CalculateMonthDetail';
import CalculatePage from 'pages/Operator/CalculatePage';
import { CalculateHeaderLayout } from '@components/layout/headerContainer/CalculateHeader';
import ProductPriceList from 'pages/Doctor/ProductPriceListPage';
import ProductInfoDetail from 'pages/Doctor/ProductDetailPage';
import { GlobalStyles } from '@styles/globalStyles';
import AddClientPage from 'pages/Operator/AddClientPage/AddClientPage';
import ClientListPage from 'pages/Operator/ClientListPage/ClientListPage';
import ProductListPage from 'pages/Operator/ProductListPage/ProductListPage';
import AddProductPage from 'pages/Operator/AddProductPage/AddProductPage';
import ClientNoticeListPage from 'pages/Operator/ClientNoticeListPage/ClientNoticeListPage';
import AddClientNoticePage from 'pages/Operator/AddClientNoticePage/AddClientNoticePage';
import Notices from 'pages/Operator/NoticesPage/NoticesPage';
import DetailNoticePage from 'pages/Operator/DetailNoticePage/DetailNoticePage';
import CalculateDashboardPage from 'pages/Operator/CalculateDashboardPage/CalculateDashboardPage';
import CalculateListPage from 'pages/Operator/CalculateListPage/CalculateListPage';
import CalculateDataListPage from 'pages/Operator/CalculateDataListPage/CalculateDataListPage';
import ReorderAlimTalkManagePage from 'pages/Operator/ReorderAlimtalkManage/ReorderAlimTalkManagePage';

function App() {
    const selectedUser = useRecoilValue(selectedUserState);

    const getToken = selectedUser?.bearerToken;
    if (!_.isUndefined(getToken)) DoctorOnAPI.shared.setBearerToken(getToken);

    const getOperatorRoutes = () => {
        return [
            {
                path: '/',
                element: <HeaderLayout children={<SignInPage />} />,
            },
            {
                path: '/home',
                element: <HeaderLayout children={<DiagnosisDashBoardPage />} />,
            },
            {
                path: '/doctor',
                element: <HeaderLayout children={<ListPage />} />,
            },
            {
                path: '/doctorDetail',
                element: <HeaderLayout children={<DoctorDetailInfoDetailPage />} />,
            },
            {
                path: '/hospital',
                element: <HeaderLayout children={<ListPage />} />,
            },
            {
                path: '/calculate',
                element: <HeaderLayout children={<CalculatePage />} />,
            },
            {
                path: '/calculateDetail',
                element: <HeaderLayout children={<CalculateDetailPage />} />,
            },
            {
                path: '/calculateMonthDetail/:year',
                element: <HeaderLayout children={<CalculateMonthDetail />} />,
            },
            {
                path: '/productsSettings',
                element: <HeaderLayout children={<ProductSettingPage />} />,
            },
            {
                path: '/hospitalDetail',
                element: <HeaderLayout children={<HospitalInfoDetailPage />} />,
            },
            {
                path: '/addHospitalInfo',
                element: <HeaderLayout children={<HospitalInfoRegisterPage />} />,
            },
            {
                path: '/pharmacy',
                element: <HeaderLayout children={<ListPage />} />,
            },
            {
                path: '/pharmacyDetail',
                element: <HeaderLayout children={<PharmacyInfoDetail />} />,
            },
            {
                path: '/addPharmacyInfo',
                element: <HeaderLayout children={<PharmacyInfoRegisterPage />} />,
            },
            {
                path: '/addDoctorInfo',
                element: <HeaderLayout children={<DoctorInfoRegisterPage />} />,
            },
            {
                path: '/diagnosisDetail',
                element: <HeaderLayout children={<DiagnosisDetailPage />} />,
            },
            {
                path: '/unospay',
                element: <HeaderLayout children={<UnosPayDashBoardPage />} />,
            },
            {
                path: '/unospayOrderDetail',
                element: <HeaderLayout children={<UnospayOrderDetailPage />} />,
            },
            {
                path: '/unospayOrderDetail/:id',
                element: <UnospayDeliveryListPopUp />,
            },
            {
                path: '/unospayAllMealsSchedule',
                element: <AllMealsScheduleListPopUp />,
            },
            {
                path: '/unospayMealDetail/:mealCode/:itemCode/:searchDate',
                element: <MealsScheduleItemPopup />,
            },
            {
                path: '/unospayHdOrder/:date',
                element: <UnospayHdOrderPopUp />,
            },
            {
                path: '/companyPopup',
                element: <UnospayAddCompanyPopup />,
            },
            {
                path: '*',
                element: <Navigate to='/home' />,
            },
        ];
    };

    const getManagerRoutes = () => {
        if (selectedUser?.user.id === 'Ww39YIj5JN6zgKvwwYQW' || selectedUser?.user.id === 'ZjIQqcyDr2DRT1nWL5hC') {
            return [
                {
                    path: '/',
                    element: <HeaderLayout children={<SignInPage />} />,
                },
                {
                    path: '/home',
                    element: <HeaderLayout children={<DiagnosisDashBoardPage />} />,
                },
                {
                    path: '/doctor',
                    element: <HeaderLayout children={<ListPage />} />,
                },
                {
                    path: '/doctorDetail',
                    element: <HeaderLayout children={<DoctorDetailInfoDetailPage />} />,
                },
                {
                    path: '/hospital',
                    element: <HeaderLayout children={<ListPage />} />,
                },
                // {
                //     path: '/calculate',
                //     element: <HeaderLayout children={<CalculatePage />} />,
                // },
                // ! calculate page divide
                {
                    path: '/calculate/dashboard',
                    element: <HeaderLayout children={<CalculateDashboardPage />} />,
                },
                {
                    path: '/calculate/list',
                    element: <HeaderLayout children={<CalculateListPage />} />,
                },
                {
                    path: '/calculate/dataCheckList',
                    element: <HeaderLayout children={<CalculateDataListPage />} />,
                },
                {
                    path: '/calculate/list/calculateDetail',
                    element: <HeaderLayout children={<CalculateDetailPage />} />,
                },
                {
                    path: '/calculate/list/calculateMonthDetail/:year',
                    element: <HeaderLayout children={<CalculateMonthDetail />} />,
                },
                // ! 여기까지
                // {
                //     path: '/calculateDetail',
                //     element: <HeaderLayout children={<CalculateDetailPage />} />,
                // },
                // {
                //     path: '/calculateMonthDetail/:year',
                //     element: <HeaderLayout children={<CalculateMonthDetail />} />,
                // },
                {
                    path: '/productsSettings',
                    element: <HeaderLayout children={<ProductSettingPage />} />,
                },
                {
                    path: '/hospitalDetail',
                    element: <HeaderLayout children={<HospitalInfoDetailPage />} />,
                },
                {
                    path: '/addHospitalInfo',
                    element: <HeaderLayout children={<HospitalInfoRegisterPage />} />,
                },
                {
                    path: '/pharmacy',
                    element: <HeaderLayout children={<ListPage />} />,
                },
                {
                    path: '/pharmacyDetail',
                    element: <HeaderLayout children={<PharmacyInfoDetail />} />,
                },
                {
                    path: '/addPharmacyInfo',
                    element: <HeaderLayout children={<PharmacyInfoRegisterPage />} />,
                },
                {
                    path: '/addDoctorInfo',
                    element: <HeaderLayout children={<DoctorInfoRegisterPage />} />,
                },
                {
                    path: '/diagnosisDetail',
                    element: <HeaderLayout children={<DiagnosisDetailPage />} />,
                },
                // ! 여기부터
                {
                    path: '/unospay',
                    element: <HeaderLayout children={<UnosPayDashBoardPage />} />,
                },
                {
                    path: '/reorderAlimtalkManage',
                    element: <HeaderLayout children={<ReorderAlimTalkManagePage />} />,
                },
                {
                    path: '/unospay/unospayOrderDetail',
                    element: <HeaderLayout children={<UnospayOrderDetailPage />} />,
                },
                // ! 여기까지
                {
                    path: '/unospayOrderDetail/:id',
                    element: <UnospayDeliveryListPopUp />,
                },
                {
                    path: '/unospayAllMealsSchedule',
                    element: <AllMealsScheduleListPopUp />,
                },
                {
                    path: '/unospayMealDetail/:mealCode/:itemCode/:searchDate',
                    element: <MealsScheduleItemPopup />,
                },
                {
                    path: '/unospayHdOrder/:date',
                    element: <UnospayHdOrderPopUp />,
                },
                {
                    path: '/companyPopup',
                    element: <UnospayAddCompanyPopup />,
                },
                // !여기부터 추가
                {
                    path: '/productList',
                    element: <HeaderLayout children={<ProductListPage />} />,
                },
                {
                    path: '/clientList',
                    element: <HeaderLayout children={<ClientListPage />} />,
                },
                {
                    path: '/clientList/addClient',
                    element: <HeaderMarginLayout children={<AddClientPage />} />,
                },
                {
                    path: '/productList/addProduct',
                    element: <HeaderMarginLayout children={<AddProductPage />} />,
                },
                {
                    path: '/clientNoticeList/addClientNotice',
                    element: <HeaderMarginLayout children={<AddClientNoticePage />} />,
                },
                {
                    path: '/clientNoticeList',
                    element: <HeaderLayout children={<ClientNoticeListPage />} />,
                },
                // ! 여기까지
                {
                    path: '*',
                    element: <Navigate to='/home' />,
                },
            ];
        } else {
            return [
                {
                    path: '/',
                    element: <HeaderLayout children={<SignInPage />} />,
                },
                // {
                //     path: '/calculate',
                //     element: <HeaderLayout children={<CalculatePage />} />,
                // },
                // ! calculate page divide
                {
                    path: '/calculate/dashboard',
                    element: <HeaderLayout children={<CalculateDashboardPage />} />,
                },
                {
                    path: '/calculate/list',
                    element: <HeaderLayout children={<CalculateListPage />} />,
                },
                {
                    path: '/calculate/dataCheckList',
                    element: <HeaderLayout children={<CalculateDataListPage />} />,
                },
                {
                    path: '/calculate/list/calculateDetail',
                    element: <HeaderLayout children={<CalculateDetailPage />} />,
                },
                {
                    path: '/calculate/list/calculateMonthDetail/:year',
                    element: <HeaderLayout children={<CalculateMonthDetail />} />,
                },
                // ! 여기까지
                // {
                //     path: '/calculateDetail',
                //     element: <HeaderLayout children={<CalculateDetailPage />} />,
                // },
                // {
                //     path: '/calculateMonthDetail/:year',
                //     element: <HeaderLayout children={<CalculateMonthDetail />} />,
                // },
                {
                    path: '/productsSettings',
                    element: <HeaderLayout children={<ProductSettingPage />} />,
                },
                // ! 여기부터
                {
                    path: '/unospay',
                    element: <HeaderLayout children={<UnosPayDashBoardPage />} />,
                },
                {
                    path: '/reorderAlimtalkManage',
                    element: <HeaderLayout children={<ReorderAlimTalkManagePage />} />,
                },
                {
                    path: '/unospay/unospayOrderDetail',
                    element: <HeaderLayout children={<UnospayOrderDetailPage />} />,
                },
                // ! 여기까지
                {
                    path: '/unospayOrderDetail/:id',
                    element: <UnospayDeliveryListPopUp />,
                },
                {
                    path: '/unospayAllMealsSchedule',
                    element: <AllMealsScheduleListPopUp />,
                },
                {
                    path: '/unospayMealDetail/:mealCode/:itemCode/:searchDate',
                    element: <MealsScheduleItemPopup />,
                },
                {
                    path: '/unospayHdOrder/:date',
                    element: <UnospayHdOrderPopUp />,
                },
                {
                    path: '/companyPopup',
                    element: <UnospayAddCompanyPopup />,
                },
                // !여기부터 추가
                {
                    path: '/productList',
                    element: <HeaderLayout children={<ProductListPage />} />,
                },
                {
                    path: '/clientList',
                    element: <HeaderLayout children={<ClientListPage />} />,
                },
                {
                    path: '/clientList/addClient',
                    element: <HeaderMarginLayout children={<AddClientPage />} />,
                },
                {
                    path: '/productList/addProduct',
                    element: <HeaderMarginLayout children={<AddProductPage />} />,
                },
                {
                    path: '/clientNoticeList/addClientNotice',
                    element: <HeaderMarginLayout children={<AddClientNoticePage />} />,
                },
                {
                    path: '/clientNoticeList',
                    element: <HeaderLayout children={<ClientNoticeListPage />} />,
                },
                // ! 여기까지
                {
                    path: '*',
                    element: <Navigate to='/unospay' />,
                },
            ];
        }
    };

    const getPharmacyRoutes = () => {
        return [
            {
                path: '/',
                element: <HeaderLayout children={<SignInPage />} />,
            },
            {
                path: '/home',
                element: <HeaderLayout children={<PharmacyDashBoardPage />} />,
            },
            {
                path: '/pharamcyPrescription',
                element: <HeaderLayout children={<PharmacyDiagnosisDetail />} />,
            },
            {
                path: '/pharmacyLedger',
                element: <HeaderLayout children={<PharmacyLedgerPage />} />,
            },
            {
                path: '/pharmacyInfo',
                element: <HeaderLayout children={<PharmacyInfo />} />,
            },
            {
                path: '*',
                element: <Navigate to='/home' />,
            },
        ];
    };

    const getDefaultRoute = () => {
        return [
            {
                path: '/',
                element: <HeaderLayout children={<SignInPage />} />,
            },
            {
                path: '*',
                element: <Navigate to='/' />,
            },
        ];
    };

    const getCalculaterRoutes = () => {
        return [
            {
                path: '/',
                element: <CalculateHeaderLayout children={<SignInPage />} />,
            },
            {
                path: '/home',
                element: <CalculateHeaderLayout children={<DoctorCalculateBoardPage />} />,
            },
            // ! 여기부터
            {
                path: '/notices',
                element: <CalculateHeaderLayout children={<Notices />} />,
            },
            {
                path: '/detailNotice',
                element: <CalculateHeaderLayout children={<DetailNoticePage />} />,
            },
            // ! 여기까지
            {
                path: '/calculateMonthDetail/:year',
                element: <CalculateHeaderLayout children={<DoctorCalculateMonthDetail />} />,
            },
            {
                path: '/productPriceList/*',
                element: <CalculateHeaderLayout children={<ProductPriceList />} />,
            },
            {
                path: '/productPriceList/mtm',
                element: <CalculateHeaderLayout children={<ProductPriceList />} />,
            },
            {
                path: '/productPriceList/mts',
                element: <CalculateHeaderLayout children={<ProductPriceList />} />,
            },
            {
                path: '/productPriceList/mte',
                element: <CalculateHeaderLayout children={<ProductPriceList />} />,
            },
            {
                path: '/productDetail/:externalCode',
                element: <CalculateHeaderLayout children={<ProductInfoDetail />} />,
            },
            {
                path: '*',
                element: <Navigate to='/home' />,
            },
        ];
    };

    const getDoctorRoutes = () => {
        return [
            {
                path: '/',
                element: <HeaderLayout children={<SignInPage />} />,
            },
            {
                path: '/home',
                element: <HeaderLayout children={<DoctorDashBoardPage />} />,
            },
            {
                path: '/prescription',
                element: <HeaderLayout children={<DoctorDiagnosisDetailPage />} />,
            },
            // {
            //   path: "/reservationInfo",
            //   element: <HeaderLayout children={<DoctorReservation />} />,
            // },
            {
                path: '/calculateDetail',
                element: <HeaderLayout children={<DoctorCalculateBoardPage />} />,
            },
            {
                path: '/calculateMonthDetail/:year',
                element: <HeaderLayout children={<DoctorCalculateMonthDetail />} />,
            },
            {
                path: '*',
                element: <Navigate to='/home' />,
            },
        ];
    };

    const filteredLevel = (level: number) => {
        let route = getDefaultRoute();

        switch (level) {
            case userLevel.operator:
                route = getOperatorRoutes();
                break;
            case userLevel.manager:
                route = getManagerRoutes();
                break;
            case userLevel.o2oPharmacy:
                route = getPharmacyRoutes();
                break;
            case userLevel.pharmacist:
                route = getPharmacyRoutes();
                break;
            case userLevel.o2oDoctor:
                route = getDoctorRoutes();
                break;
            case userLevel.o2oCalculater:
                route = getCalculaterRoutes();
                break;
        }
        return route;
    };

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {useRoutes(filteredLevel(selectedUser?.user.level || 0))}
        </ThemeProvider>
    );
}

export default App;
