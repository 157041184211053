import { IOoUnospayDelivery, OoDeliveryStatus } from './unospay';

export interface UnospayProcessingDeliveryState {
    title: string;
    color: UnospayStatusColor;
    timestamp: number;
}

export enum UnospayStatusColor {
    deliverySendSuccess = '#28344D',
    cancel = '#CF5D5D',
    deliveryFormSuccess = '#3E7BCD',
    wait = '#949390',
    requestCancel = '#D89F93',
    beforePayment = '#B693D8',
    noSelectMenu = '#eacf8d',
}

export const deliveryProcessingStatus = (delivery: IOoUnospayDelivery): UnospayProcessingDeliveryState => {
    let statusData = {
        title: '배송정보 미입력',
        color: UnospayStatusColor.wait,
        timestamp: delivery.timestamp,
    };
    switch (delivery.status) {
        case OoDeliveryStatus.existAddress:
            statusData = {
                title: '배송정보 입력 완료',
                color: UnospayStatusColor.deliveryFormSuccess,
                timestamp: delivery.timestamp,
            };
            break;
        case OoDeliveryStatus.sendPackage:
            statusData = {
                title: '발송 완료',
                color: UnospayStatusColor.deliverySendSuccess,
                timestamp: delivery.timestamp,
            };
            break;
        case OoDeliveryStatus.cancelOrder:
            statusData = {
                title: '주문 취소',
                color: UnospayStatusColor.cancel,
                timestamp: delivery.timestamp,
            };
            break;
        case OoDeliveryStatus.requestCancel:
            statusData = {
                title: '취소 요청',
                color: UnospayStatusColor.requestCancel,
                timestamp: delivery.timestamp,
            };
            break;
        case OoDeliveryStatus.beforePayment:
            statusData = {
                title: '입금전',
                color: UnospayStatusColor.beforePayment,
                timestamp: delivery.timestamp,
            };
            break;
        // case  OoDeliveryStatus.noSelectMenu:
        //     statusData = {
        //         title: "메뉴 미입력",
        //         color: UnospayStatusColor.noSelectMenu,
        //         timestamp: delivery.timestamp,
        //     };
        //     break;
    }
    return statusData;
};
