import { AxiosInstance } from 'axios';
import { IOoDataResponse, IOoResponse } from '../reponses';
import { baseUrl, endPoint } from './base';
import OoUnospayDeliveryInfoCreate from '../params/unospay/create';
import OoUnospayAddressInfoUpdate from '../params/unospay/update';
import { OoParamName } from '@params/common/paramName';
import { IOoUnospayDelivery } from '@models/unospay';
import { OoParamId } from '../params';
import { OoUnospayReadDeliveryInfoRange } from '@params/unospay/readDeliveryInfoRange';
import { OoUnospayUpdateTransportInfo } from '@params/unospay/updateTransportInfo';
import { OoParamKeyword } from '@params/common/paramkeyword';
import { OoUnospayChangeOrderPlatform } from '@params/unospay/changeOrderPlatform';
import { OoUnospayCancelOrderComment } from '@params/unospay/cancelOrderComment';
import { IOoUnospayProvider } from '@api/models/unospay/provider';
import { IOoUnospayGfOrder } from '@models/unospay/gfOrder';
import { IOoUnospayCsInfo } from '@api/models/unospay/csInfo';
import { IOoallMealsSchedule } from '@api/models/unospay/allMealsSchedule';
import { mealsDetailDetailInfo } from 'interfaces/Operator/OperatorMealsDetail';
import { OoUnospayPostMealDetail } from '@api/params/unospay/postMealDetail';
import { IOoMealDetail } from '@api/models/unospay/mealDetail';
import { OoUnospayChangeDeliveryStatus } from '@api/params/unospay/changeDeliveryStatus';
import { OoUnospayAddCompany } from '@api/params/unospay/addCompany';
import { providerList } from 'interfaces/Operator/OperatorUnospayProviderList';
import { IOoCalculateList } from '@api/models/calculate/calculateList';
import { postCreateOrderInfo } from '@api/params/unospay/postCreateOrderInfo';
import OoUnospayHospitalInfoUpdate from '@api/params/unospay/updateHospitalInfo';
import OoUnospayPaymenyCancelInfo from '@api/params/unospay/updatePaymentCancelInfo';
import OoUnospayUpdateGfMenu from '@api/params/unospay/updateGfMenu';
import { OoUnospaySendTransportAlimtalk } from '@api/params/unospay/sendTranportAlimtalk';
import { GetReorderMessageListApiResponse } from '@api/models/reorderAlimtalk/reorderAlimtalkModels';

class DoctorOnUnospayApi {
    static shared: any;
    constructor(private readonly axiosInstance: AxiosInstance) {}

    /*
     * 결제 정보 생성
     */
    async deliveryInfo(param: OoUnospayDeliveryInfoCreate): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.unospay.deliveryInfo, param);
    }

    /*
     * 결제 정보 삭제
     */
    async delteDeliveryInfo(param: OoParamId): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.unospay.deleteDeliveryInfo, param);
    }

    /*
     * 수령인 이름 으로 조회
     */
    async findByName(param: OoParamName): Promise<IOoDataResponse<IOoUnospayDelivery[]>> {
        const url = `${endPoint.unospay.findByName}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoUnospayDelivery[]>>(url);
    }

    /*
     * 수령인 아이디로 조회
     */
    async findById(param: OoParamId): Promise<IOoDataResponse<IOoUnospayDelivery>> {
        const url = `${endPoint.unospay.findById}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoUnospayDelivery>>(url);
    }

    /*
     * 키워드로 조회
     */
    async fulltextSearch(param: OoParamKeyword): Promise<IOoDataResponse<IOoUnospayDelivery[]>> {
        const url = `${endPoint.unospay.fulltextSearch}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoUnospayDelivery[]>>(url);
    }

    /*
     * 결제 정보 기간 조회
     */
    async readRecordRange(param: OoUnospayReadDeliveryInfoRange): Promise<IOoDataResponse<IOoUnospayDelivery[]>> {
        const url = `${endPoint.unospay.readRecordRange}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoUnospayDelivery[]>>(url);
    }

    /*
     * 주문 취소
     */
    async cancelOrder(param: OoUnospayCancelOrderComment): Promise<IOoDataResponse<IOoUnospayDelivery[]>> {
        return await this.axiosInstance.put<IOoDataResponse<IOoUnospayDelivery[]>>(endPoint.unospay.cancelOrder, param);
    }
    /*
     * 배송 상태 변경
     */
    async changeDeliveryStatus(param: OoUnospayChangeDeliveryStatus): Promise<IOoDataResponse<IOoUnospayDelivery[]>> {
        return await this.axiosInstance.put<IOoDataResponse<any[]>>(endPoint.unospay.changeDeliveryStatus, param);
    }

    // async changeOrderPlatform(param: OoUnospayChangeOrderPlatform): Promise<IOoDataResponse<IOoUnospayDelivery[]>> {
    //     return await this.axiosInstance.put<IOoDataResponse<IOoUnospayDelivery[]>>(endPoint.unospay.changeOrderPlatform, param);
    // }

    /*
     * 배송지 정보 업데이트
     */
    async updateAddressInfo(param: OoUnospayAddressInfoUpdate): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.unospay.updateAddressInfo, param);
    }

    /*
     * 배송 정보 업데이트
     */
    async updateTransportInfo(param: OoUnospayUpdateTransportInfo): Promise<IOoDataResponse<IOoUnospayDelivery[]>> {
        return await this.axiosInstance.put<IOoDataResponse<IOoUnospayDelivery[]>>(endPoint.unospay.updateTransportInfo, param);
    }

    /*
     * 카카오 알림톡 재전송
     */
    async resendKakaoAlimtalk(param: OoParamId): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.unospay.resendKakaoAlimtalk, param);
    }

    /*
     * 배송 알림톡 전송
     */
    async sendTransportAlimtalk(param: OoUnospaySendTransportAlimtalk): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.unospay.sendTransportAlimtalk, param);
    }
    /*
     * 주문 플랫폼 업데이트
     */
    async changeOrderPlatform(param: OoUnospayChangeOrderPlatform): Promise<IOoDataResponse<IOoUnospayDelivery>> {
        return await this.axiosInstance.put<IOoDataResponse<IOoUnospayDelivery>>(endPoint.unospay.changeOrderPlatform, param);
    }

    /*
     * 업체 리스트 조회
     */
    async foodProviderList(): Promise<IOoDataResponse<IOoUnospayProvider[]>> {
        return await this.axiosInstance.get<IOoDataResponse<IOoUnospayProvider[]>>(endPoint.unospay.foodProviderList);
    }

    /*
     * 주문 아이디로 그리팅 연동 정보 조회
     */
    async getGfOrderByDeliveryId(param: OoParamId): Promise<IOoDataResponse<IOoUnospayGfOrder[]>> {
        const url = `${endPoint.unospay.getGfOrder}/${param.getPathParams()}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoUnospayGfOrder[]>>(url);
    }

    /*
     * 날짜 기반 조회
     */
    async getGfOrderByTargetDate(date: string): Promise<IOoDataResponse<IOoUnospayGfOrder[]>> {
        const url = `${endPoint.unospay.getGfOrderByTargetDate}/${date}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoUnospayGfOrder[]>>(url);
    }

    /*
     * CS Memo 조회
     */
    async getCsMemoByMobile(mobile: string): Promise<IOoDataResponse<IOoUnospayCsInfo[]>> {
        const url = `${endPoint.unospay.getCsMemoByMobile}/${mobile}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoUnospayCsInfo[]>>(url);
    }

    /*
     * 그링팅 조회
     */
    async getMealsScheduleData(startDate: string, endDate: string): Promise<IOoDataResponse<IOoallMealsSchedule[]>> {
        const url = `${baseUrl.prod}${endPoint.unospay.getMealsScheduleData}/${startDate}/${endDate}`;
        return await this.axiosInstance.get<IOoDataResponse<IOoallMealsSchedule[]>>(url);
    }

    /*
     * 그링팅 상세조회
     */
    async postmealDetail(param: OoUnospayPostMealDetail): Promise<IOoDataResponse<IOoMealDetail>> {
        return await this.axiosInstance.post<IOoDataResponse<IOoMealDetail>>(endPoint.unospay.getmealDetail, param);
    }

    /*
     * 업체등록
     */
    async createFoodProvider(param: OoUnospayAddCompany): Promise<IOoResponse> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.unospay.createFoodProvider, param);
    }

    /*
     * 업체목록
     */
    async getFoodProvider(): Promise<IOoDataResponse<providerList[]>> {
        return await this.axiosInstance.get<IOoDataResponse<providerList[]>>(endPoint.unospay.getFoodProvider);
    }

    /*
     * 수기주문
     */
    async postCreateOrderInfo(param: postCreateOrderInfo): Promise<IOoDataResponse<any[]>> {
        return await this.axiosInstance.post<IOoResponse>(endPoint.unospay.postCreateOrderInfo, param);
    }

    /*
     * 주문내 병원 정보 업데이트
     */
    async updateHospitalInfo(param: OoUnospayHospitalInfoUpdate): Promise<IOoDataResponse<IOoUnospayDelivery>> {
        return await this.axiosInstance.put<IOoDataResponse<IOoUnospayDelivery>>(endPoint.unospay.updateHospitalInfo, param);
    }

    async updatePaymentCancelInfo(id: string, date: string): Promise<IOoResponse> {
        const url = date ? `${baseUrl.prod}${endPoint.unospay.updatePaymentCancelInfo}/${id}/${date}` : `${baseUrl.prod}${endPoint.unospay.updatePaymentCancelInfo}/${id}`;
        return await this.axiosInstance.get<IOoResponse>(url);
    }

    async updateGfMenu(param: OoUnospayUpdateGfMenu): Promise<IOoResponse> {
        return await this.axiosInstance.put<IOoResponse>(endPoint.unospay.updateGfMenu, param);
    }

    async reorderMessageList(page: number, limit: number, sendStatus: number | null, keyword?: string): Promise<IOoDataResponse<GetReorderMessageListApiResponse>> {
        // const url = `${endPoint.unospay.reorderMessageList}?page=${page}&limit=${limit}&keyword=${keyword}`;
        const url = `${endPoint.unospay.reorderMessageList}?page=${page}&limit=${limit}&sendStatus=${sendStatus}`;
        return await this.axiosInstance.get<IOoDataResponse<GetReorderMessageListApiResponse>>(url);
    }
}

export { DoctorOnUnospayApi };
