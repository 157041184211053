import { useMutation, useQueryClient } from 'react-query';
import { useSuspenseHook } from '@hooks/suspense';
import { toast } from 'react-toastify';
import { DoctorOnAPI } from '@api/request';
import { ChangeReorderMessageStatusRqData, UpdateReorderMessageRqData } from '@api/models/reorderAlimtalk/reorderAlimtalkModels';
import { isRequestSucceed } from '@api/reponses';

export const useUpdateReorderMessageMutate = () => {
    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

    return useMutation(
        // 첫 번째 인자: mutation 함수 (파라미터를 받아 처리)
        'updateReorderMessage',
        async (rqData: UpdateReorderMessageRqData) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.reorderMessage.updateReorderMessage(rqData);
                setStopLoading();

                if (!isRequestSucceed(response)) {
                    throw new Error('API 요청 실패');
                }
                return response.data;
            } catch (e: any) {
                setErrorStatus(e);
            }
        }
    );
};
