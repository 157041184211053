import { realtimeDatabase } from "../../firebaseConfig";
import { ref, onValue, onChildAdded, onChildChanged, onChildRemoved, off, DatabaseReference } from "firebase/database";
import _ from "lodash";
import { IOoDiagnosis } from "@models/diagnosis";

export class DiagnosisRtdbSubscriber {
    private static instance: DiagnosisRtdbSubscriber;
    private TAG = 'DiagnosisRtdbSubscriber';
    private dbRef?: DatabaseReference = undefined;
    private dataList: Array<IOoDiagnosis> = [];

    private constructor() {
    }

    static shared() {
        if (_.isEmpty(DiagnosisRtdbSubscriber.instance)) {
            DiagnosisRtdbSubscriber.instance = new DiagnosisRtdbSubscriber();
        }
        return DiagnosisRtdbSubscriber.instance;
    }

    getOriginList(): Array<IOoDiagnosis> {
        return this.dataList;
    }

    subscribe(path: string, onValueCallback: OnDiagnosisDataListListener) {
        if (!_.isUndefined(this.dbRef)) {
            console.log(this.TAG, ':subscribe', ' =====>> already connected! ');
            return;
        }

        if (_.isEmpty(path)) {
            this.unsubscribe();
            console.log(this.TAG, ':subscribe', ' =====>> path is empty! ');
            return;
        }

        this.dataList = [];
        this.dbRef = ref(realtimeDatabase, path);

        // 초기 데이터 로드
        onValue(this.dbRef, (snapshot) => {
            if (!snapshot.exists()) return;
            
            this.dataList = [];
            const data = snapshot.val();
            Object.values(data).forEach((item) => {
                this.dataList.push(item as IOoDiagnosis);
            });
            onValueCallback(this.dataList);
        });

        // 개별 이벤트 리스너 설정
        this.setupChildListeners(onValueCallback);
    }

    private setupChildListeners(onValueCallback: OnDiagnosisDataListListener) {
        if (!this.dbRef) return;

        // 새로운 데이터 추가
        onChildAdded(this.dbRef, (snapshot) => {
            const newData = snapshot.val() as IOoDiagnosis;
            const existingIndex = this.getDiagnosisIndex(newData);
            if (existingIndex === -1) {
                this.dataList.push(newData);
                onValueCallback(this.dataList);
            }
        });

        // 데이터 업데이트
        onChildChanged(this.dbRef, (snapshot) => {
            const changedData = snapshot.val() as IOoDiagnosis;
            const index = this.getDiagnosisIndex(changedData);
            if (index !== -1) {
                this.dataList[index] = changedData;
                onValueCallback(this.dataList);
            }
        });

        // 데이터 삭제
        onChildRemoved(this.dbRef, (snapshot) => {
            const removedData = snapshot.val() as IOoDiagnosis;
            const index = this.getDiagnosisIndex(removedData);
            if (index !== -1) {
                this.dataList.splice(index, 1);
                onValueCallback(this.dataList);
            }
        });
    }

    unsubscribe() {
        if (this.dbRef) {
            off(this.dbRef);
        }
        this.dbRef = undefined;
        this.dataList = [];
    }

    private getDiagnosisIndex(diagnosis: IOoDiagnosis) {
        return _.findIndex(this.dataList, (d) => _.isEqual(d.id, diagnosis.id));
    }
}

export type OnDiagnosisDataListListener = (diagnosis: Array<IOoDiagnosis>) => void;