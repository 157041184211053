import { IOoUnospaySalesMenuOption } from "./salesMenuOption";

interface IOoUnospayTransportInfo {
    // 외부코드
    externalCode: string;

    // 제공 업체 이름
    providerName: string;

    // 택배사 리스트
    displayTransportNameList: Array<string>;

    // 택배사
    selectedTransportName?: string;

    // 운송장 번호
    trackingNumber?: string;

    // 메뉴 코드 리스트
    menuCode?: string;

    // 메뉴 이름
    menuName?: string;

    orderNum?: number;

    transportOption?: number;

    salesOption?:IOoUnospaySalesMenuOption[];

    // 배송 알림톡 전송여부
    sendAlimtalk?: boolean;
}

export type { IOoUnospayTransportInfo };

export enum OoTransportOption {
    DAILY_DELIVERY,
    TWODAYS_DELIVERY,
    PACKAGE_DELIVERY,
    EARLY_DELIVERY
}
