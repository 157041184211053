import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { IOoUnospayDelivery } from '@api/models';
import { SearchProps } from '@recoils/search/unospaySearchByNameState';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useFindByNameUnospayQuery, useFindIdUnospayQuery, useReadRangeUnospayQuery, useGetCsMemoByMobileQuery } from '@queries/unospay';
import { CalendarParamProp, changeDeliveryStatusVisivleState, cancelOrderModalVisibleState, resendKakaoModalVisibleState, unospayListState } from '@recoils/unospay';
import { changeOrderPlatformVisibleState } from '@recoils/unospay/changeOrderPlatformVisible';
import { useFindDeliveryIdGfOrderQuery } from '@queries/unospay/findByDeliveryIdGfOrderQuery';

export const useUnosOrderDetailPageSettingHook = () => {
    const location = useLocation();

    const rtdbList = useRecoilValue(unospayListState);

    const data = _.get(location.state, 'data') as IOoUnospayDelivery;
    const calendarParam = _.get(location.state, 'calendarParam') as CalendarParamProp;
    const searchParam = _.get(location.state, 'searchList') as SearchProps;

    const [queryTrigger, setQueryTrigger] = useState({
        findQueryTrigger: false,
        searchQueryTrigger: false,
        calendarQueryTrigger: false,
    });

    const [changeStatusOrderQueryTrigger, setChangeStatusOrderQueryTrigger] = useState<boolean>(false);
    const [cancelOrderQueryTrigger, setCancelOrderQueryTrigger] = useState<boolean>(false);
    const [changeDeliveryStatusTrigger, setChangeDeliveryStatusTrigger] = useState<boolean>(false);
    const [resendQueryTrigger, setResendQueryTrigger] = useState<boolean>(false);

    const rangeResponse = useReadRangeUnospayQuery({
        beginTimestamp: calendarParam.beginTimestamp,
        endTimestamp: calendarParam.endTimestamp,
        count: calendarParam.count,
        id: calendarParam.id,
        updateQueryTrigger: queryTrigger.calendarQueryTrigger,
    });

    const findKeywordResponse = useFindByNameUnospayQuery({
        searchName: searchParam.userName || '',
        searchQueryTrigger: queryTrigger.searchQueryTrigger,
    });
    const findTargetResponse = useFindIdUnospayQuery({ id: data.id || '' });

    const getCsInfoResponse = useGetCsMemoByMobileQuery({ mobile: data.mobile || '' });

    const [cancelModalVisible, setCancelModalVisible] = useRecoilState(cancelOrderModalVisibleState);
    const [changeStatusModalVisible, setChangeStatusModalVisible] = useRecoilState(changeDeliveryStatusVisivleState);
    const [kakaoResendModalVisible, setKakaoResendModalVisible] = useRecoilState(resendKakaoModalVisibleState);

    const handleCancelOrder = () => setCancelModalVisible(true);
    const handleChangeStatusOrder = () => setChangeStatusModalVisible(true);
    const handleResendAlimtalk = () => setKakaoResendModalVisible(true);

    const [changeOrderPlatformQueryTrigger, setChangeOrderPlatformQueryTrigger] = useState<boolean>(false);
    const [changeOrderPlatformValue, setChangeOrderPlatformValue] = useState<number>(0);
    const [orderPlatformModalVisible, setOrderPlatformModalVisible] = useRecoilState(changeOrderPlatformVisibleState);
    const handleChangeOrderPlatform = () => setOrderPlatformModalVisible(true);

    const [cancelOrderCommentValue, setCancelOrderCommentValue] = useState<string>('');

    const { data: gfOrderList } = useFindDeliveryIdGfOrderQuery({ id: data.id || '' });

    const [purchasePathModalVisible, setPurchasePathModalVisible] = useState<boolean>(false);
    const [changePurchasePathTrigger, setChangePurchasePathTrigger] = useState<boolean>(false);
    const handleChangePurchasePath = () => setPurchasePathModalVisible(true);
    const [selectedHospitalCode, setSelectedHospitalCode] = useState<string>('');

    return {
        data,
        calendarParam,
        searchParam,
        queryTrigger,
        setQueryTrigger,
        cancelOrderQueryTrigger,
        setCancelOrderQueryTrigger,
        changeStatusOrderQueryTrigger,
        setChangeStatusOrderQueryTrigger,
        resendQueryTrigger,
        setResendQueryTrigger,
        rangeResponse,
        findKeywordResponse,
        findTargetResponse,
        cancelModalVisible,
        setCancelModalVisible,
        handleChangeStatusOrder,
        changeStatusModalVisible,
        setChangeStatusModalVisible,
        kakaoResendModalVisible,
        setKakaoResendModalVisible,
        handleCancelOrder,
        handleResendAlimtalk,
        rtdbList,
        changeOrderPlatformQueryTrigger,
        setChangeOrderPlatformQueryTrigger,
        changeOrderPlatformValue,
        setChangeOrderPlatformValue,
        orderPlatformModalVisible,
        setOrderPlatformModalVisible,
        handleChangeOrderPlatform,
        changeDeliveryStatusTrigger,
        cancelOrderCommentValue,
        setCancelOrderCommentValue,
        gfOrderList,
        getCsInfoResponse,
        purchasePathModalVisible,
        setPurchasePathModalVisible,
        changePurchasePathTrigger,
        setChangePurchasePathTrigger,
        handleChangePurchasePath,
        selectedHospitalCode,
        setSelectedHospitalCode,
    };
};
