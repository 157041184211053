import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const config = {
    apiKey: 'AIzaSyCwEJKGXP3ekbNa7HbNx4wWQGKt_9ImCag',
    authDomain: 'opusone-doctor-on.firebaseapp.com',
    databaseURL: 'https://opusone-doctor-on-default-rtdb.firebaseio.com',
    projectId: 'opusone-doctor-on',
    storageBucket: 'gs://opusone-doctor-on.appspot.com/',
    messagingSenderId: '131480046525',
    appId: '1:131480046525:web:62740c7677e5b62ca5a0d4',
    measurementId: 'G-9788LRDZ4X',
};

const app = initializeApp(config);

console.info('+++++++++++++++ firebase config init +++++++++++++++');

const auth = getAuth(app);
const realtimeDatabase = getDatabase(app);
const storage = getStorage(app);

const googleAuthProvider = new GoogleAuthProvider();

export { app, auth, storage, realtimeDatabase, googleAuthProvider };
