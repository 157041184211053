import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import { FlexDiv } from '@components/flexDiv';
import { OoOrderPlatformType, UnospayStatusColor } from '@api/models';
import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList';
import { ModalOverlay } from '@components/modal';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { selectedUserState } from '@recoils/oauth';
import { DoctorOnAPI } from '@api/request';
import { DoctorOnUnospayApi } from '@api/request/unospay';
import OoUnospayPaymenyCancelInfo from '@api/params/unospay/updatePaymentCancelInfo';
import { useUpdatePaymentCancelInfoQuery } from '@queries/unospay/paymenyInfoQuery';
import { IOoResponse } from '@api/reponses';
import { UseMutationResult } from 'react-query';

interface Props {
    data: Array<IOoCalculateMonthList>;
}

interface ColorProps {
    color: string;
}

const CalculateRawListContainer = ({ data }: Props) => {
    const [showCopyMessage, setShowCopyMessage] = useState({ type: false, id: '' });
    const [showModal, setShowModal] = useState<{ show: boolean; item: any }>({ show: false, item: null });

    const selectedUser = useRecoilValue(selectedUserState);

    const updatePaymentCancelInfoQuery: UseMutationResult<IOoResponse | undefined, any, { id: string; date: string }> = useUpdatePaymentCancelInfoQuery();

    const updatePaymentCancelInfo = (param: { id: string; date: string }) => {
        updatePaymentCancelInfoQuery.mutate(param, {
            onSuccess: (data: IOoResponse | undefined) => {
                toast.success('처리가 완료되었습니다');
                if (_.isUndefined(data)) {
                    return data;
                }
            },
            onError: (e: any) => {
                console.error(e);
                toast.error('처리에 실패했습니다');
            },
            onSettled: () => {
                setShowModal({ show: false, item: null });
            },
        });
    };

    const getOrderPlatformIndexToString = (type: number) => {
        switch (type) {
            case OoOrderPlatformType.kiosk:
                return '키오스크';
            case OoOrderPlatformType.mtmMall:
                return 'MTM몰';
            case OoOrderPlatformType.telephone:
                return '전화주문';
            case OoOrderPlatformType.test:
                return 'Test주문';
            default:
                return '-';
        }
    };

    const copyToClipboard = (id: string) => {
        navigator.clipboard
            .writeText(id)
            .then(() => {
                toast.success('복사가 완료되었습니다');
            })
            .catch((err) => {
                console.error('Error in copying text: ', err);
                toast.error('복사에 실패했습니다');
            });
    };

    const cancelOrder = (orderId: string) => {
        updatePaymentCancelInfo({ id: orderId, date: moment().format('YYYYMMDD') });
    };

    const restoreCancelOrder = (orderId: string) => {
        updatePaymentCancelInfo({ id: orderId, date: '' });
    };

    return (
        <Wrapper>
            {_.sortBy(data, 'dateTime')
                .reverse()
                .map((item, index) => (
                    <StatusRow>
                        {item.cancelDateTime === '' && item.operatorCancelTimestamp === 0 ? (
                            <CalculateState color={UnospayStatusColor.deliveryFormSuccess}></CalculateState>
                        ) : (
                            <CalculateState color={UnospayStatusColor.cancel}></CalculateState>
                        )}
                        <ListContainer>
                            <FlexDiv flex={0.5}>{index + 1}</FlexDiv>
                            <FlexDiv flex={1}> {item.payerName}</FlexDiv>
                            <FlexDiv flex={1}> {item.payerMobile!.split('-').join('')}</FlexDiv>
                            <FlexDiv flex={1}>{item.dateTime ? moment(item.dateTime).format('YYYY/MM/DD HH:mm') : ''}</FlexDiv>
                            <FlexDiv flex={1.5}>{item.hospitalName}</FlexDiv>
                            <FlexDiv flex={1}>{getOrderPlatformIndexToString(Number(item.orderPlatform))}</FlexDiv>
                            <FlexDiv flex={1}>{item.hospitalCalPrice}</FlexDiv>
                            <FlexDiv flex={3}>{item.goods}</FlexDiv>
                            <FlexDiv flex={1}> {item.paymentPrice}</FlexDiv>
                            <FlexDiv flex={1}> {item.providerName}</FlexDiv>
                            <FlexDiv flex={1.5}>
                                {item.refererList?.map((referer) => (
                                    <div>
                                        {referer.split('|')[0]} [{referer.split('|')[1]}%]
                                    </div>
                                ))}
                            </FlexDiv>
                            <FlexDiv flex={0.5}>
                                {' '}
                                {item.refererCalPrice?.map((price) => (
                                    <div>{price}</div>
                                ))}
                            </FlexDiv>
                            <FlexDiv flex={0.5} display='flex'>
                                {(selectedUser?.user.email === 'dev@theopusone.com' || selectedUser?.user.email === 'operator2@theopusone.com') && (
                                    <DownloadThemeButton onClick={() => setShowModal({ show: true, item })}>
                                        <ButtonIcon src='/img/management.png' alt='management' />
                                    </DownloadThemeButton>
                                )}
                            </FlexDiv>
                            {showModal.show && showModal.item.id === item.id && (
                                <ModalOverlay visible={showModal.show}>
                                    <ModalContent>
                                        <CloseButton onClick={() => setShowModal({ show: false, item: null })}>×</CloseButton>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td>주문 아이디</td>
                                                    <td>
                                                        {item.orderId}
                                                        <CopyButton onClick={() => copyToClipboard(item.orderId)}>복사하기</CopyButton>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>정산 아이디</td>
                                                    <td>
                                                        {item.id}
                                                        <CopyButton onClick={() => copyToClipboard(item.id!)}>복사하기</CopyButton>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <ButtonContainer>
                                                            <CancelButton onClick={() => cancelOrder(item.id!)}>취소 하기</CancelButton>
                                                            <DeleteButton onClick={() => restoreCancelOrder(item.id!)}>취소 복구 하기</DeleteButton>
                                                        </ButtonContainer>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </ModalContent>
                                </ModalOverlay>
                            )}
                        </ListContainer>
                    </StatusRow>
                ))}
        </Wrapper>
    );
};

export default CalculateRawListContainer;

const CopyMessageContainer = styled.span`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 0.5em;
    overflow-y: scroll;
    color: #2176f6;
    position: absolute;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75%;
    bottom: 100px;
    overflow: scroll;

    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }

    @media (min-height: 1024px) and (max-height: 1280px) {
        height: 70%;
    }

    @media (min-height: 768px) and (max-height: 1024px) {
        height: 65%;
    }
`;

const StatusRow = styled.div`
    background: #ffffff;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px -2px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 4px;
    width: 100%;

    & > a:first-child {
        width: 100%;
        height: 64px;
        color: #030303;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const ListContainer = styled.div`
    width: 100%;
    display: grid;
    text-align: start;
    height: 60px;
    align-items: center;
    margin-bottom: 5px;
    background: #ffffff;
    column-gap: 10px;
    grid-template-columns: 3% 5% 8% 5% 12% 5% 5% 15% 5% 7% 10% 5% 5%;
`;

const CalculateState = styled.div<ColorProps>`
    background-color: ${(props) => props.color};
    height: 65px;
    width: 10px;
`;

const DownloadThemeButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-weight: 700;
    font-size: 13px;
    color: black;
    border-radius: 8px;
    border: 0.5px solid #c2c1bd;
    background: white;
    padding: 4px 8px 4px 8px;
    margin-right: 5px;
`;

const ButtonIcon = styled.img`
    width: 16px;
    height: 16px;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
    &:hover {
        color: #666;
    }
`;

const ModalContent = styled.div`
    width: 600px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    position: relative;
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;

    td {
        padding: 8px;
        border: 1px solid #ddd;
    }
`;

const CopyButton = styled.button`
    margin-left: 10px;
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    font-size: 12px;
    cursor: pointer;
    &:hover {
        background: #f5f5f5;
    }
`;

const CancelButton = styled.button`
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: red;
    color: white;
    font-size: 13px;
    cursor: pointer;
    &:hover {
        background: #ffcccc; /* 연한 빨간색 */
        color: #990000; /* 호버 시 진한 텍스트색상 */
    }
`;

const DeleteButton = styled.button`
    padding: 4px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: blue;
    color: white;
    font-size: 13px;
    cursor: pointer;
    &:hover {
        background: #cce5ff; /* 연한 파란색 */
        color: #003399; /* 호버 시 진한 텍스트색상 */
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;
