import styled from 'styled-components';
import BasicButton from '@components/editButton/BasicButton';
import { NoticeModalProps } from '@api/models/modal/modalTypes';
import { ModalFooter, ModalTitle, NoticeModalContent, NoticeModalLayout } from './ModalForms';
import { BackgroundColor } from '@styles/colorSemantic';
import { MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router';

const NoticeModal = ({ setIsLeaveModal, clickConfirmButton, title, content, firstButton, secondButton, id, $top, $left }: NoticeModalProps) => {
    const navigate = useNavigate();

    return (
        <NoticeModalLayout $width='400px' $height='400px' $backgroundColor={BackgroundColor.color_background_primary_neutral} $top={$top} $left={$left}>
            <ModalTitleAndContentContainer>
                <TitleAndIconWrapper>
                    {/* <NotiWarning /> */}
                    <ModalTitle>{title}</ModalTitle>
                </TitleAndIconWrapper>
                <ContentWrapper>
                    <NoticeModalContent>{content}</NoticeModalContent>
                    <MoveButtonWrapper>
                        <BasicButton $width='100px' $height='30px' $type='fill' onClick={() => navigate('/detailNotice', { state: { id } })}>
                            게시판으로 이동
                        </BasicButton>
                    </MoveButtonWrapper>
                </ContentWrapper>
            </ModalTitleAndContentContainer>
            <ModalFooter>
                {/* <CloseButtonWrapper onClick={() => setIsLeaveModal(id)}>
                    <CloseDialog />
                </CloseButtonWrapper> */}
                <BasicButton $width='160px' $height='48px' onClick={() => setIsLeaveModal(id, 'week')} $type='outLine'>
                    {firstButton}
                </BasicButton>
                <BasicButton $width='160px' $height='48px' onClick={() => setIsLeaveModal(id)} $type='outLine'>
                    {secondButton}
                </BasicButton>
            </ModalFooter>
            <CloseButtonWrapper onClick={() => setIsLeaveModal(id)}>
                <MdClose />
            </CloseButtonWrapper>
        </NoticeModalLayout>
    );
};

export default NoticeModal;

const ModalTitleAndContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 12px;
`;

const TitleAndIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
`;

const CloseButtonWrapper = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 50%;
    &:hover {
        background-color: ${BackgroundColor.color_background_white};
    }
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 230px;
    border-radius: 10px;
    background-color: ${BackgroundColor.color_background_white};
    padding: 10px;
    overflow: auto;
    word-wrap: break-word; /* 자동 줄바꿈 */
`;

const MoveButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`;
