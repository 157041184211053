import { AbstractOoParam } from "../base";

class OoUnospayUpdateGfMenu extends AbstractOoParam {
  public id: string = "";

  public index: number = -1;

  public code: string = "";

  public name: string = "";
}

export default OoUnospayUpdateGfMenu;
