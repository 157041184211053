import styled from 'styled-components';
import { BorderColor, TextColor } from './colorSemantic';
import { font } from './globalStyles';

export const Input = styled.input<{ $width?: string; $height?: string; $state?: boolean; $fontSize?: string; $padding?: string }>`
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${BorderColor.color_border_normal};
    width: ${(props) => props.$width || '300px'};
    height: ${(props) => props.$height || '70%'};
    font-size: ${(props) => props.$fontSize || font.size[14]};
    font-weight: 500;
    padding: ${(props) => props.$padding || '0 8px 0 12px'};
    ${(props) =>
        !props.$state &&
        `
        border: none; 
        outline: none; 
        background: none;
        &::placeholder {
            color: transparent; // placeholder 텍스트 숨기기
        }
        `};
    ${(props) =>
        props.$state &&
        `
        &::placeholder {
            color: ${TextColor.color_text_placeholder};
        }
        &:hover {
            border: 1px solid ${BorderColor.color_border_normal_hover};
        }
        &:focus {
            border: 1px solid ${BorderColor.color_border_normal_press};
        }
        `}
`;
