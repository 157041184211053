import { atom, atomFamily, DefaultValue, selectorFamily } from "recoil";
import { IOoUnospayTransportInfo } from "@models/unospay";
import _ from "lodash";

export interface DeliveryInfoType {
  checked: boolean;
  isDisabled: boolean;
  transport: IOoUnospayTransportInfo;
}

export const checkedListAtom = atom<Array<DeliveryInfoType>>({
  key: "checkedListAtom",
  default: [],
});

type TransportParam = {
  code: string;
  orderNum?: number;
};

const checkedListAtomFamily = atomFamily<DeliveryInfoType, TransportParam>({
  key: "checkedListAtomFamily",
  default: ({ code, orderNum }) => {
    const param: DeliveryInfoType = {
      checked: false,
      isDisabled: false,
      transport: {
        externalCode: code,
        providerName: "",
        displayTransportNameList: [],
        selectedTransportName: "",
        trackingNumber: "",
        menuCode: "",
        menuName: "",
        orderNum: orderNum,
        transportOption: -1,
        sendAlimtalk: false,
      },
    };
    return param;
  },
});

export const checkedListState = selectorFamily<
  DeliveryInfoType,
  TransportParam
>({
  key: "checkedListState",
  get:
    ({ code, orderNum }) =>
    ({ get }) =>
      get(checkedListAtomFamily({ code: code, orderNum: orderNum })),
  set:
    ({ code, orderNum }) =>
    ({ get, set, reset }, deliveryInfoType) => {
      if (deliveryInfoType instanceof DefaultValue) {
        reset(checkedListAtomFamily({ code: code, orderNum: orderNum }));
        set(checkedListAtom, (prevValue) =>
          prevValue.filter((item) => {
            return (
              item.transport.externalCode !== code &&
              item.transport.orderNum !== orderNum
            );
          })
        );
        return;
      }

      const list = get(checkedListAtom);
      const copied = _.cloneDeep(list);
      const index = copied.findIndex((item) => {
        return (
          _.isEqual(
            item.transport.externalCode,
            deliveryInfoType.transport.externalCode
          ) &&
          _.isEqual(
            item.transport.orderNum,
            deliveryInfoType.transport.orderNum
          )
        );
      });

      set(
        checkedListAtomFamily({ code: code, orderNum: orderNum }),
        deliveryInfoType
      );

      if (index < 0) {
        set(
          checkedListAtomFamily({ code: code, orderNum: orderNum }),
          deliveryInfoType
        );
        set(checkedListAtom, (prev) =>
          Array.from(new Set([...prev, deliveryInfoType]))
        );

        return;
      } else {
        copied.splice(index, 1, deliveryInfoType);
        set(checkedListAtom, copied);

        return;
      }
    },
});
