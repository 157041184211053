import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList';
import { ITClient } from '@api/models/client/client';
import { ITProduct } from '@api/models/product/product';
import { ModalOverlay } from '@components/modal';
import LoadingSpinner from '@components/LoadingSpinner';
import { useClientListQuery } from '@queries/client/useClientListQuery';
import { useProductListQuery } from '@queries/product/useProductListQuery';
import { SearchInfo } from '@recoils/display';
import _, { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { CalculateRawListSubTitle } from 'pages/Operator/ListPage/components';
import CalculateRawListContainer from 'pages/Operator/ListPage/components/CalculateRowListContainer';
import DownloadCalculateExcel from 'pages/Operator/ListPage/components/DownloadCalculateExcel';
import RawCalculateOrder from 'pages/Operator/ListPage/components/RawCalculateOrder';
import { useListSearchSettingHook } from 'pages/Operator/ListPage/hooks/useListSearchSettingHook';
import { DownloadButton } from 'pages/Operator/UnospayDashBoardPage/components/DownloadButton';
import { PaymentCalendar } from 'pages/Operator/UnospayDashBoardPage/components/PaymentCalendar';
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { LuSearch } from 'react-icons/lu';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const DataCheckListDashBoard = () => {
    // const [productList, setProductList] = useState<IOoProducts[]>([]);
    const [showModal, setShowModal] = useState({ show: false, type: 'year' });
    const [dobuleDate, setDobuleDate] = useState([moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')]);
    const [searchValue, setSearchValue] = useState({ filterName: 'hospitalName', value: '' });
    const [doneCnt, setDoneCnt] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const { displayList, setDisplayList, list, calculateRawListResp } = useListSearchSettingHook('calculateRaw');

    const { data: clientList, isLoading: isClientLoading, refetch: refetchClients } = useClientListQuery();

    const { data: productList, isLoading: isProductLoading, refetch: refetchProducts } = useProductListQuery();

    const closeCalendar = () => {
        setShowModal((prev) => ({ ...prev, show: false }));
    };

    const hadnleClickProductSetting = () => {
        const width = 1400;
        const height = 1000;

        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2;

        // const url = `https://doctor-on-react.web.app/productsSettings`;
        const url = `https://doctor-on-react-test.web.app/productsSettings`;
        // const url = `http://localhost:3000/productsSettings`;

        window.open(url, '제품관리페이지', `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`);
    };

    // const getProductList = async () => {
    //     const response = await DoctorOnAPI.shared.productSetting.list();
    //     if (response.status === 'ok') {
    //         setProductList(response.data!);
    //     }
    // };

    const handleClickReset = () => {
        setDisplayList((prev) => ({ ...prev, list }));
        setSearchValue({ filterName: 'hospitalName', value: '' });
        // getProductList();
    };

    const handleChageFilterType = (e: ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        setSearchValue((prev) => ({ ...prev, filterName: value }));
    };

    const filteringList = (list: IOoCalculateMonthList[]) => {
        if (searchValue.filterName === 'hospitalName') {
            const result = list.filter((item) => item.hospitalName.toLocaleUpperCase().indexOf(searchValue.value.toLocaleUpperCase()) > -1);
            setDisplayList((prev) => ({ ...prev, list: result }));
        } else if (searchValue.filterName === 'providerName') {
            const result = list.filter((item) => item.providerName.toLocaleUpperCase().indexOf(searchValue.value.toLocaleUpperCase()) > -1);
            setDisplayList((prev) => ({ ...prev, list: result }));
        } else {
            const result = list.filter((item) => item.payerName!.toLocaleUpperCase().indexOf(searchValue.value.toLocaleUpperCase()) > -1);
            setDisplayList((prev) => ({ ...prev, list: result }));
        }
    };

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue((prev) => ({ ...prev, value }));
    };

    const hadleClickSearch = () => {
        filteringList(list as IOoCalculateMonthList[]);
    };

    // 구 다운로드...
    // const hadnleClickExcelDownLoad = () => {
    //     RawCalculateOrder(displayList.list as Array<IOoCalculateMonthList>, productList as Array<ITProduct>);
    // };

    // 매출 통계 엑셀 다운로드
    const handleDownloadExcel = () => {
        if (clientList) {
            DownloadCalculateExcel(displayList.list as Array<IOoCalculateMonthList>, productList as Array<ITProduct>, clientList as Array<ITClient>);
        }
    };

    const handleKeyupSearch = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && searchValue.value.trim() !== '') {
            hadleClickSearch();
        }
        if (e.key === 'Enter' && searchValue.value.trim() === '') {
            toast.error('키워드를 입력하세요.');
        }
    };

    const getNotCancelCount = (list: Array<IOoCalculateMonthList>) => {
        const cancelList = list.filter((item) => {
            if (_.isUndefined(item.operatorCancelTimestamp) || (_.isEqual(item.operatorCancelTimestamp, 0) && _.isEmpty(item.cancelDateTime))) {
                return item;
            }
        });
        setDoneCnt(cancelList.length);
    };

    const getCalculateListMonthDate = useCallback(() => {
        setIsLoading(true);
        calculateRawListResp.mutate({ startDate: dobuleDate[0], endDate: dobuleDate[1] }, {
            onSuccess: () => {
                setIsLoading(false);
            },
            onError: () => {
                setIsLoading(false);
            }
        });
    }, [dobuleDate]);

    useEffect(() => {
        getCalculateListMonthDate();
    }, [dobuleDate]);

    useEffect(() => {
        getNotCancelCount(list as Array<IOoCalculateMonthList>);
        isEmpty(displayList.userName) ? setDisplayList({ ...displayList, id: 'calculateRaw', list }) : setDisplayList({ ...displayList, id: 'calculateRaw' });
    }, [list]);

    return (
        <Wrapper>
            <MainListTitle>
                <Title>
                    <span>정산데이터 목록</span>
                    <span>
                        (총 주문 {list.length}건 / <CancelCntTitle>취소 {list.length - doneCnt}건)</CancelCntTitle>
                    </span>
                </Title>
                <LayoutContainer>
                    {/* <DownloadButton
                  title="제품관리"
                  iconSrc="/img/management.png"
                  onClick={hadnleClickProductSetting}
              ></DownloadButton> */}
                    <ResetBtn type='button' onClick={handleClickReset} disabled={searchValue.value ? false : true}>
                        초기화
                    </ResetBtn>
                    <SelectBoxContainer>
                        <select onChange={handleChageFilterType} value={searchValue.filterName}>
                            <option value='hospitalName'>병원명</option>
                            <option value='providerName'>업체명</option>
                            <option value='payerName'>주문자</option>
                        </select>
                        <input value={searchValue.value} onChange={handleChangeSearch} onKeyUp={handleKeyupSearch} placeholder='검색 키워드 입력' />
                        <button type='submit' onClick={hadleClickSearch}>
                            <LuSearch />
                        </button>
                    </SelectBoxContainer>
                    <PaymentCalendar type='doubleCandler' setDobuleDate={setDobuleDate} dobuleDate={dobuleDate} />
                    {/* <DownloadButton title='엑셀 다운로드' iconSrc='/img/ic_download.png' onClick={hadnleClickExcelDownLoad}></DownloadButton> */}
                    <DownloadButton title='다운로드' iconSrc='/img/ic_download.png' onClick={handleDownloadExcel}></DownloadButton>
                </LayoutContainer>
            </MainListTitle>
            {showModal.show && showModal.type === 'month' && (
                <ModalOverlay visible={showModal.show}>
                    {/* 정산Raw */}
                    <CalendarContainer>
                        <CloseButton onClick={closeCalendar}>닫기</CloseButton>
                    </CalendarContainer>
                </ModalOverlay>
            )}
            <SubTitle>
                <span>항목</span>
                <span>주문자</span>
                <span>휴대번호</span>
                <span>등록일</span>
                <span>병원명</span>
                <span>주문경로</span>
                <span>병원정산금액</span>
                <span>상품명</span>
                <span>상품가격</span>
                <span>상품업체명</span>
                <span>추천인 [퍼센트]</span>
                <span>추천인 정산금액</span>
                <span></span>
                <span></span>
            </SubTitle>
            {(displayList.list || []).length > 0 ? <CalculateRawListContainer data={(displayList.list as Array<IOoCalculateMonthList>) || []} /> : <div>표시 할 내용이 없습니다.</div>}
        </Wrapper>
    );
};

export default DataCheckListDashBoard;

const Wrapper = styled.div`
    width: 100%;
    max-width: 55%;
    min-width: 1400px;
    height: 95%;
`;

const CalendarContainer = styled.div`
    width: 600px;
    height: 500px;
    border-radius: 25px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    .react-calendar {
        border: none;
        margin: 0 auto;
    }
`;

const CloseButton = styled.button`
    width: 100px;
    height: 36px;
    border-radius: 8px;
    border: none;
    align-self: end;
    margin-right: 50px;
    background-color: white;
    font-weight: 400;
    font-size: 13px;
    border: 1px solid #c2c1bd;

    &:hover {
        font-weight: bold;
    }
`;

const LayoutContainer = styled.div`
    height: 100%;
    align-items: center;
    display: flex;
    column-gap: 10px;
    margin-right: 10px;

    span {
        display: flex;
        align-items: center;
        column-gap: 5px;

        div {
            background-color: #e0e0e0;
            border-radius: 5px;
            padding: 2px 5px;
            font-weight: 700;
        }
    }
`;

const SubTitle = styled.div`
    display: grid;
    font-size: 13px;
    column-gap: 10px;
    text-align: center;
    align-items: center;
    padding: 5px;
    background: #ffffff;
    border-bottom: 0.5px solid #c2c1bd;
    border-right: 0.5px solid #c2c1bd;
    border-left: 0.5px solid #c2c1bd;
    grid-template-columns: 3% 5% 8% 5% 12% 5% 5% 15% 5% 7% 10% 6% 5%;
`;

const ResetBtn = styled.button`
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    border: 1px solid ${(props) => (props.disabled ? 'gray' : '#CF5D5D')};
    background-color: ${(props) => (props.disabled ? 'gray' : '#CF5D5D')};
    color: #ffffff;
    padding: 1px 10px;
    &:hover {
        font-weight: bold;
    }
`;

const SelectBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    position: relative;

    select {
        border: 1px solid #cccccc;
        height: 35px;
        border-radius: 5px;
        padding: 0px 5px;
    }

    input {
        border-radius: 5px;
        border: 1px solid rgb(204, 204, 204);
        height: 35px;
        padding: 0px 45px 0px 10px;

        &:focus {
            outline: none;
        }
    }

    button {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0px;
        border: 1px solid #cccccc;
        &:hover {
            font-size: x-large;
        }
    }
`;

const MainListTitle = styled.div`
    width: 100%;
    height: 80px;
    background: ${(props) => props.theme.colors.bgColor.white};
    border: 0.5px solid ${(props) => props.theme.colors.borderColor.grey};
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
`;
const Title = styled.div`
    width: fit-content;
    height: 80px;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 32px;
    display: flex;
    align-items: center;

    @media (min-width: 1536px) and (max-width: 1920px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    @media (min-width: 768px) and (max-width: 1536px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

const CancelCntTitle = styled.span`
    color: #cf5d5d;
    font-weight: 700;
`;
