import { useMutation, useQueryClient } from 'react-query';
import { useSuspenseHook } from '@hooks/suspense';
import { toast } from 'react-toastify';
import { DoctorOnAPI } from '@api/request';
import { ChangeReorderMessageStatusRqData } from '@api/models/reorderAlimtalk/reorderAlimtalkModels';
import { isRequestSucceed } from '@api/reponses';

export const useChangeReorderMessageStatusMutate = () => {
    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

    return useMutation(
        // 첫 번째 인자: mutation 함수 (파라미터를 받아 처리)
        'changeReorderMessageStatus',
        async (rqData: ChangeReorderMessageStatusRqData) => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.reorderMessage.changeReorderMessageStatus(rqData);
                setStopLoading();

                if (!isRequestSucceed(response)) {
                    throw new Error('API 요청 실패');
                }

                return response.data;
            } catch (e: any) {
                setErrorStatus(e);
                // throw e; // 에러를 다시 던져서 onError 콜백에서 처리
            }
        }
        // {
        //   // 성공 시 실행할 콜백
        //   onSuccess: (data, variables) => {
        //     toast.success("알림톡 전송이 취소되었습니다.");

        //     // 관련 쿼리 무효화 (데이터 새로고침)
        //     // queryClient.invalidateQueries("alimtalkList");
        //   },
        //   // 실패 시 실행할 콜백
        //   onError: (error: Error) => {
        //     toast.error("알림톡 취소 중 오류가 발생했습니다.");
        //     console.error(error);
        //   }
        // }
    );
};
