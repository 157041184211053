import { IOoCalculateList } from '@api/models/calculate/calculateList';
import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList';
import { ModalOverlay } from '@components/modal';
import { selectedUserState } from '@recoils/oauth';
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import { MainListContainerStyle } from 'pages/Operator/ListPage';
import { CalculateListSubTitle } from 'pages/Operator/ListPage/components';
import CalculateListContainer from 'pages/Operator/ListPage/components/CalculateListContainer';
import { useListSearchSettingHook } from 'pages/Operator/ListPage/hooks/useListSearchSettingHook';
import calculateListMonthExcelDown from 'pages/Operator/UnospayDashBoardPage/components/ExcelDownloadButton/components/calculateListMonthExcelDown';
import React, { ChangeEvent, KeyboardEvent, useCallback, useEffect, useState, useTransition } from 'react';
import Calendar from 'react-calendar';
import { LuSearch } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { FaFileExcel } from 'react-icons/fa';
import { useHospitalFunctionHook } from '@hooks/hospital';
import { hospitalListState } from '@recoils/display';
import { useHospitalListQuery } from '@queries/hospital';
import { display } from '@mui/system';
import { IOoHospital } from '@api/models';
import { useClientListQuery } from '@queries/client/useClientListQuery';
import { useNavigate } from 'react-router';

interface WrapperStyle extends MainListContainerStyle {
    title: string;
}

interface CustomType extends IOoCalculateList, Omit<IOoHospital, 'id' | 'type'> {}

const ListDashBoard = () => {
    const { displayList, setDisplayList, list, calculateListResp } = useListSearchSettingHook('calculate');
    const hospitalList = useRecoilValue(hospitalListState);
    const selectedUser = useRecoilValue(selectedUserState);
    const [showModal, setShowModal] = useState({ show: false, type: 'year' });
    const [month, setMonth] = useState(moment().startOf('month').format('YYYYMM'));
    const [searchValue, setSearchValue] = useState({
        filterName: 'hospitalCode',
        value: '',
    });
    const [sortInfo, setSortInfo] = useState({
        name: 'hospitalCode',
        type: true,
    });

    const { data: clientList, isLoading: isClientLoading, refetch: refetchClients } = useClientListQuery();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    useEffect(() => {
        calculateListResp.mutate(month);
    }, [month]);

    useEffect(() => {
        isEmpty(displayList.userName) ? setDisplayList({ ...displayList, id: 'calculate', list: list }) : setDisplayList({ ...displayList, id: 'calculate' });
    }, [list]);

    const handleClickDownLoadExcel = () => {
        if (displayList.list) {
            const list = displayList.list as IOoCalculateList[];
            const mattchedList = list.map((item) => {
                const findData = clientList?.find((hospitalInfo) => hospitalInfo.code === item.hospitalCode);
                return { ...item, bank: findData?.bank, bankAccount: findData?.bankAccount, bankHolderName: findData?.bankHolderName };
            });
            calculateListMonthExcelDown(mattchedList as Array<CustomType>);
        }
    };

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue((prev) => ({ ...prev, value }));
    };

    const handleKeyupSearch = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && searchValue.value.trim() !== '') {
            hadleClickSearch();
        }
        if (e.key === 'Enter' && searchValue.value.trim() === '') {
            toast.error('키워드를 입력하세요.');
        }
    };

    const handleClickSorting = (name: 'hospitalCode' | 'totalPrice' | 'totalHospitalCalPrice') => {
        setSortInfo((prev) => ({ name, type: !prev.type }));
    };

    const filteringList = (list: IOoCalculateMonthList[]) => {
        if (searchValue.filterName === 'hospitalCode') {
            const result = list.filter((item) => item.hospitalCode.toLocaleUpperCase().indexOf(searchValue.value.toLocaleUpperCase()) > -1);
            setDisplayList((prev) => ({ ...prev, list: result }));
        } else {
            const result = list.filter((item) => item.hospitalName.toLocaleUpperCase().indexOf(searchValue.value.toLocaleUpperCase()) > -1);
            setDisplayList((prev) => ({ ...prev, list: result }));
        }
    };

    const onShowCalendar = (type: string) => {
        setShowModal({ type: type, show: true });
    };

    const handleClickReset = () => {
        // if(target === 'calculate' ){
        setDisplayList((prev) => ({ ...prev, list }));
        setSearchValue({ filterName: 'hospitalCode', value: '' });
        // }
    };

    const handleChageFilterType = (e: ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;
        setSearchValue((prev) => ({ ...prev, filterName: value }));
    };

    const totalPrice = (type: 'price' | 'hospitalPrice') => {
        const list = displayList.list as Array<IOoCalculateList>;
        if (type === 'price') {
            const price = _.sumBy(list, (item) => item.totalPrice).toLocaleString();
            return price;
        } else {
            const price = _.sumBy(list, (item) => item.totalHospitalCalPrice).toLocaleString();
            return price;
        }
    };

    const changeDate = (value: Date) => {
        setMonth(moment(value).format('YYYYMM'));
        setShowModal((prev) => ({ ...prev, show: false }));
    };

    const closeCalendar = () => {
        setShowModal((prev) => ({ ...prev, show: false }));
    };

    // ! 수정 필요
    const hadleClickSearch = () => {
        filteringList(list as IOoCalculateMonthList[]);
        setSearch(searchValue.value);
    };

    const setSearch = useCallback(
        (text: string) => {
            queryParams.set('search', text);

            navigate({ search: queryParams.toString() });
        },
        [location.search, navigate]
    );

    useEffect(() => {
        const searchValue = queryParams.get('search') || '';
        setSearchValue((prev) => ({ ...prev, value: searchValue }));

        setDisplayList((prev) => ({ ...prev, list }));
        // hadleClickSearch();
    }, [queryParams.get('search')]);

    return (
        <Wrapper>
            <MainListTitleWrapper title='업체별 월 정산'>
                <MainListTitleWrapperTitle>업체별 월 정산 ({displayList.list?.length}건)</MainListTitleWrapperTitle>
                <ExcelButton onClick={handleClickDownLoadExcel}>
                    <FaFileExcel />
                    <span className='excelDownText'>다운로드</span>
                </ExcelButton>
                <LayoutContainer>
                    <span>
                        총 매출액<div>{totalPrice('price')}</div>원
                    </span>
                    |
                    <span>
                        총 거래처 정산금액<div>{totalPrice('hospitalPrice')}</div>원
                    </span>
                    <ResetBtn type='button' onClick={handleClickReset} disabled={searchValue.value ? false : true}>
                        초기화
                    </ResetBtn>
                    <SelectBoxContainer>
                        <select onChange={handleChageFilterType} value={searchValue.filterName}>
                            <option value='hospitalCode'>거래처코드</option>
                            <option value='hospitalName'>거래처명</option>
                        </select>
                        <input value={searchValue.value} onChange={handleChangeSearch} onKeyUp={handleKeyupSearch} placeholder='검색 키워드 입력' />
                        <button type='submit' onClick={hadleClickSearch}>
                            <LuSearch />
                        </button>
                    </SelectBoxContainer>
                    <CalendarButton onClick={() => onShowCalendar('year')}>
                        <div>
                            <img src={process.env.PUBLIC_URL + `/img/calendar-icon.png`} alt='calendar' />
                            {moment(month).format('YYYY-MM')}
                        </div>
                    </CalendarButton>
                    {showModal.show && showModal.type === 'year' && (
                        <ModalOverlay visible={showModal.show}>
                            {/* 정산 */}
                            <CalendarContainer>
                                <Calendar locale='ko' onClickMonth={changeDate} next2Label={null} prev2Label={null} view={'year'} />
                                <CloseButton onClick={closeCalendar}>닫기</CloseButton>
                            </CalendarContainer>
                        </ModalOverlay>
                    )}
                </LayoutContainer>
            </MainListTitleWrapper>
            <CalculateListSubTitle level={selectedUser && selectedUser.user.level} handleClickSorting={handleClickSorting!} />
            {(displayList.list || []).length > 0 ? <CalculateListContainer data={(displayList.list as Array<IOoCalculateList>) || []} sortInfo={sortInfo} /> : <div>표시 할 내용이 없습니다.</div>}
        </Wrapper>
    );
};

export default ListDashBoard;

const Wrapper = styled.div`
    width: 100%;
    max-width: 55%;
    min-width: 1400px;
    height: 95%;

    @media (min-width: 768px) and (max-width: 2400px) {
        .excelDownText {
            display: none;
        }
    }
`;

const MainListTitleWrapper = styled.div<WrapperStyle>`
    width: 100%;
    height: 80px;
    background: ${(props) => props.theme.colors.bgColor.white};
    border: 0.5px solid ${(props) => props.theme.colors.borderColor.grey};
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
`;

const MainListTitleWrapperTitle = styled.div`
    width: fit-content;
    height: 80px;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 32px;
`;

const LayoutContainer = styled.div`
    height: 100%;
    align-items: center;
    display: flex;
    column-gap: 10px;
    margin-right: 10px;

    span {
        display: flex;
        align-items: center;
        column-gap: 5px;

        div {
            background-color: #e0e0e0;
            border-radius: 5px;
            padding: 2px 5px;
            font-weight: 700;
        }
    }
`;

const ResetBtn = styled.button`
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    border: 1px solid ${(props) => (props.disabled ? 'gray' : '#CF5D5D;')} background-color ${(props) => (props.disabled ? 'gray' : '#CF5D5D;')} color #ffffff;
    padding: 1px 10px;
    &:hover {
        font-weight: bold;
    }
`;

const SelectBoxContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    position: relative;

    select {
        border: 1px solid #cccccc;
        height: 35px;
        border-radius: 5px;
        padding: 0px 5px;
    }

    input {
        border-radius: 5px;
        border: 1px solid rgb(204, 204, 204);
        height: 35px;
        padding: 0px 45px 0px 10px;

        &:focus {
            outline: none;
        }
    }

    button {
        width: 35px;
        height: 35px;
        background-color: transparent;
        border: none;
        border-radius: 5px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0px;
        border: 1px solid #cccccc;
        &:hover {
            font-size: x-large;
        }
    }
`;

const CalendarButton = styled.button`
    padding: 0px 15px;
    height: 36px;
    border-radius: 8px;
    border: 0.5px solid #c2c1bd;
    background: #ffffff;
    font-weight: 400;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 35px;

    & > div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        & > img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    & > div:nth-child(2) {
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
            width: 20px;
            height: 20px;
        }
    }

    & > div:nth-child(3) {
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }
`;

const CalendarContainer = styled.div`
    width: 600px;
    height: 500px;
    border-radius: 25px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;

    .react-calendar {
        border: none;
        margin: 0 auto;
    }
`;

const CloseButton = styled.button`
    width: 100px;
    height: 36px;
    border-radius: 8px;
    border: none;
    align-self: end;
    margin-right: 50px;
    background-color: white;
    font-weight: 400;
    font-size: 13px;
    border: 1px solid #c2c1bd;

    &:hover {
        font-weight: bold;
    }
`;

const ExcelButton = styled.button`
    background: white;
    padding: 5px 10px;
    border-radius: 10px;
    border: 0.5px solid rgb(194, 193, 189);
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;

    & > svg {
        width: 20px;
        height: 20px;
        color: #207244;
    }

    &:hover {
        background: rgb(194, 193, 189);
    }
`;
