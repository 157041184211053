import { IOoUnospayDelivery, OoOrderPlatformType } from '@models/unospay';
import { useUnospayOrderPlatformRtdbHook } from '@hooks/unospay/useUnospayOrderPlatformRtdbHook';

interface Props {
    number: number;
    list: Array<IOoUnospayDelivery>;
}

export const useUnospayOrderPlatformFilter = ({ number, list }: Props) => {
    const { total, kiosk, mtmMall, telephone, test, tlcMall, noTest } = useUnospayOrderPlatformRtdbHook({ list: list });

    switch (number) {
        case OoOrderPlatformType.kiosk:
            return kiosk();
        case OoOrderPlatformType.mtmMall:
            return mtmMall();
        case OoOrderPlatformType.telephone:
            return telephone();
        case OoOrderPlatformType.test:
            return test();
        case OoOrderPlatformType.tlcMall:
            return tlcMall();
        case OoOrderPlatformType.noTest:
            return noTest();
        default:
            return total();
    }
};
