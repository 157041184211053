import { Statistics, StatisticsInfo } from '@api/models/calculate/calculate';
import { DoctorOnAPI } from '@api/request';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import { useNavbarHooks } from '@components/layout/headerContainer/Header/Navbar/hooks/useNavbarHooks';
import calculateListYearExcelDown from '../../Operator/UnospayDashBoardPage/components/ExcelDownloadButton/components/calculateListYearExcelDown';
import { FaFileExcel } from 'react-icons/fa';
import { Pie } from '@nivo/pie';
import PieChart from '@components/chart/pieChart';
import MyResponsivePie from '@components/chart/nivoChart';
import { useSuspenseHook } from '@hooks/suspense';
import { ITClientNotice, HiddenItemsType, VisibleNoticeData } from '@api/models/notice/clientNotice/notice';
import { isRequestSucceed } from '@api/reponses';
import { setClientTypeIntToString } from 'functions/setClientTypeFunctions';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'functions/localStoarageFunction';
import { ClientTargetConvertIntoInt } from '@api/models/client/clientEnum';
import { ModalBackground } from '@components/modal/basicModal/ModalForms';
import NoticeModal from '@components/modal/basicModal/NoticeModal';

interface FontSize {
    size: number;
}

interface ContainerStyle {
    height: number;
    overflow?: string;
}

interface Repeat {
    width?: number;
    rows?: number;
    columns?: number;
    justify_div?: string;
    justify_span?: string;
}

interface Article {
    flex: string;
    gap?: number;
    justifyContent?: string;
}

interface BarStyle {
    height?: number;
}

interface LayoutStyle {
    width?: string;
}

interface ArticleItemSubLayoutStyle {
    padding?: string;
}

const DoctorCalculateBoardPage = () => {
    const [statistics, setStatistics] = useState<Statistics[]>();
    const [statisticsInfo, setStatisticsInfo] = useState<StatisticsInfo>();
    const [selectedYear, setSelectedYear] = useState<string>(moment().format('YYYY'));
    const [clientTarget, setClientTarget] = useState<string>('');
    const [visibleNoticeData, setVisibleNoticeData] = useState<VisibleNoticeData[]>();
    const [modalCount, setModalCount] = useState(0);

    const { setLoading, setStopLoading } = useSuspenseHook();
    const { cx, oauthList, selectedUser, navigate, onClickLogout, GetUserList } = useNavbarHooks();

    useEffect(() => {
        getCalculateDetailData();
        findByAdminEmailApi();
    }, [selectedUser]);

    useEffect(() => {
        if (clientTarget) noticeListApi();
    }, [clientTarget]);

    const findByAdminEmailApi = async () => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.findByAdminEmail(selectedUser!.user.email);

            if (isRequestSucceed(response)) {
                const clientType = setClientTypeIntToString(response.data?.clientType);
                setClientTarget(clientType);
            }
            return response;
        } catch (e: any) {
            console.log(e);
        }
    };

    const noticeListApi = async () => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.getNoticePopup(String(ClientTargetConvertIntoInt[clientTarget as keyof typeof ClientTargetConvertIntoInt]));

            if (isRequestSucceed(response)) {
                const visibleData = response.data?.map((item) => ({ data: item, onModal: true }));
                const hiddenItemsKey = 'hiddenItems';

                if (!visibleData || visibleData.length === 0) {
                    removeLocalStorage(hiddenItemsKey);
                    setVisibleNoticeData(visibleData);
                } else {
                    setVisibleNoticeModalFunction(visibleData);
                }
            }
            return response;
        } catch (e: any) {
            console.log(e);
        }
    };

    // 화면에 노출할 모달 설정(로컬 스토리지에 저장된 데이터와 비교하여 설정)
    const setVisibleNoticeModalFunction = (visibleData: { data: ITClientNotice; onModal: boolean }[]) => {
        const hiddenItemsKey = 'hiddenItems';
        const hiddenItems: HiddenItemsType[] = getLocalStorage(hiddenItemsKey);
        const now = new Date().getTime();

        if (!hiddenItems) {
            const newVisibleNoticeData = visibleData.map((item) => {
                return { data: item.data, onModal: true };
            });
            return setLocalStorage(hiddenItemsKey, newVisibleNoticeData);
        } else if (visibleData && hiddenItems) {
            // visibleData에 없는 hiddenItems를 필터링하여 로컬 스토리지에서 제거
            const updatedHiddenItems = hiddenItems.filter((hiddenItem) => visibleData.some((item) => item.data._id === hiddenItem._id));

            const newVisibleNoticeData = visibleData.map((item) => {
                if (hiddenItems.some((hiddenItem) => hiddenItem._id === item.data._id && now > hiddenItem.timestamp)) {
                    return { data: item.data, onModal: true };
                } else if (hiddenItems.some((hiddenItem) => hiddenItem._id === item.data._id && now <= hiddenItem.timestamp)) {
                    console.log(123);
                    console.log(modalCount, 'inininin');
                    return { data: item.data, onModal: false };
                }
                return item;
            });

            setLocalStorage(hiddenItemsKey, updatedHiddenItems);
            setVisibleNoticeData(newVisibleNoticeData);

            // modal count에 따른 background dim 처리
            if (newVisibleNoticeData.some((visibleNoticeData) => visibleNoticeData.onModal)) {
                const modalCount = newVisibleNoticeData.filter((item) => item.onModal).length;
                setModalCount(modalCount);
            } else {
                setModalCount(0);
            }
        }
    };

    const getPrevMonth = (monthData?: string, yearData?: string) => {
        if (monthData) {
            const year = yearData;
            const month = monthData;
            const date = moment(`${year}-${month}`).format('YYYY-MM-DD');

            const startDate = moment(date).format('YYYY .MM .DD');
            const endDate = moment(date).endOf('month').format('YYYY .MM .DD');

            return `${startDate} ~ ${endDate}`;
        }
    };

    const handleClickYear = () => {
        calculateListYearExcelDown('', _.orderBy(statistics, 'month'));
    };

    const getCalculateDetailData = async () => {
        try {
            if (!_.isUndefined(selectedUser!.user)) {
                const code = selectedUser!.user.email.split('@')[0];
                setLoading();
                const response = await DoctorOnAPI.shared.calculate.getOrderStatisticsDataMonth(selectedYear, code.toLocaleUpperCase());
                setStopLoading();
                if (response.data) {
                    const monthFiltered = response.data.statistics.filter((item) => {
                        if (new Date().getFullYear() === 2024) {
                            return Number(item.month) > 6 && item;
                        } else {
                            return item;
                        }
                    });
                    setStatistics(monthFiltered);
                    setStatisticsInfo(response.data.statisticsInfo);
                }
            }
        } catch (error: any) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getCalculateDetailData();
    }, [selectedUser, selectedYear]);
    const handleModal = (id: string, type?: string) => {
        const newVisibleNoticeData = visibleNoticeData?.map((item) => {
            if (item.data._id === id) {
                if (type === 'week') {
                    const now = new Date();
                    const sevenDaysLater = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7, 0, 0, 0, 0); // 7일 뒤 00:00:00
                    const timestamp = sevenDaysLater.getTime();
                    // const now = new Date().getTime();
                    // const oneMinute = 20 * 1000; // 1분 TODO: 시간 수정하기

                    console.log(now.getTime(), timestamp, timestamp - now.getTime());
                    const hiddenItemsKey = 'hiddenItems';
                    const hiddenItems: HiddenItemsType[] = getLocalStorage(hiddenItemsKey) || [];

                    const updatedHiddenItems = hiddenItems.map((item) => {
                        if (item._id === id) {
                            return { ...item, timestamp: timestamp };
                        }
                        return item;
                    });

                    // 해당 ID가 hiddenItems에 없는 경우 새로운 항목 추가
                    if (!updatedHiddenItems.some((item) => item._id === id)) {
                        updatedHiddenItems.push({ _id: id, timestamp: timestamp });
                    }

                    setLocalStorage(hiddenItemsKey, updatedHiddenItems);
                }
                setModalCount(modalCount - 1);
                return { data: item.data, onModal: false };
            }
            return item;
        });

        setVisibleNoticeData(newVisibleNoticeData);
    };

    return (
        <Wrapper>
            {modalCount > 0 && <ModalBackground> </ModalBackground>}
            {visibleNoticeData
                ?.filter((item) => item.onModal)
                .map((item, index, filteredData) => {
                    const top = '25%';
                    const gap = 500; // 모달 간의 간격 (px)
                    const left = `calc(50vw - 280px - ${((filteredData.length - 1) * gap) / 2}px + ${index * gap}px)`;

                    return (
                        <NoticeModal
                            title='공지사항'
                            content={item.data.title}
                            id={item.data._id}
                            firstButton='일주일간 보지 않기'
                            secondButton='닫기'
                            setIsLeaveModal={handleModal}
                            $top={top}
                            $left={left}
                        />
                    );
                })}

            <Body>
                <Nav>
                    <TabItem>정산정보</TabItem>
                </Nav>
                <Article>
                    <Layout>
                        <ArticleRow flex='column' gap={30}>
                            <ArticleColumn flex='row' gap={30}>
                                <ArticleItemLayout>
                                    <ArticleItemTitle>정산정보</ArticleItemTitle>
                                    <ArticleItemSubLayout padding='20px 0px'>
                                        <ArticleItem>
                                            <span>은행명</span>
                                            {statisticsInfo?.bank ? <h6>{statisticsInfo?.bank}</h6> : <p>등록된 데이터가 없습니다.</p>}
                                        </ArticleItem>
                                        <Bar height={statisticsInfo ? 70 : 30} />
                                        <ArticleItem>
                                            <span>계좌번호</span>
                                            {statisticsInfo?.bankAccount ? <h6>{statisticsInfo?.bankAccount}</h6> : <p>등록된 데이터가 없습니다.</p>}
                                        </ArticleItem>
                                        <Bar height={statisticsInfo ? 70 : 30} />
                                        <ArticleItem>
                                            <span>예금주</span>
                                            {!_.isUndefined(statisticsInfo) ? <h6>{statisticsInfo.bankHolderName}</h6> : <p>등록된 데이터가 없습니다.</p>}
                                        </ArticleItem>
                                    </ArticleItemSubLayout>
                                </ArticleItemLayout>
                                {/* <ArticleItemLayout>
                                    <ArticleItemTitle>공지사항</ArticleItemTitle>
                                    <ArticleItemSubLayout padding='20px 35px'>
                                        <div>{statisticsInfo?.memo ? statisticsInfo?.memo : '등록된 데이터가 없습니다.'}</div>
                                    </ArticleItemSubLayout>
                                </ArticleItemLayout> */}
                            </ArticleColumn>
                        </ArticleRow>
                        <ArticleRow flex='column' gap={30}>
                            <ArticleColumn flex='row' justifyContent='space-between'>
                                <SubTitle size={20}>매출 및 정산 금액</SubTitle>
                                <YearSelectWrapper>
                                    <YearSelect value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                                        {Array.from({ length: 2 }, (_, i) => Number(selectedYear) - 1 + i).map((year) => (
                                            <option key={year} value={year}>
                                                {year}년
                                            </option>
                                        ))}
                                    </YearSelect>
                                    <ExcelBtn onClick={handleClickYear}>
                                        <FaFileExcel />
                                        다운로드
                                    </ExcelBtn>
                                </YearSelectWrapper>
                            </ArticleColumn>
                            <Container height={80}>
                                <ArticleColumn flex='column' gap={30}>
                                    <Grid width={100} columns={3} justify_div='center'>
                                        <div>기간</div>
                                        <div>월별 매출액</div>
                                        <div>월별 정산 금액</div>
                                    </Grid>
                                    <Container height={90} overflow='scroll'>
                                        {!_.isUndefined(statistics) && statistics.length > 0 ? (
                                            _.orderBy(statistics, 'month').map((item) => {
                                                return (
                                                    <LinkStyle
                                                        to={`/calculateMonthDetail/${selectedYear}`}
                                                        state={{
                                                            hospitalId: statisticsInfo?.hospitalId,
                                                            month: item.month,
                                                        }}
                                                    >
                                                        <Grid columns={3}>
                                                            <span>{getPrevMonth(item.month, selectedYear)}</span>
                                                            <span>{item.totalPrice.toLocaleString()}</span>
                                                            <span>{item.totalHospitalCalPrice.toLocaleString()}</span>
                                                        </Grid>
                                                    </LinkStyle>
                                                );
                                            })
                                        ) : (
                                            <Grid justify_span='center'>
                                                <span>데이터 없음</span>
                                            </Grid>
                                        )}
                                    </Container>
                                </ArticleColumn>
                            </Container>
                        </ArticleRow>
                    </Layout>
                    <Layout>{/* <MyResponsivePie data={formattingData(statistics)} /> */}</Layout>
                </Article>
            </Body>
        </Wrapper>
    );
};

export default DoctorCalculateBoardPage;

const Wrapper = styled.div`
    position: relative;
    min-width: 1024px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
`;
const Body = styled.div`
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;

    @media (max-width: 1024px) {
        width: 65%;
    }

    @media (max-width: 1280px) and (min-width: 1024px) {
        width: 85%;
    }
`;

const Nav = styled.div`
    width: 95%;
    display: flex;
`;

const Article = styled.div`
    width: 95%;
    display: grid;
    grid-template-columns: 1100px auto;

    @media (max-width: 1024px) {
        width: 80%;
        display: grid;
        grid-template-columns: 100% auto;
    }
    @media (max-width: 1280px) and (min-width: 1024px) {
        width: 95%;
        display: grid;
        grid-template-columns: 600px auto;
    }
    @media (max-width: 1366px) and (min-width: 1280px) {
        width: 95%;
        display: grid;
        grid-template-columns: 700px auto;
    }
    @media (max-width: 1420px) and (min-width: 1366px) {
        width: 95%;
        display: grid;
        grid-template-columns: 800px auto;
    }
    @media (max-width: 1600px) and (min-width: 1420px) {
        width: 95%;
        display: grid;
        grid-template-columns: 1000px auto;
    }
`;

const TabItem = styled.div`
    width: 150px;
    padding: 25px 0px;
    font-size: 20px;
    font-weight: 700;
    background: #ffffff;
    color: #1c6fc9;
    border-top: none;
    border-left: none;
    border-right: none;
    &:first-child {
        border-top-left-radius: 30px;
    }
`;

const Layout = styled.div<LayoutStyle>`
    width: ${(props) => props.width}%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    row-gap: 30px;
`;

const LinkStyle = styled(Link)`
    color: black;
    text-decoration: none;
    &:hover,
    &:focus {
        font-weight: bold;
    }
`;

const SubTitle = styled.span<FontSize>`
    font-size: ${(props) => props.size}px;
    font-weight: bold;
`;

const ArticleRow = styled.div<Article>`
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: ${(props) => props.flex};
    row-gap: ${(props) => props.gap}px;
`;

const Container = styled.div<ContainerStyle>`
    display: flex;
    flex-direction: column;
    height: ${(props) => props.height}%;
    overflow: ${(props) => props.overflow};
`;

const ArticleColumn = styled.div<Article>`
    display: flex;
    flex-direction: ${(props) => props.flex};
    column-gap: ${(props) => props.gap}px;
    justify-content: ${(props) => props.justifyContent};
`;

const ArticleItemLayout = styled.div`
    width: 100%;
    border-radius: 15px;
    border: 1px solid #e9ecef;
`;
const ArticleItemTitle = styled.div`
    background-color: #edf2f7;
    height: 50px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
    padding: 20px 35px;
    font-weight: bold;
    font-size: 16px;
`;

const ArticleItemSubLayout = styled.div<ArticleItemSubLayoutStyle>`
    padding: ${(props) => props.padding};
    display: flex;
    justify-content: space-between;
`;

const ArticleItem = styled.div`
    width: 100%;
    text-align: center;

    p,
    h6 {
        padding-top: 20px;
    }
`;

const Bar = styled.div<BarStyle>`
    width: 1px;
    height: ${(props) => props.height}px;
    background: #dbdcdf;
`;

const ExcelBtn = styled.button`
    width: 100px;
    height: 35px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    background-color: #ffffff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;

    svg {
        color: #2176f6;
        font-size: 18px;
    }

    :hover {
        background-color: #2176f6;
        color: #ffffff;
        font-weight: 700;
        svg {
            color: #ffffff;
        }
    }
`;

const Grid = styled.div<Repeat>`
    width: ${(props) => `${props.width}%`};
    display: grid;
    grid-template-rows: ${(props) => `repeat(${props.rows}, 1fr)`};
    grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};

    div {
        padding: 10px;
        border: 1px solid #e9ecef;
        background: #f2f7fc;
        color: black;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: ${(props) => props.justify_div};
    }
    span {
        padding: 10px;
        border: 1px solid #e9ecef;
        display: flex;
        align-items: center;
        justify-content: ${(props) => props.justify_span};
    }
`;

const YearSelectWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const YearSelect = styled.select`
    padding: 5px 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    height: 35px;
`;
