import { useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useFindIdUnospayQuery } from '@queries/unospay';
import { IOoUnospayDelivery, IOoUnospaySalesMenu, unospayPaymentTypeFilter } from '@models/unospay';
import { formatPrice } from '@hooks/format';
import { OrderDetailInfo } from 'pages/Operator/UnospayOrderDetailPage/components/OrderDetailInfo';
import { useUnosOrderDetailPageSettingHook } from 'pages/Operator/UnospayOrderDetailPage/hooks/useUnosOrderDetailPageSettingHook';
import { useFindDeliveryIdGfOrderQuery } from '@queries/unospay/findByDeliveryIdGfOrderQuery';

export const UnospayDeliveryListPopUp = () => {
    const location = useLocation();

    const memolizedId = useMemo(() => {
        const arr = location.pathname.split('/');
        return _.last(arr);
    }, [location.pathname]);
    console.log(memolizedId, 'memolizedId');

    const result = useFindIdUnospayQuery({ id: memolizedId || '' });

    const [data, setData] = useState<IOoUnospayDelivery | undefined>(result.data);

    const { data: gfOrderList } = useFindDeliveryIdGfOrderQuery({
        id: memolizedId || '',
    });

    useEffect(() => {
        setData(result.data);
    }, [result.data]);

    const getProviderName = (code: string) => {
        if (_.isUndefined(data)) return '-';
        const target = data.transportInfo.find((item) => _.isEqual(code, item.externalCode));
        if (_.isUndefined(target)) return code;
        return target.providerName;
    };

    const getOptionsPrice = (item: IOoUnospaySalesMenu): number => {
        const result = item.salesOption?.reduce((acc, cur) => {
            acc = _.toNumber(acc);
            acc += _.toNumber(cur.optionPrice);
            return acc;
        }, 0);
        return result || 0;
    };

    console.log('gfData', gfOrderList);

    return (
        <Wrapper>
            <Container>
                <OrderDetailInfo data={data!} gfOrderList={gfOrderList || []}></OrderDetailInfo>
                {/* <Title>주문 상세내역</Title>
        {!_.isUndefined(data) && !_.isUndefined(data.salesMenu) ? (
          <Contents>
            {data.salesMenu.map((item, index) => (
              <ProductContainer key={index}>
                <SubTitle>상품 {index + 1}</SubTitle>
                <Table>
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>상품명</td>
                      <td>{item.menuName || ""}</td>
                      <td>카테고리</td>
                      <td>{item.categoryName || ""}</td>
                    </tr>
                    <tr>
                      <td>제조회사</td>
                      <td>{getProviderName(item.externalCode || "")}</td>
                      <td>수량</td>
                      <td>{item.menuCount || ""}</td>
                    </tr>
                    {!_.isUndefined(item.salesOption) &&
                      item.salesOption.map((item, index) => (
                        <tr key={data?.mobile || index}>
                          <td>옵션{index + 1} </td>
                          <td>{item.groupName || ""}</td>
                          <td>선택</td>
                          <td>{item.optionName || ""}</td>
                        </tr>
                      ))}
                    <tr>
                      <td>금액</td>
                      <td rowSpan={2}>
                        {formatPrice(
                          Number(item.menuPrice || "0") *
                            Number(item.menuCount || "1") +
                            getOptionsPrice(item) *
                              Number(item.menuCount || "0")
                        )}
                        원
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <OrderDetailInfo data={data} gfOrderList={gfOrderList || []}></OrderDetailInfo>
              </ProductContainer>
            ))}
            <BottomContainer>
              <SubTitle>결제 정보</SubTitle>
              <Table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>결제금액</td>
                    <td>{formatPrice(Number(data?.paymentPrice || "0"))}원</td>
                    <td>결제수단</td>
                    <td>
                      {unospayPaymentTypeFilter(
                        Number(data?.paymentType || "1")
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </BottomContainer>
          </Contents>
        ) : (
          <DisabledTitle>주문 상세 내역이 존재하지 않습니다.</DisabledTitle>
        )} */}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: scroll;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 46px;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 16px;
`;

const Contents = styled.div`
    height: auto;
`;

const SubTitle = styled.div`
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
`;

const ProductContainer = styled.div`
    width: 100%;
`;

const Table = styled.table`
    border-top: 2px solid #c8d6f8;
    border-bottom: 2px solid #c8d6f8;
    width: 100%;

    & > tbody tr {
        border-bottom: 2px solid #c8d6f8;
        height: 48px;
    }

    & > tbody tr td:nth-child(2n-1) {
        width: 100px;
        min-width: 100px;
        background: #f4f6fb;
        text-align: center;
        font-weight: 700;
        font-size: 13px;
    }

    & > tbody tr td:nth-child(2n) {
        width: 40%;
        font-weight: 400;
        font-size: 13px;
        text-align: left;
        padding-left: 20px;
    }
`;

const BottomContainer = styled.div`
    padding-top: 17px;
`;

const DisabledTitle = styled.div`
    position: relative;
    top: 100px;
    bottom: 0;
    width: 100%;
    padding-left: 46px;
`;
