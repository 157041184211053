import { useSuspenseHook } from "../../hooks";
import { useMutation } from "react-query";
import { DoctorOnAPI, isRequestSucceed, OoParamId } from "../../api";
import { toast } from "react-toastify";
import { OoUnospaySendTransportAlimtalk } from "@api/params/unospay/sendTranportAlimtalk";

export const useSendTransportAlimtalkQuery = () => {
  const { setLoading, setStopLoading } = useSuspenseHook();
  return useMutation("unospay/sendTransportAlimtalk", async (param: OoUnospaySendTransportAlimtalk) => {
    try {
      setLoading();
      const response = await DoctorOnAPI.shared.unospay.sendTransportAlimtalk(
        param
      );
      setStopLoading();
      if (isRequestSucceed(response)) {
        toast.success("알림톡을 전송했습니다.");
        return response;
      }
      return undefined;
    } catch (e: any) {
      throw e;
    }
  });
};
