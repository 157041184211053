import { ITPagination } from '../pagination/customPaginationType';
import { ProductStatus, ProductVisibilityTarget } from './productEnum';

export interface ITOptionList {
    optionCode: string;
    optionName: string;
    optionOrderCode: string;
    optionOrderName: string;
    optionPrice: number;
    optionSupplyPrice: number;
    optionMarginRate: number;
    optionPeriod: number;
    optionProductCount: number;
    optionDayToSend: number;
    _id?: string;
}

export interface ITProduct {
    _id?: string;
    orderProductCode: string;
    orderProductName: string;
    productName: string;
    productType: number;
    providerName: string;
    externalCode: string;
    deliveryPrice: number;
    retailPrice: number;
    supplyPrice: number;
    settleRate: number;
    marginRate: number;
    isPurchasedProduct: boolean;
    isRepurchaseAlimtalk: boolean;
    dayToSend?: number;
    status: number;
    visibilityTarget: string[];
    optionList?: ITOptionList[];
    mainCat?: string;
    subCat?: string;
    mtsCat1?: string;
    mtsCat2?: string;
    mtsCat3?: string;
    departments?: string[];
    productPeriod?: number;
    productCount?: number;
    timestamp?: number;
}

export interface GetProductsSearchApiResponse {
    products: ITProduct[];
    pagination: ITPagination;
}

export interface ProductListTableProps {
    data: ITProduct[];
    // totalCount: number;
    currentPage: number;
    sectionIndex: number;
    totalPages: number;
    onChangeCurrentPage: (page: number, newGroup?: number) => void;
    onChangeSectionIdx: (group: number, newPage: number) => void;
}

type ViewProductTargetType = {
    label: string;
    target: ProductVisibilityTarget;
    selected: boolean;
};

export enum ProductType {
    MTM = 0,
    MTE,
    MTS,
    MTC,
    MDS, // Medical Devices & Supplies
}

export enum PurchaseProductType {
    사입 = 0,
    위탁,
}

export namespace ProductsCreateOptionList {
    export const YesOrNo: string[] = ['Y', 'N'];
    export const productTypeList: string[] = ['MTM', 'MTE', 'MTS', 'MTC', 'MDS'];
    export const purchasedProductList: string[] = ['사입', '위탁'];
    export const optionTitle: string[] = ['옵션코드', '옵션명', '발주서 옵션코드', '발주서 옵션명', '옵션별 금액', '옵션별 공급가', '옵션별 마진율', '옵션별 기간', '옵션별 개수', '알림톡 발송일자'];
    export const divideCategoryList: string[] = ['중분류', '소분류', '옵션 1', '옵션 2', '옵션 3'];
    export const ProductStatusList: string[] = ['판매중지', '판매중', '품절'];

    export const ViewProductStatus: { [key in ProductStatus]: string } = {
        [ProductStatus.INACTIVE]: '판매중지',
        [ProductStatus.ACTIVE]: '판매중',
        [ProductStatus.SOLD_OUT]: '품절',
    };

    export const ViewProductTarget: ViewProductTargetType[] = [
        { label: '병의원', target: ProductVisibilityTarget.WESTERN_MEDICINE, selected: false },
        { label: '한의원', target: ProductVisibilityTarget.ORIENTAL_MEDICINE, selected: false },
        { label: 'B2B', target: ProductVisibilityTarget.B2B, selected: false },
        { label: '피트니스', target: ProductVisibilityTarget.FITNESS, selected: false },
    ];
}

// export interface ClientCreateRqData {
//     name: string; // required
//     code: string; // required
//     tel: string; // required
//     address: string; // required
//     addressDetail: string;
//     zipCode: string; // required
//     bank: string;
//     bankAccount: string;
//     bankHolderName: string;
//     referrerList: string[];
//     clientType: number;
//     businessNumber: string;
// }
