class RawExcelExportModel {
    id?:string = "";
    payerName?:string = "";
    payerMobile?:string = "";
    productType?:string = "";
    productSettleRate?:number = 0;
    dateTime:string = "";
    hospitalCode:string = "";
    hospitalName:string = "";
    orderPlatform:string = "";
    hospitalCalPrice:number = 0;
    externalCode:string = "";
    goods:string = "";
    paymentPrice:number = 0;
    providerName:string = "";
    refererList:string = "";
    refererCalPrice:string = "";
    orderId:string = "";
    cancelDateTime:string = "";
    operatorCancelTimestamp?:number = 0;
    totalPrice?:number = 0;
    totalHospitalCalPrice?:number = 0;
    referer: string[] = [];
    totalRefererCalPrice: number[] = [];
    type?:number = 0;
    bank:string = "";
    bankAccount:string = "";
    bankHolderName: string = "";
    clientType?:string = "";
    mainCat?:string = "";
    subCat?:string = "";
    mtsCat1?:string = "";
    mtsCat2?:string = "";
    mtsCat3?:string = "";
    marginRate?:number = 0;
    marginPrice?:number = 0;
    productPeriod?:number = 0;
    productCount?:number = 0;
    reorderCount?:number = 0;
    group_name?:string = "";
    supplyPrice?:number = 0;
    recipientName?:string = "";
}

export { RawExcelExportModel };