import { useSuspenseHook } from "../../hooks";
import { DoctorOnAPI, isRequestSucceed } from "../../api";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import OoUnospayUpdateGfMenu from "@api/params/unospay/updateGfMenu";

export const useUpdateGfMenuQuery = () => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

  return useMutation(
    "unospay/updateGfMenu",
    async (item: OoUnospayUpdateGfMenu) => {
      try {
        setLoading();
        const param = new OoUnospayUpdateGfMenu();
        param.id = item.id;
        param.index = item.index;
        param.code = item.code;
        param.name = item.name;
        const response = await DoctorOnAPI.shared.unospay.updateGfMenu(
          param
        );
        setStopLoading();
        if (isRequestSucceed(response)) {
          toast.success("메뉴 수정 완료");
          return response;
        }
        return undefined;
      } catch (e: any) {
        throw e;
      }
    }
  );
};