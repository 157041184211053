import { ITProduct, ITOptionList, ProductsCreateOptionList, ProductType, PurchaseProductType } from '@api/models/product/product';
import { isRequestSucceed } from '@api/reponses';
import { DoctorOnAPI } from '@api/request';
import { DropdownWithLabel } from '@components/dropdown/clientTypeDropdown';
import BasicButton from '@components/editButton/BasicButton';
import { AddAndDeleteColumnInputWithLabel, ColumnInputWithLabel } from '@components/input/input';
import ConfirmModal from '@components/modal/basicModal/ConfirmModal';
import useToggle from '@hooks/common/useToggle';
import { useSuspenseHook } from '@hooks/suspense';
import { selectedUserState } from '@recoils/oauth';
import { BackgroundColor, BorderColor, InverseColor, PrimaryColor } from '@styles/colorSemantic';
import { font } from '@styles/globalStyles';
import { Input } from '@styles/input';
import { Body3_SemiBold, Body4_Medium } from '@styles/typography';
import { ChangeEvent, useEffect, useState } from 'react';
import { MdDelete, MdLibraryAdd } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

const AddProductPage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const selectedUser = useRecoilValue(selectedUserState);

    const [initData, setInitData] = useState<ITProduct>();
    const [orderProductCode, setOrderProductCode] = useState<string>(''); // 발주서 코드
    const [orderProductName, setOrderProductName] = useState<string>(''); // 발주서 제품명
    const [productName, setProductName] = useState<string>('');
    const [productType, setProductType] = useState<number>(ProductType.MTM);
    const [providerName, setProviderName] = useState<string>(''); // 업체명
    const [externalCode, setExternalCode] = useState<string>(''); // 제품 코드
    const [deliveryPrice, setDeliveryPrice] = useState<string>(''); // 업체 배송비
    const [retailPrice, setRetailPrice] = useState<string>('');
    const [supplyPrice, setSupplyPrice] = useState<string>('');
    const [settleRate, setSettleRate] = useState<string>(''); // 요율
    const [marginRate, setMarginRate] = useState<string>(''); // 마진율
    const [isPurchasedProduct, setIsPurchasedProduct] = useState<number>(PurchaseProductType.사입); // 공급 방식
    const [status, setStatus] = useState<number>(0); // 판매 상태
    const [visibilityTarget, setVisibilityTarget] = useState<string[]>([]);
    const [viewProductTarget, setViewProductTarget] = useState(ProductsCreateOptionList.ViewProductTarget);
    const [optionList, setOptionList] = useState<ITOptionList[]>([]);
    const [mainCat, setMainCat] = useState<string>('');
    const [subCat, setSubCat] = useState<string>('');
    const [mtsCat1, setMtsCat1] = useState<string>('');
    const [mtsCat2, setMtsCat2] = useState<string>('');
    const [mtsCat3, setMtsCat3] = useState<string>('');
    // const [departments, setDepartments] = useState<string[]>(['']); // 병원 진료과

    const [isRepurchaseAlimtalk, setIsRepurchaseAlimtalk] = useState<number>(0);
    const [dayToSend, setDayToSend] = useState<string | undefined>();

    const [inputEdit, setInputEdit] = useState<boolean>(true);
    const [onProductTypeDropdown, setOnProductTypeDropdown] = useState<boolean>(false);
    const [onPurchasedDropdown, setOnPurchasedDropdown] = useState<boolean>(false);
    const [onStatusDropdown, setOnStatusDropdown] = useState<boolean>(false);
    const [onRepurchaseAlimtalkDropdown, setOnRepurchaseAlimtalkDropdown] = useState<boolean>(false);

    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

    const [isDeleteProductModal, setIsDeleteProductModal] = useToggle();

    useEffect(() => {
        if (state && state.id) {
            setInputEdit(false);
            clientListSearchApi();
        } else {
            setInputEdit(true);
        }
    }, [state]);

    useEffect(() => {
        setInitFunc();
    }, [initData]);

    // 특정 병원 정보 가져오기
    const clientListSearchApi = async () => {
        setLoading();
        try {
            const response = await DoctorOnAPI.shared.smsHospital.productFindById(state.id);

            if (isRequestSucceed(response)) {
                response.data?.visibilityTarget.map((target) => {
                    setViewProductTarget((prev) => {
                        return prev.map((prevItem) => {
                            if (prevItem.target === target) {
                                return { ...prevItem, selected: true };
                            }
                            return prevItem;
                        });
                    });
                });
                setInitData(response.data);
                setStopLoading();
            }
            return response;
        } catch (e: any) {
            setStopLoading();
            console.log(e);
        }
    };

    // product delete api
    const deleteProductApi = async () => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.deleteProduct(state.id);

            if (isRequestSucceed(response)) {
                setIsDeleteProductModal();
                toast.success('제품이 삭제되었습니다.');
                navigate('/productList', { replace: true });
            }
        } catch (e: any) {
            console.log(e, 'error');
            throw new Error(e);
        }
    };

    // 제품 등록 api
    const productCreateApi = async () => {
        setLoading();
        try {
            const response = await DoctorOnAPI.shared.smsHospital.productCreate({
                orderProductCode: orderProductCode,
                orderProductName: orderProductName,
                productName: productName, // required
                productType: productType, // required
                providerName: providerName, // required
                externalCode: externalCode, // required
                deliveryPrice: parseFloat((deliveryPrice || '').replace(/,/g, '')) || 0,
                retailPrice: parseFloat((retailPrice || '').replace(/,/g, '')) || 0, // required
                supplyPrice: parseFloat((supplyPrice || '').replace(/,/g, '')) || 0, // required
                settleRate: parseFloat((settleRate || '').replace(/,/g, '')) || 0, // required
                marginRate: parseFloat((marginRate || '').replace(/,/g, '')) || 0,
                isPurchasedProduct: isPurchasedProduct === 1 ? true : false,
                isRepurchaseAlimtalk: isRepurchaseAlimtalk === 0 ? true : false,
                dayToSend: isRepurchaseAlimtalk === 0 && dayToSend ? parseFloat(dayToSend.replace(/,/g, '')) : 0,
                status: status - 1,
                visibilityTarget: visibilityTarget,
                optionList: optionList,
                mainCat: mainCat,
                subCat: subCat,
                mtsCat1: mtsCat1,
                mtsCat2: mtsCat2,
                mtsCat3: mtsCat3,
                // departments: departments,
            });

            if (isRequestSucceed(response)) {
                toast.success('제품이 등록되었습니다.');
                navigate('/productList', { replace: true });
                setStopLoading();
            } else {
                toast.error('제품 등록에 실패하였습니다.');
                setStopLoading();
            }
        } catch (e: any) {
            console.log(e, 'error');
            setStopLoading();
            throw new Error(e);
        }
    };

    //  product update api
    const productUpdateApi = async () => {
        setLoading();

        try {
            const response = await DoctorOnAPI.shared.smsHospital.productUpdate(state.id, {
                orderProductCode: orderProductCode,
                orderProductName: orderProductName,
                productName: productName, // required
                productType: productType, // required
                providerName: providerName, // required
                externalCode: externalCode, // required
                deliveryPrice: parseFloat((deliveryPrice || '').replace(/,/g, '')) || 0,
                retailPrice: parseFloat((retailPrice || '').replace(/,/g, '')) || 0, // required
                supplyPrice: parseFloat((supplyPrice || '').replace(/,/g, '')) || 0, // required
                settleRate: parseFloat((settleRate || '').replace(/,/g, '')) || 0, // required
                marginRate: parseFloat((marginRate || '').replace(/,/g, '')) || 0,
                isPurchasedProduct: isPurchasedProduct === 1,
                isRepurchaseAlimtalk: isRepurchaseAlimtalk === 0,
                dayToSend: isRepurchaseAlimtalk === 0 && dayToSend ? parseFloat((dayToSend || '').replace(/,/g, '')) : 0,
                status: status - 1,
                visibilityTarget: visibilityTarget,
                optionList: optionList,
                mainCat: mainCat,
                subCat: subCat,
                mtsCat1: mtsCat1,
                mtsCat2: mtsCat2,
                mtsCat3: mtsCat3,
                // departments: departments,
            });

            if (isRequestSucceed(response)) {
                toast.success('제품이 수정되었습니다.');
                navigate('/productList', { replace: true });
            } else {
                toast.error('제품 수정에 실패하였습니다.');
            }
            setStopLoading();
        } catch (e: any) {
            console.log(e, 'error');
            setStopLoading();
            throw new Error(e);
        }
    };

    // input change func
    const onHospitalInfoChange = (e: string, type: string, receiverIndex?: number) => {
        if (inputEdit) {
            switch (type) {
                case 'providerName':
                    setProviderName(e);
                    break;
                case 'orderProductCode':
                    setOrderProductCode(e);
                    break;
                case 'orderProductName':
                    setOrderProductName(e);
                    break;
                case 'productName':
                    setProductName(e);
                    break;
                case 'externalCode':
                    setExternalCode(e);
                    break;
                case 'deliveryPrice':
                    if (/^[0-9]{0,}$/g.test(e)) setDeliveryPrice(e);
                    break;
                case 'retailPrice':
                    if (/^[0-9]{0,}$/g.test(e)) setRetailPrice(e);
                    break;
                case 'supplyPrice':
                    if (/^[0-9]{0,}$/g.test(e)) setSupplyPrice(e);
                    break;
                case 'settleRate':
                    if (/^[0-9]*\.?[0-9]*$/g.test(e)) {
                        const numericValue = parseFloat(e);
                        if (e === '' || (!isNaN(numericValue) && numericValue <= 100)) {
                            setSettleRate(e);
                        }
                    }
                    break;
                case 'dayToSend':
                    if (/^[0-9]{0,}$/g.test(e)) setDayToSend(e);
                    break;
                case 'marginRate':
                    if (/^[0-9]*\.?[0-9]*$/g.test(e)) {
                        const numericValue = parseFloat(e);
                        if (e === '' || (!isNaN(numericValue) && numericValue <= 100)) {
                            setMarginRate(e);
                        }
                    }
                    break;
                case 'mainCat':
                    setMainCat(e);
                    break;
                case 'subCat':
                    setSubCat(e);
                    break;
                case 'mtsCat1':
                    setMtsCat1(e);
                    break;
                case 'mtsCat2':
                    setMtsCat2(e);
                    break;
                case 'mtsCat3':
                    setMtsCat3(e);
                    break;
                // case 'departments':
                //     const newValues = [...departments];
                //     newValues[receiverIndex!] = e;
                //     setDepartments(newValues);
                //     break;
                default:
                    break;
            }
        }
    };

    // input에 초기값 설정
    const setInitFunc = () => {
        if (initData && initData !== null) {
            const updatedOptions = initData.optionList?.map(({ _id, ...rest }) => rest);

            setOrderProductCode(initData.orderProductCode);
            setOrderProductName(initData.orderProductName);
            setProductName(initData.productName);
            setProductType(initData.productType);
            setProviderName(initData.providerName);
            setExternalCode(initData.externalCode);
            setDeliveryPrice(initData.deliveryPrice?.toString());
            setRetailPrice(initData.retailPrice?.toString());
            setSupplyPrice(initData.supplyPrice?.toString());
            setSettleRate(initData.settleRate?.toString());
            setMarginRate(initData.marginRate?.toString());
            setIsPurchasedProduct(initData.isPurchasedProduct ? 1 : 0);
            setStatus(initData.status + 1);
            setIsRepurchaseAlimtalk(initData.isRepurchaseAlimtalk ? 0 : 1);
            setDayToSend(initData.dayToSend?.toString());
            setMainCat(initData.mainCat || '');
            setSubCat(initData.subCat || '');
            setMtsCat1(initData.mtsCat1 || '');
            setMtsCat2(initData.mtsCat2 || '');
            setMtsCat3(initData.mtsCat3 || '');
            setVisibilityTarget(initData.visibilityTarget);
            updatedOptions && setOptionList(updatedOptions);
            // setDepartments(initData?.departments?.length ? initData.departments : ['']);

            initData.visibilityTarget.map((target) => {
                setViewProductTarget((prev) => {
                    return prev.map((prevItem) => {
                        if (prevItem.target === target) {
                            return { ...prevItem, selected: true };
                        }
                        return prevItem;
                    });
                });
            });
        }
    };

    const handleDropdown = (idx: number, type?: string) => {
        switch (type) {
            case 'productType':
                setProductType(idx);
                break;
            case 'purchasedProduct':
                setIsPurchasedProduct(idx);
                break;
            case 'status':
                setStatus(idx);
                break;
            case 'isRepurchaseAlimtalk':
                setIsRepurchaseAlimtalk(idx);
                break;
            default:
                break;
        }
    };

    // 취소하기 버튼
    const clickCancelButton = () => {
        navigate('/productList', { replace: true });
    };

    const clickEditButton = (type: string) => {
        if (type === 'delete') {
            setIsDeleteProductModal();
        } else if (type === 'cancel') {
            setInitFunc();
            setInputEdit(false);
        } else {
            productUpdateApi();
        }
    };

    // optionlist 변경
    const handleChangeOption = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value, type } = e.target;
        const initialOption = {
            optionCode: '',
            optionName: '',
            optionOrderCode: '',
            optionOrderName: '',
            optionPrice: 0,
            optionSupplyPrice: 0,
            optionMarginRate: 0,
            optionPeriod: 0,
            optionProductCount: 0,
            optionDayToSend: 0,
        };
        setOptionList((prev) => {
            const updatedOptions = prev ? [...prev] : [initialOption];
            if (type === 'number') {
                if (/^[0-9]*\.?[0-9]*$/g.test(value))
                    updatedOptions[index] = {
                        ...updatedOptions[index],
                        [name]: Number(value),
                    };
            } else {
                updatedOptions[index] = {
                    ...updatedOptions[index],
                    [name]: value,
                };
            }
            return updatedOptions;
        });
    };

    // option add func
    const handleAddOption = () => {
        const initialOption = {
            optionCode: '',
            optionName: '',
            optionOrderCode: '',
            optionOrderName: '',
            optionPrice: 0,
            optionSupplyPrice: 0,
            optionMarginRate: 0,
            optionPeriod: 0,
            optionProductCount: 0,
            optionDayToSend: 0,
        };

        setOptionList((prev) =>
            prev
                ? [
                      ...prev,
                      {
                          optionCode: '',
                          optionName: '',
                          optionOrderCode: '',
                          optionOrderName: '',
                          optionPrice: 0,
                          optionSupplyPrice: 0,
                          optionMarginRate: 0,
                          optionPeriod: 0,
                          optionProductCount: 0,
                          optionDayToSend: 0,
                      },
                  ]
                : [initialOption]
        );
    };

    // option delete func
    const handleDeleteOption = (index: number) => {
        if (optionList) {
            setOptionList(optionList.filter((_, idx) => idx !== index));
        }
    };

    // 제품타겟 선택
    const handleProductTarget = (label: string, target: string) => {
        const visibility = [...visibilityTarget];

        setViewProductTarget((prev) => prev.map((item) => (item.label === label ? { ...item, selected: !item.selected } : item)));

        if (visibility.includes(target)) {
            setVisibilityTarget(visibility.filter((item) => item !== target));
        } else {
            setVisibilityTarget([...visibility, target]);
        }
    };

    return (
        <Layout>
            {isDeleteProductModal && (
                <ConfirmModal
                    title='제품을 삭제하시겠습니까?'
                    content='해당 제품이 완전히 삭제됩니다.'
                    firstButton='취소하기'
                    secondButton='삭제하기'
                    setIsLeaveModal={setIsDeleteProductModal}
                    clickConfirmButton={deleteProductApi}
                />
            )}
            <Container>
                <Title>제품 기본 정보</Title>
                {(selectedUser?.user.id === 'Ww39YIj5JN6zgKvwwYQW' || selectedUser?.user.id === 'ZjIQqcyDr2DRT1nWL5hC') && (
                    <DeleteButtonWrapper $position='absolute' $width='auto'>
                        <BasicButton $type='warning' $width='100px' $height='30px' onClick={() => clickEditButton('delete')}>
                            삭제하기
                        </BasicButton>
                    </DeleteButtonWrapper>
                )}
                <RowWrapper>
                    <ColumnDivider>
                        <DropdownWithLabel
                            label='카테고리'
                            type='productType'
                            selected={productType}
                            handleDropdown={handleDropdown}
                            list={ProductsCreateOptionList.productTypeList}
                            onDropdown={onProductTypeDropdown}
                            setOnDropdown={setOnProductTypeDropdown}
                            buttonEdit={inputEdit}
                            $dropdownHeight='168px'
                            $info={true}
                        />

                        <ColumnInputWithLabel
                            label='업체명'
                            placeholder='업체명을 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={providerName}
                            type='providerName'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                        <ColumnInputWithLabel
                            label='제품코드'
                            placeholder='제품코드를 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={externalCode}
                            type='externalCode'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                        <ColumnInputWithLabel
                            label='제품명'
                            placeholder='제품명을 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={productName}
                            type='productName'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                    </ColumnDivider>
                    <ColumnDivider>
                        <DropdownWithLabel
                            label='공급방식'
                            type='purchasedProduct'
                            selected={isPurchasedProduct}
                            handleDropdown={handleDropdown}
                            list={ProductsCreateOptionList.purchasedProductList}
                            onDropdown={onPurchasedDropdown}
                            setOnDropdown={setOnPurchasedDropdown}
                            buttonEdit={inputEdit}
                            $dropdownHeight='88px'
                            $info={true}
                        />
                        <ColumnInputWithLabel
                            label='배송비'
                            placeholder='배송비를 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={deliveryPrice}
                            type='deliveryPrice'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                        <ColumnInputWithLabel
                            label='발주서 코드'
                            placeholder='발주서 코드를 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={orderProductCode}
                            type='orderProductCode'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                        <ColumnInputWithLabel
                            label='발주서 제품명'
                            placeholder='발주서 제품명을 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={orderProductName}
                            type='orderProductName'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                    </ColumnDivider>
                </RowWrapper>
                <ColumnDivider style={{ borderBottom: `1px solid ${BorderColor.color_border_neutral}`, paddingBottom: '20px' }}>
                    <RowWrapper>
                        {ProductsCreateOptionList.divideCategoryList.map((title, idx) => {
                            return <OptionWrapper>{title}</OptionWrapper>;
                        })}
                    </RowWrapper>
                    <ColumnDivider style={{ gap: '3px' }}>
                        <RowWrapper>
                            <Input
                                placeholder='중분류'
                                value={mainCat}
                                name='mainCat'
                                onChange={(e) => onHospitalInfoChange(e.target.value, 'mainCat')}
                                $state={inputEdit}
                                readOnly={!inputEdit && true}
                                $width='15%'
                                $height='35px'
                            />
                            <Input
                                placeholder='소분류'
                                value={subCat}
                                name='subCat'
                                onChange={(e) => onHospitalInfoChange(e.target.value, 'subCat')}
                                $state={inputEdit}
                                readOnly={!inputEdit && true}
                                $width='15%'
                                $height='35px'
                            />
                            <Input
                                placeholder='옵션 1'
                                value={mtsCat1}
                                name='mtsCat1'
                                onChange={(e) => onHospitalInfoChange(e.target.value, 'mtsCat1')}
                                $state={inputEdit}
                                readOnly={!inputEdit && true}
                                $width='15%'
                                $height='35px'
                            />
                            <Input
                                placeholder='옵션 2'
                                value={mtsCat2}
                                name='mtsCat2'
                                onChange={(e) => onHospitalInfoChange(e.target.value, 'mtsCat2')}
                                $state={inputEdit}
                                readOnly={!inputEdit && true}
                                $width='15%'
                                $height='35px'
                            />
                            <Input
                                placeholder='옵션 3'
                                value={mtsCat3}
                                name='mtsCat3'
                                onChange={(e) => onHospitalInfoChange(e.target.value, 'mtsCat3')}
                                $state={inputEdit}
                                readOnly={!inputEdit && true}
                                $width='15%'
                                $height='35px'
                            />
                        </RowWrapper>
                    </ColumnDivider>
                </ColumnDivider>
                <RowWrapper>
                    <ColumnInputWithLabel
                        label='소비자가'
                        placeholder='소비자가를 입력해주세요.'
                        onChange={onHospitalInfoChange}
                        value={retailPrice}
                        type='retailPrice'
                        inputEdit={inputEdit}
                        $labelWidth='70px'
                        $width='150px'
                        $info={true}
                    />
                    <ColumnInputWithLabel
                        label='공급가'
                        placeholder='공급가를 입력해주세요.'
                        onChange={onHospitalInfoChange}
                        value={supplyPrice}
                        type='supplyPrice'
                        inputEdit={inputEdit}
                        $labelWidth='70px'
                        $width='150px'
                        $info={true}
                    />
                    <ColumnInputWithLabel
                        label='요율'
                        placeholder='요율을 입력해주세요.'
                        onChange={onHospitalInfoChange}
                        value={settleRate}
                        type='settleRate'
                        inputEdit={inputEdit}
                        $labelWidth='70px'
                        $width='150px'
                        $info={true}
                    />
                    <ColumnInputWithLabel
                        label='마진율'
                        placeholder='마진율을 입력해주세요.'
                        onChange={onHospitalInfoChange}
                        value={marginRate}
                        type='marginRate'
                        inputEdit={inputEdit}
                        $labelWidth='70px'
                        $width='150px'
                        $info={true}
                    />
                </RowWrapper>
                <ColumnDivider style={{ borderBottom: `1px solid ${BorderColor.color_border_neutral}`, paddingBottom: '20px', gap: '10px' }}>
                    <div>
                        <RowWrapper>
                            {ProductsCreateOptionList.optionTitle.map((title, idx) => {
                                if (idx <= 5) return <OptionWrapper>{title}</OptionWrapper>;
                            })}
                            {inputEdit && (
                                <OptionButtonWrapper onClick={handleAddOption}>
                                    <MdLibraryAdd />
                                </OptionButtonWrapper>
                            )}
                        </RowWrapper>
                        <ColumnDivider style={{ gap: '3px' }}>
                            {optionList?.map((item, idx) => {
                                return (
                                    <RowWrapper>
                                        <Input
                                            placeholder='옵션코드'
                                            value={item.optionCode}
                                            type='string'
                                            name='optionCode'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='옵션명'
                                            value={item.optionName}
                                            type='string'
                                            name='optionName'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='발주서 옵션코드'
                                            value={item.optionOrderCode}
                                            type='string'
                                            name='optionOrderCode'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='발주서 옵션명'
                                            value={item.optionOrderName}
                                            type='string'
                                            name='optionOrderName'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='옵션금액'
                                            value={item.optionPrice}
                                            type='number'
                                            name='optionPrice'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='옵션공급가'
                                            value={item.optionSupplyPrice}
                                            type='number'
                                            name='optionSupplyPrice'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        {inputEdit && (
                                            <OptionButtonWrapper onClick={() => handleDeleteOption(idx)}>
                                                <MdDelete />
                                            </OptionButtonWrapper>
                                        )}
                                    </RowWrapper>
                                );
                            })}
                        </ColumnDivider>
                    </div>
                    <div>
                        <RowWrapper>
                            <OptionWrapper>{ProductsCreateOptionList.optionTitle[0]}</OptionWrapper>
                            <OptionWrapper>{ProductsCreateOptionList.optionTitle[1]}</OptionWrapper>
                            {ProductsCreateOptionList.optionTitle.map((title, idx) => {
                                if (idx > 5) return <OptionWrapper>{title}</OptionWrapper>;
                            })}
                            {inputEdit && (
                                <OptionButtonWrapper onClick={handleAddOption}>
                                    <MdLibraryAdd />
                                </OptionButtonWrapper>
                            )}
                        </RowWrapper>
                        <ColumnDivider style={{ gap: '3px' }}>
                            {optionList?.map((item, idx) => {
                                return (
                                    <RowWrapper>
                                        <Input
                                            placeholder='옵션코드'
                                            value={item.optionCode}
                                            type='string'
                                            name='optionCode'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='옵션명'
                                            value={item.optionName}
                                            type='string'
                                            name='optionName'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='마진율'
                                            value={item.optionMarginRate}
                                            type='number'
                                            name='optionMarginRate'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='기간'
                                            value={item.optionPeriod}
                                            type='number'
                                            name='optionPeriod'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='개수'
                                            value={item.optionProductCount}
                                            type='number'
                                            name='optionProductCount'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        <Input
                                            placeholder='알림톡'
                                            value={item.optionDayToSend}
                                            type='number'
                                            name='optionDayToSend'
                                            onChange={(e) => handleChangeOption(e, idx)}
                                            $state={inputEdit}
                                            readOnly={!inputEdit && true}
                                            $width='15%'
                                            $height='35px'
                                        />
                                        {inputEdit && (
                                            <OptionButtonWrapper onClick={() => handleDeleteOption(idx)}>
                                                <MdDelete />
                                            </OptionButtonWrapper>
                                        )}
                                    </RowWrapper>
                                );
                            })}
                        </ColumnDivider>
                    </div>
                </ColumnDivider>
                <RowWrapper style={{ borderBottom: `1px solid ${BorderColor.color_border_neutral}` }}>
                    <Label>제품타겟</Label>
                    <FlexWrapper>
                        {viewProductTarget.map((item, idx) => {
                            const type = item.selected ? 'fill' : 'outLine';
                            if (!inputEdit) {
                                if (item.selected) {
                                    return <SelectedTarget>{item.label}</SelectedTarget>;
                                }
                            } else {
                                return (
                                    <BasicButton $type={type} $height='30px' $width='90px' onClick={() => handleProductTarget(item.label, item.target)} disabled={!inputEdit}>
                                        {item.label}
                                    </BasicButton>
                                );
                            }
                        })}
                    </FlexWrapper>
                    <div>
                        <DropdownWithLabel
                            label='상태'
                            type='status'
                            selected={status}
                            handleDropdown={handleDropdown}
                            list={ProductsCreateOptionList.ProductStatusList}
                            onDropdown={onStatusDropdown}
                            setOnDropdown={setOnStatusDropdown}
                            buttonEdit={inputEdit}
                            $dropdownHeight='128px'
                            $info={true}
                        />
                    </div>
                </RowWrapper>
                <RowWrapper>
                    <ColumnDivider>
                        <DropdownWithLabel
                            label='재구매 알림톡'
                            type='isRepurchaseAlimtalk'
                            selected={isRepurchaseAlimtalk}
                            handleDropdown={handleDropdown}
                            list={ProductsCreateOptionList.YesOrNo}
                            onDropdown={onRepurchaseAlimtalkDropdown}
                            setOnDropdown={setOnRepurchaseAlimtalkDropdown}
                            buttonEdit={inputEdit}
                            $dropdownHeight='88px'
                            $info={true}
                        />
                    </ColumnDivider>
                    <ColumnDivider>
                        <ColumnInputWithLabel
                            label='알림톡 일자'
                            placeholder='알림톡 발송 일자를 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={dayToSend || ''}
                            type='dayToSend'
                            inputEdit={inputEdit}
                            $info={true}
                            disabled={isRepurchaseAlimtalk === 1}
                        />
                    </ColumnDivider>
                </RowWrapper>
                {/* <AddAndDeleteColumnInputWithLabel
                    label='병원 진료과'
                    placeholder='병원 진료과를 입력해주세요.'
                    onChange={onHospitalInfoChange}
                    value={departments}
                    setValue={setDepartments}
                    type='departments'
                    inputEdit={inputEdit}
                /> */}
            </Container>
            {!state && (
                <ButtonWrapper>
                    <BasicButton $type='warning' $height='50px' onClick={clickCancelButton}>
                        취소하기
                    </BasicButton>
                    <BasicButton $type='fill' $height='50px' onClick={productCreateApi} disabled={!productName || !providerName || !externalCode || !retailPrice || !supplyPrice || !settleRate}>
                        등록하기
                    </BasicButton>
                </ButtonWrapper>
            )}
            {state && (
                <ButtonWrapper>
                    {!inputEdit ? (
                        <BasicButton $type='outLine' $height='50px' onClick={() => setInputEdit(true)}>
                            수정하기
                        </BasicButton>
                    ) : (
                        <>
                            <BasicButton $type='warning' $height='50px' onClick={() => clickEditButton('cancel')}>
                                취소하기
                            </BasicButton>
                            <BasicButton
                                $type='fill'
                                $height='50px'
                                disabled={!productName || !providerName || !externalCode || !retailPrice || !supplyPrice || !settleRate}
                                onClick={() => clickEditButton('edit')}
                            >
                                수정하기
                            </BasicButton>
                        </>
                    )}
                </ButtonWrapper>
            )}
        </Layout>
    );
};

export default AddProductPage;

const FlexWrapper = styled.div`
    display: flex;
    width: 400px;
    gap: 10px;
    // align-items: center;
`;

const Label = styled(Body4_Medium)`
    width: 100px;
    display: flex;
    align-items: center;
    background-color: ${BackgroundColor.color_background_neutral};
    height: 35px;
    padding-left: 5px;
    border-radius: 4px;
`;

const OptionWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 8px 0 12px;
    // justify-content: center;
    width: 15%;
    height: 35px;
    background-color: ${BackgroundColor.color_background_neutral};
    border-radius: 4px;
    margin-bottom: 5px;
`;

const OptionButtonWrapper = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    // height: 50px;
`;

const RowWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    // gap: 40px;
`;

const ColumnDivider = styled.div`
    display: flex;
    flex-direction: column;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 1024px;
    height: auto;
    background: #ffffff;
    border: 1px solid #c4c4c4;
    padding: 40px;
    gap: 20px;
`;

const Layout = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1024px;
    height: auto;
    gap: 25px;
`;

const Title = styled(Body3_SemiBold)`
    line-height: none;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    gap: 20px;
`;

const SelectedTarget = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 30px;
    border-radius: 4px;
    font-size: ${font.size[14]};
    font-weight: ${font.weight.medium};
    background-color: ${PrimaryColor.color_primary_normal};
    color: ${InverseColor.color_inverse_white};
`;

const DeleteButtonWrapper = styled.div<{ $position?: string; $width?: string }>`
    position: ${(props) => props.$position};
    right: 40px;
    top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.$width || '400px'};
    gap: 20px;
`;
