import { useQuery } from 'react-query';
import { useSuspenseHook } from '@hooks/suspense';
import { DoctorOnAPI } from '@request/doctorOnAPI';
import { OoParamId } from '@params/common';
import { isRequestSucceed } from '../../api';
import { useRecoilState } from 'recoil';
import { doctorFindInfoState } from '@recoils/doctor';
import _ from 'lodash';

export const useDoctorFindQuery = (id: string, trigger?: boolean) => {
    const { setErrorStatus } = useSuspenseHook();
    const [queryTrigger, setQueryTrigger] = useRecoilState(doctorFindInfoState);
    return useQuery(
        'doctor/findDoctor',
        async () => {
            try {
                const response = await DoctorOnAPI.shared.doctor.readByUserId(new OoParamId(id));
                if (isRequestSucceed(response)) {
                    setQueryTrigger(false);
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            enabled: _.isUndefined(trigger) ? queryTrigger : trigger,
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: 'always', // always로 설정하면 마운트 시 마다 매번 refetch 실행,
        }
    );
};
