import { ColumnDivider } from '@styles/common/commonStyles';
import { font } from '@styles/globalStyles';
import { Body4_Regular, Title1_Bold_Input } from '@styles/typography';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import dompurify from 'dompurify';
import { useSuspenseHook } from '@hooks/suspense';
import { isRequestSucceed } from '@api/reponses';
import { DoctorOnAPI } from '@api/request';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { ITClientNotice } from '@api/models/notice/clientNotice/notice';
import _ from 'lodash';
import { MdDownload } from 'react-icons/md';
import { BackgroundColor } from '@styles/colorSemantic';
import { ProductsCreateOptionList } from '@api/models/product/product';

const sanitizer = dompurify.sanitize;
const DetailNoticePage = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    console.log(state, 'state');
    const [initData, setInitData] = useState<ITClientNotice>();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState<string>('');
    const [visibilityTarget, setVisibilityTarget] = useState(ProductsCreateOptionList.ViewProductTarget);
    const [file, setFile] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();

    useEffect(() => {
        if (state && state.id) {
            getDetailNoticeApi();
        }
    }, []);

    useEffect(() => {
        setInitFunc();
    }, [initData]);

    const onNoticeChange = (e: ChangeEvent<HTMLInputElement>, type: string) => {
        if (type === 'title') setTitle(e.target.value);
    };

    // 공지사항 상세 api
    const getDetailNoticeApi = async () => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.clientNoticeDetail(state.id);

            if (isRequestSucceed(response)) {
                setInitData(response.data);
            }
        } catch (e: any) {
            console.log(e, 'error');
            throw new Error(e);
        }
    };

    const downloadFileApi = async (fileId: string, fileName: string) => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.clientNoticeDownloadFile(state.id, fileId);
            console.log(response, 'response');

            const aElement = document.createElement('a');
            // 위에서 생성한 aElement변수에 href속성에 넣을 데이터를 설정해준다.
            const blobFile = window.URL.createObjectURL(new Blob([response]));
            aElement.href = blobFile;

            aElement.download = fileName;

            aElement.click();

            setTimeout(() => {
                // 이제 더이상 필요 없으니 생성한 a태그를 1초후 삭제 시켜준다.
                aElement.remove();
            }, 1000);
        } catch (e: any) {
            console.log(e, 'error');
            throw new Error(e);
        }
    };
    const setInitFunc = () => {
        if (initData && initData !== null) {
            let copied = _.cloneDeep(visibilityTarget);

            initData.visibilityTarget.forEach((target) => {
                copied.forEach((item) => target === item.target && (item.selected = true));
            });

            setVisibilityTarget(copied);
            setTitle(initData.title);
            setContent(initData.content);
            setStartDate(initData.startDate ? moment(initData.startDate).toDate() : null);
            setEndDate(initData.endDate ? moment(initData.endDate).toDate() : null);
            setFile(initData.files ?? []);
        }
    };

    return (
        <Layout>
            <InnerLayout>
                {content && (
                    <Container>
                        {/* <FlexContainer>
                                <FlexWrapper>
                                    <Body4_Regular>시작일</Body4_Regular>
                                    <DatePickerWrapper>
                                        <ReactDatePicker selected={startDate} onChange={setStartDate} dateFormat='yyyy/MM/dd' minDate={new Date()} placeholderText='시작 날짜' disabled={true} />
                                    </DatePickerWrapper>
                                </FlexWrapper>
                                <FlexWrapper>
                                    <Body4_Regular>종료일</Body4_Regular>
                                    <DatePickerWrapper>
                                        <ReactDatePicker
                                            selected={endDate}
                                            onChange={setEndDate}
                                            dateFormat='yyyy/MM/dd'
                                            minDate={startDate || new Date()}
                                            placeholderText='종료 날짜'
                                            disabled={true}
                                        />
                                    </DatePickerWrapper>
                                </FlexWrapper>
                            </FlexContainer> */}
                        <Title placeholder='제목' onChange={(e) => onNoticeChange(e, 'title')} value={title} disabled={true} />
                        <Divider />
                        <BodyContainer>
                            <SanitizedContent dangerouslySetInnerHTML={{ __html: sanitizer(`${content}`) }} />
                        </BodyContainer>
                        <FlexWrapper>
                            {file.length > 0 && (
                                <FileList>
                                    {file.map((file, index) => (
                                        <FileName key={index}>
                                            <FileNameText>{file.name}</FileNameText>
                                            <FileButton value={file.name} onClick={() => downloadFileApi(file._id, file.name)} $top='2px' $right='-30px'>
                                                <MdDownload />
                                            </FileButton>
                                        </FileName>
                                    ))}
                                </FileList>
                            )}
                        </FlexWrapper>
                    </Container>
                )}
            </InnerLayout>
        </Layout>
    );
};

export default DetailNoticePage;

const FlexWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

const Layout = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    padding: 40px;
    flex-direction: column;
    overflow: auto;
`;

const InnerLayout = styled.div`
    display: flex;
    width: 1024px;
    max-width: 1024px;
    height: 100%;
    flex-direction: column;
    row-gap: 15px;
    border: 1px solid rgba(196, 196, 196, 0.5);
    border-radius: 10px;
    overflow: auto;

    scrollbar-width: thin; /* Firefox에서 스크롤바 너비 설정 */
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1); /* Firefox에서 스크롤바 색상 설정 */

    /* 스크롤바 스타일 */
    &::-webkit-scrollbar {
        width: 12px; /* 스크롤바 너비 */
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2); /* 스크롤바 색상 */
        border-radius: 10px; /* 스크롤바 모서리 둥글게 */
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1); /* 스크롤바 트랙 색상 */
    }
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #ffffff;
    padding: 40px;
    gap: 40px;
`;

const Title = styled(Title1_Bold_Input)`
    border: none;
    outline: none;
    background: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::placeholder {
        font-weight: ${font.weight.medium};
        color: #949390;
    }
`;

const Divider = styled.div`
    width: 100%;
    min-height: 1px;
    background-color: #c4c4c4;
`;

const BodyContainer = styled.div`
    width: 100%;
    height: 100%;
    #toolBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        width: 100%;
        border: none;
        color: black;
        font-size: 32px;

        #content {
            width: 100%;
        }
        .ql-formats {
            .image-btn {
                font-size: 18px;
                cursor: pointer;

                .icon-custom {
                    margin-right: 5px;
                    font-size: 24px;
                }
            }
        }
    }

    #quillContent {
        border: 1px solid #c4c4c4;

        // text container
        .ql-container {
            width: 100%;
            height: 700px;
            padding: 5px 10px;
            border: none;
            overflow: hidden;

            .ql-editor {
                overflow: auto; /* 스크롤바가 필요할 때만 보이도록 설정 */
                scrollbar-width: thin; /* Firefox에서 스크롤바 너비 설정 */
                scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1); /* Firefox에서 스크롤바 색상 설정 */

                /* 스크롤바 스타일 */
                &::-webkit-scrollbar {
                    width: 12px; /* 스크롤바 너비 */
                }

                &::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.2); /* 스크롤바 색상 */
                    border-radius: 10px; /* 스크롤바 모서리 둥글게 */
                }

                &::-webkit-scrollbar-track {
                    background-color: rgba(0, 0, 0, 0.1); /* 스크롤바 트랙 색상 */
                }
            }
        }
    }
`;

const SanitizedContent = styled.div`
    width: 100%;
    height: 700px;
    overflow: auto; /* 스크롤바가 필요할 때만 보이도록 설정 */
    scrollbar-width: thin; /* Firefox에서 스크롤바 너비 설정 */
    scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1); /* Firefox에서 스크롤바 색상 설정 */

    /* 스크롤바 스타일 */
    &::-webkit-scrollbar {
        width: 12px; /* 스크롤바 너비 */
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2); /* 스크롤바 색상 */
        border-radius: 10px; /* 스크롤바 모서리 둥글게 */
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1); /* 스크롤바 트랙 색상 */
    }

    img {
        max-width: 100%;
        // height: auto;
    }
`;

const FileList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

const FileName = styled.li`
    position: relative;
    display: flex;
    width: 300px;
    height: 30px;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #c4c4c4;
    margin: 5px 0;
    padding-left: 10px;
`;
const FileNameText = styled.span`
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const FileButton = styled.button<{ $top?: string; $right?: string }>`
    position: absolute;
    width: 25px;
    height: 25px;
    top: ${(props) => props.$top};
    right: ${(props) => props.$right};
    :hover {
        background-color: ${BackgroundColor.color_background_primary_normal};
        border-radius: 99px;
    }
`;
