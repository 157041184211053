import { useSuspenseHook } from "@hooks/suspense";
import { useRecoilState } from "recoil";
import _ from "lodash";
import { DoctorOnAPI } from "@api/request";
import { isRequestSucceed } from "@api/reponses";
import { useMutation, useQuery } from "react-query";
import { calculateListState } from "@recoils/calculate/calculateListState";
import { OoCalculateMonth } from "@api/params/calculate/calculateMonthParam";
import { calculateRawListState } from "@recoils/calculate/calculateRawListState";

interface Props{
  month:string;
}

export const useCalculateListMutation = () => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
  const [list, setList] = useRecoilState(calculateListState);
  
  return useMutation(
    "calculate/list",
    async (param:string) => {
      try {
        setLoading();
        const response = await DoctorOnAPI.shared.calculate.getOrderStatisticsData(param);
        setStopLoading();
        if (isRequestSucceed(response)) {
          setList(response.data || []);
          return response.data;
        }
        return undefined;
      } catch (error: any) {
        setErrorStatus(error);
      }
    },
  );
};

export const useCalculateRawListMutation = () => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
  const [rowList, setRowList] = useRecoilState(calculateRawListState);
  
  return useMutation(
    "calculate/rowList",
    async (param:OoCalculateMonth) => {
      try {
        setLoading();
        const response = await DoctorOnAPI.shared.calculate.getUnospayPaymentData(param);
        console.log('useCalculateRawListMutation response', response);
        
        setStopLoading();
        if (isRequestSucceed(response)) {
          setRowList(response.data || []);
          return response.data;
        }
        return undefined;
      } catch (error: any) {
        setErrorStatus(error);
      }
    },
  );
};
