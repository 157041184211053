import { storage } from "../../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {v4 as uuidv4} from 'uuid';
import {filteredType} from "./storageUtils";
import {formatYyyyMMDHH} from "@hooks/format";

export class StorageUploader {
    static async upload(path: string, file: File) {
        try {
            const storageRef = ref(storage, path);
            const uploadResult = await uploadBytes(storageRef, file);
            return await getDownloadURL(uploadResult.ref);
        } catch (e: any) {
            console.error(e);
            return '';
        }
    }
}

export class DoctorProfileUploader {
    static async upload(hospitalId: string, file: File) {
        const fileType = filteredType(file.type);
        const uniqFilename = uuidv4().replaceAll('-', '');
        const path = `doctor/profile/${hospitalId}/${uniqFilename}.${fileType}`;
        return await StorageUploader.upload(path, file);

    }
}

export class HospitalLogoUploader {
    static async upload(file: File) {
        const fileType = filteredType(file.type);
        const uniqFilename = uuidv4().replaceAll('-', '');
        const path = `hospitalLogo/${uniqFilename}.${fileType}`;
        return await StorageUploader.upload(path, file);
    }
}

export class PharmacyProfileUploader {
    static async upload(file: File) {
        const fileType = filteredType(file.type);
        const uniqFilename = uuidv4().replaceAll('-', '');
        const path = `pharmacy/${uniqFilename}.${fileType}`;
        return await StorageUploader.upload(path, file);
    }
}

export class DoctorPrescriptionUploader {
    static async upload(file: File, userId: string) {
        const date = new Date();
        const fileType = filteredType(file.type);
        const uniqFilename = uuidv4().replaceAll('-', '');
        const path = `prescriptions/${userId}/${formatYyyyMMDHH(date)}/${uniqFilename}.${fileType}`;
        return await StorageUploader.upload(path, file);
    }
}