import _, { cloneDeep, findIndex, gte, isEqual } from 'lodash';
import { UseMutationResult } from 'react-query';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { cancelOrderModalVisibleState, resendKakaoModalVisibleState, transportModalVisibleState, unospayCalendarRangeListState, unospayListState } from '@recoils/unospay';
import { useSuspenseHook } from '@hooks/suspense';
import { IOoDataResponse, IOoResponse, IOoUnospayDelivery } from '../../api';
import { DeliverDetailInfo } from '../../pages/Operator/UnospayOrderDetailPage/components/DeliveryDetailInfo';
import { useCancelOrderUnospayQuery, useResendKakaoAlimTalkQuery, useUpdateTransportQuery, useUpdateAddressInfoQuery, useUpdateOrderCsInfoQuery, useUpdateHospitalInfoQuery } from '@queries/unospay';
import { ItemType } from '../../pages/Operator/UnospayOrderDetailPage/components/DeliveryInfo';
import { useUnospayDisplayHook } from '@hooks/unospay/useUnospayDisplayHook';
import { useChangeOrderPlatformQuery } from '@queries/unospay/changeOrderPlatformQuery';
import { ChangeHospitalInfo, ChangeOrderPlatform } from '../../pages/Operator/UnospayOrderDetailPage/components/RecipientInfo';
import { changeOrderPlatformVisibleState } from '@recoils/unospay/changeOrderPlatformVisible';
import { useNavigate } from 'react-router-dom';
import { doctorListState } from '@recoils/doctor';
import { CancelOrderComment, ChangeDeliveryStatus } from '../../pages';
import { OrderCsInfo } from 'pages/Operator/UnospayOrderDetailPage/components/OrderCsInfo';
import { useChangeDeliveryStatusQuery } from '@queries/unospay/changeDeliveryStatusQuery';
import OoUnospayHospitalInfoUpdate from '@api/params/unospay/updateHospitalInfo';
// SscWZ7tz4xijUgOeVwF2
export const useUnospayFunctionHook = (id: string) => {
    const { setErrorStatus } = useSuspenseHook();

    const resetCancelOrderModalVisible = useResetRecoilState(cancelOrderModalVisibleState);
    const resetKakaoResendModalVisible = useResetRecoilState(resendKakaoModalVisibleState);
    const resetModalVisible = useResetRecoilState(transportModalVisibleState);

    const resetOrderPlatformModalVisible = useResetRecoilState(changeOrderPlatformVisibleState);

    const { displayList, updateDisplay } = useUnospayDisplayHook(id);

    // const [deliveryList, setDeliveryList] = useRecoilState(unospayListState);
    const [calendarList, setCalendarList] = useRecoilState(unospayCalendarRangeListState);

    const updateAddressInfoQuery: UseMutationResult<IOoResponse | undefined, unknown, DeliverDetailInfo> = useUpdateAddressInfoQuery();
    const cancelOrderQuery: UseMutationResult<IOoUnospayDelivery[] | undefined, any, CancelOrderComment> = useCancelOrderUnospayQuery();
    const changeStatusQuery: UseMutationResult<any[] | undefined, any, ChangeDeliveryStatus> = useChangeDeliveryStatusQuery();
    const kakaoResendQuery: UseMutationResult<IOoResponse | undefined, any, string> = useResendKakaoAlimTalkQuery();
    const updateTransportInfoQuery: UseMutationResult<IOoUnospayDelivery[] | undefined, unknown, ItemType> = useUpdateTransportQuery();

    const orderPlatformQuery: UseMutationResult<IOoUnospayDelivery | undefined, any, ChangeOrderPlatform> = useChangeOrderPlatformQuery();

    const updateOrderCsInfoQuery: UseMutationResult<IOoResponse | undefined, unknown, OrderCsInfo> = useUpdateOrderCsInfoQuery();

    const updateHospitalInfoQuery: UseMutationResult<IOoDataResponse<IOoUnospayDelivery> | undefined, unknown, ChangeHospitalInfo> = useUpdateHospitalInfoQuery();

    // 배송지 정보 업데이트
    const updateDeliveryInfo = (param: DeliverDetailInfo) => {
        updateAddressInfoQuery.mutate(param, {
            onSuccess: (data: IOoResponse | undefined) => {
                if (_.isUndefined(data)) {
                    return data;
                }
            },
            onError: (e: any) => {
                setErrorStatus(e);
            },
        });
    };

    // 배송 정보 업데이트
    const updateTransportInfo = (param: ItemType) => {
        updateTransportInfoQuery.mutate(param, {
            onSuccess: async (data: IOoUnospayDelivery[] | undefined) => {
                if (_.isUndefined(data)) return;
                updateDisplay(_.concat(data));
            },
            onError: (e: any) => {
                setErrorStatus(e);
            },
        });
    };

    // 카카오 알림톡 재전송
    const resendKakaoAlimtalk = (id: string) => {
        kakaoResendQuery.mutate(id, {
            onSuccess: (data: IOoResponse | undefined) => {
                if (_.isUndefined(data)) {
                    return data;
                }
            },
            onError: (e: any) => {
                setErrorStatus(e);
            },
            onSettled: () => {
                resetKakaoResendModalVisible();
            },
        });
    };
    //배송상태 변경
    const changeDeliveryStatus = (param: ChangeDeliveryStatus) => {
        changeStatusQuery.mutate(param, {
            onSuccess: (data: any[] | undefined) => {
                if (_.isUndefined(data)) {
                    console.log(data);
                    return data;
                }
                updateDisplay(_.concat(data));
            },
            onError: (e: any) => {
                setErrorStatus(e);
            },
            onSettled: () => {
                resetCancelOrderModalVisible();
            },
        });
    };

    // 주문 취소
    const cancelOrder = (param: CancelOrderComment) => {
        cancelOrderQuery.mutate(param, {
            onSuccess: (data: IOoUnospayDelivery[] | undefined) => {
                if (_.isUndefined(data)) {
                    return data;
                }
                updateDisplay(_.concat(data));
            },
            onError: (e: any) => {
                setErrorStatus(e);
            },
            onSettled: () => {
                resetCancelOrderModalVisible();
            },
        });
    };

    // 주문 플랫폼 변경
    const changeOrderPlatform = (param: ChangeOrderPlatform) => {
        orderPlatformQuery.mutate(param, {
            onSuccess: async (data: IOoUnospayDelivery | undefined) => {
                if (_.isUndefined(data)) return;
                updateDisplay(_.concat(data));
                const copied = cloneDeep(calendarList);
                console.log(calendarList);
                const index = findIndex(copied, (item) => isEqual(item.id, param.id));
                if (gte(index, 0)) {
                    copied[index] = data;
                    setCalendarList(copied);
                }
            },
            onError: (e: any) => {
                setErrorStatus(e);
            },
            onSettled: () => {
                resetOrderPlatformModalVisible();
            },
        });
    };

    const updateOrderCsInfo = (param: OrderCsInfo) => {
        updateOrderCsInfoQuery.mutate(param, {
            onSuccess: (data: IOoResponse | undefined) => {
                if (_.isUndefined(data)) {
                    return data;
                }
            },
            onError: (e: any) => {
                setErrorStatus(e);
            },
        });
    };

    const updateHospitalInfo = (param: ChangeHospitalInfo) => {
        updateHospitalInfoQuery.mutate(param, {
            onSuccess: (data: IOoDataResponse<IOoUnospayDelivery> | undefined) => {
                if (_.isUndefined(data)) {
                    return data;
                }
            },
            onError: (e: any) => {
                setErrorStatus(e);
            },
        });
    };

    return {
        updateDeliveryInfo,
        changeDeliveryStatus,
        cancelOrder,
        resendKakaoAlimtalk,
        updateTransportInfo,
        displayList,
        updateDisplay,
        changeOrderPlatform,
        updateOrderCsInfo,
        updateHospitalInfo,
    };
};
