import { Body4_Medium } from '@styles/typography';
import styled from 'styled-components';
import { font } from '@styles/globalStyles';
import IconArrowUp from '@assets/icons/pagination/IconArrowUp';
import IconArrowDown from '@assets/icons/pagination/IconArrowDown';
import { useEffect, useRef } from 'react';
import { ClientTypeDropdownWithLabelProps } from '@api/models/common/dropdownType';
import { BackgroundColor, BorderColor } from '@styles/colorSemantic';
import { DropdownCell, DropdownLayout } from '@components/dropdown/dropdownForms';
import DropdownButton from '@components/dropdown/dropdownButton';
import { ReorderAlimtalkDropdownProps } from '@api/models/reorderAlimtalk/reorderAlimtalkModels';

export const ReorderAlimtalkDropdownWithLabel = ({
    label,
    selected,
    handleDropdown,
    list,
    onDropdown,
    setOnDropdown,
    buttonEdit,
    type,
    $width,
    $dropdownHeight,
    $dropdownWidth,
    $labelWidth,
    $height,
    $info,
}: ClientTypeDropdownWithLabelProps) => {
    const dropdownRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && onDropdown) {
                setOnDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onDropdown]);

    const handleButtonClick = () => {
        if (buttonEdit) {
            setOnDropdown(!onDropdown);
        }
    };

    const visibleDropdownIcon = () => {
        if (buttonEdit) {
            return <IconArrowDown />;
        }
    };

    return (
        <InputWrapper $width={$width} $info={$info} type={type}>
            <Label $labelWidth={$labelWidth} $info={$info}>
                {label}
            </Label>
            <DropdownButton $width={$dropdownWidth ?? '300px'} $height={$height ?? '70%'} ref={dropdownRef} onClick={handleButtonClick} buttonEdit={buttonEdit}>
                {list[selected]}
                <DropdownIconWrapper>
                    {onDropdown ? (
                        <>
                            <IconArrowUp />
                            <Dropdown selected={selected} handleDropdown={handleDropdown} list={list} type={type || undefined} $height={$dropdownHeight} />
                        </>
                    ) : (
                        visibleDropdownIcon()
                    )}
                </DropdownIconWrapper>
            </DropdownButton>
        </InputWrapper>
    );
};

export const Dropdown = ({ selected, handleDropdown, list, type, $height, $fontSize, $padding, $justifyContent }: ReorderAlimtalkDropdownProps) => {
    console.log($justifyContent);
    return (
        <DropdownLayout $position='absolute' $height={$height || '128px'} $padding='4px 0' $top='23px' $left='0'>
            {list.map((item, idx) => {
                return (
                    <DropdownCell key={idx} $height='30px' onClick={() => handleDropdown(idx, type)} $selected={selected === idx} $fontSize={$fontSize} $justifyContent={$justifyContent}>
                        {item}
                    </DropdownCell>
                );
            })}
        </DropdownLayout>
    );
};

const InputWrapper = styled.div<{ $width?: string; $info?: boolean; type?: string }>`
    position: relative;
    display: flex;
    align-items: center;
    width: ${(props) => props.$width || '100%'};
    height: 50px;
    ${(props) => props.$info && props.type !== 'status' && `border-bottom: 1px solid ${BorderColor.color_border_neutral}`};
`;

const Label = styled(Body4_Medium)<{ $labelWidth?: string; $info?: boolean }>`
    width: ${(props) => props.$labelWidth || '100px'};
    ${(props) =>
        props.$info &&
        `
    display: flex;
    align-items: center;
    background-color: ${BackgroundColor.color_background_neutral};
    height: 35px;
    padding-left: 5px;
    border-radius: 4px;
    `}
`;

const DropdownIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    gap: 4px;
    color: ${(props) => props.color};
    font-weight: ${font.weight.medium};
`;
