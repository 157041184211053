interface IOoResponse {
    _id?: string;
    status: string;
    timestamp: number;
    __v?: number;
}

interface IOoError {
    code: number;
    message: string;
}

interface IOoDataResponse<T> extends IOoResponse {
    data?: T;
}

interface IOoErrorResponse extends IOoResponse {
    error?: IOoError;
}

export function isRequestSucceed(response: IOoResponse): boolean {
    return response.status === 'ok';
}

export type { IOoResponse, IOoDataResponse, IOoErrorResponse, IOoError };
