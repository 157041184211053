import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';
import { IOoUnospayDelivery } from '@api/models';

interface DisplayInfo {
    id: string;
    list: Array<IOoUnospayDelivery>;
}

const displayAtom = atom<Array<string>>({
    key: 'unospay/displayAtom',
    default: [],
});

const displayAtomFamily = atomFamily<DisplayInfo, string>({
    key: 'unospay/displayAtomFamily',
    default: (id) => {
        const item: DisplayInfo = {
            id,
            list: [],
        };
        return item;
    },
});

export const unospayDisplayListState = selectorFamily<DisplayInfo, string>({
    key: 'unospay/displayListState',
    get:
        (id: string) =>
        ({ get }) =>
            get(displayAtomFamily(id)),
    set:
        (id: string) =>
        ({ get, set, reset }, displayInfo) => {
            if (displayInfo instanceof DefaultValue) {
                reset(displayAtomFamily(id));
                set(displayAtom, (prevValue) => prevValue.filter((item) => item !== id));
                return;
            }
            set(displayAtomFamily(id), displayInfo);
            set(displayAtom, (prev) => Array.from(new Set([...prev, id])));
        },
});
