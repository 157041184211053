import { OoDiagnosisPayType, OoHospitalMedicineType } from "@api/models";

class ExcelExportModel {
  excelStatus ?: string;

  product?: string = "";

  name?: string = "";

  tel1?: string = "";

  tel2?: string = "";

  option?: string = "";

  address1?: string = "";

  address2?: string = "";

  zipCode?: string = "";

  memo?: string = "";

  subMemo?: string = "";

  quantity?: string = "";

  exCode?: string = "";

  productCode?:string = "";

  orderDate?: string = "";

  optionCodeList?: Array<string> = [];

  custOrderNum?: string = "";

  holderCnt?: string = "";

  withNewCare?: string = "";

  countType?:string = ""

  price?: string = "";
  
  providerName?: string = "";
  
  orderPlatform?: string = "";
  
  hospitalCalPrice?: string = "";

  menu?: string = "";

  menuCode?: string = "";

  deliveryDate?: string = "";

  deliveryCost?: string = "";

  deliveryPrice?: string = "";

  menuCost?: string = "";

  hospitalName?: string = "";

  dawndeliveryType?: string = "";

  dawnVisitMethod?: string = "";

  // 주문번호 (필요에 따라 활용)
  orderNumber?: string = "";

  // 단위 
  unit?: string = "";
}

export { ExcelExportModel };
