import React from 'react';
import styled from 'styled-components';
import PieChartHospitalTypeDashBoard from '../CalculatePage/components/PieChartHospitalTypeDashBoard';

const CalculateDashboardPage = () => {
    return (
        <Layout>
            <TopContainer>
                <Container>
                    <PieChartHospitalTypeDashBoard />
                </Container>
            </TopContainer>
        </Layout>
    );
};

export default CalculateDashboardPage;

const Layout = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

const TopContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    top: 130px;
    align-items: center;
`;

const Container = styled.div`
    width: 100%;
    max-width: 55%;
    min-width: 1400px;
    height: 100%;
    margin-bottom: 180px;
    overflow-y: scroll;
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 40px;
`;
