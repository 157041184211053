import { ModalOverlay } from "@components/modal";
import { useState, useEffect } from "react";
import styled from "styled-components";
import _, { result } from "lodash";
import { IOoUnospayDelivery, OoProvider } from "@api/models";
import { hdOrder } from "../ExcelDownloadButton/components/hdOrder";
import { dsOrder } from "../ExcelDownloadButton/components/dsOrder";
import { ssOrder } from "../ExcelDownloadButton/components/ssOrder";
import { pmoOrder } from "../ExcelDownloadButton/components/pmoOrder";
import { mdslOrder } from "../ExcelDownloadButton/components/mdslOrder";
import { empOrder } from "../ExcelDownloadButton/components/empOrder";
import { nbOrder } from "../ExcelDownloadButton/components/nbOrder";
import tktOrder from "../ExcelDownloadButton/components/tktOrder";
import jspOrder from "../ExcelDownloadButton/components/jspOrder";
import mkOrder from "../ExcelDownloadButton/components/mkOrder";
import mhdOrder from "../ExcelDownloadButton/components/mhdOrder";
import smOrder from "../ExcelDownloadButton/components/smOrder";
import { ItemType } from '../../../UnospayOrderDetailPage/components/DeliveryInfo/index';
import buyerOrder from "../ExcelDownloadButton/components/buyerOrder";
import { DoctorOnAPI } from "@api/request";
import { IOoUnospayProvider } from "@api/models/unospay/provider";
import ddOrder from "../ExcelDownloadButton/components/ddOrder";
import mocOrder from "../ExcelDownloadButton/components/mocOrder";
import mmxOrder from "../ExcelDownloadButton/components/mmxOrder";
import nutritionOrder from "../ExcelDownloadButton/components/nutritionOrder";
import foremeOrder from "../ExcelDownloadButton/components/foremeOrder";
import bicnuOrder from "../ExcelDownloadButton/components/bicnuOrder";
import { IOoProducts } from "@api/models/productSetting/product";
import dongaotsukaOrder from "../ExcelDownloadButton/components/dongaotsukaOrder";
import overMaxOrder from "../ExcelDownloadButton/components/overMaxOrder";
import idoctorOrder from "../ExcelDownloadButton/components/idoctorOrder";
import pharmanetOrder from "../ExcelDownloadButton/components/pharmanetOrder";
import duldaOrder from "../ExcelDownloadButton/components/duldaOrder";
import maeilOrder from "../ExcelDownloadButton/components/maeilOrder";
import udLifeOrder from "../ExcelDownloadButton/components/udLifeOrder";
import eromOrder from "../ExcelDownloadButton/components/eromOrder";
import { useProductListQuery } from "@queries/product/useProductListQuery";
import shinangchonOrder from "../ExcelDownloadButton/components/shinangchonOrder";
import ssentakOrder from "../ExcelDownloadButton/components/ssentakOrder";

interface Props {
  modalVisible: boolean;
  title: any;
  contents: any;
  cancelButtonName: string;
  submitButtonName: string;
  selectValue?: number;
  onChangedVisible: (status: boolean) => void;
  onChangedTrigger: (status: boolean, value: number) => void;
  list: Array<IOoUnospayDelivery>;
}

const MTS_NUM = 2;
const PASSCODE = ["OF33_01","OF34_01","OF34_02","OF34_03","OF34_04","OF34_05","OF35_01","OF36_01","OF37_01","OF37_02","OF37_03","OF37_04"];
//CMG제약 , 아미노바이탈 제품에 대한 마진율 정보가 없는 상태여서 넣음
const CMG_CODE = ['OF38_01', 'OF38_02', 'OF38_03', 'OF38_04','OF38_05'];
const AMINO_CODE = ['OF42_01'];

const filterProviderMenuList = (tlcMenuList:IOoUnospayDelivery[], providerCode:string) => {
  console.log(tlcMenuList);
  
  let newTlcItemList:IOoUnospayDelivery[] = [];
  tlcMenuList.map(tlcItem => {
    tlcItem.salesMenu?.map((sale) => {
      if(_.startsWith(sale.externalCode, 'OF12')) {
        const newSalesMenu = {...sale, externalCode:sale.externalCode, menuName:sale.optionName , salesOption:sale.salesOption};
        const newITem = {...tlcItem, salesMenu: [{...newSalesMenu}]}
        newTlcItemList.push(newITem);
      }else{
      sale.salesOption?.map(option => {
        if(_.startsWith(option.externalCode, 'OF12')){
          const newSalesMenu = {...sale, externalCode:option.externalCode, menuName:option.optionName , salesOption:[]};
          const newITem = {...tlcItem, salesMenu: [{...newSalesMenu}]}
          newTlcItemList.push(newITem);
        }
      });
      }
    })
  })
  return newTlcItemList;
}

export const listProviderFilter = (list:IOoUnospayDelivery[] , providerCode:string) => {
  let excelList:IOoUnospayDelivery[] = [];
  list.map(item => {
    item.salesMenu?.map((sale) => {
      console.log('sale :: ', sale);
      if(_.startsWith(sale.externalCode, providerCode)) {
        const newSalesMenu = {...sale, externalCode:sale.externalCode, menuName:sale.menuNameCh , salesOption:sale.salesOption};
        const newITem = {...item, salesMenu: [{...newSalesMenu}]}
        console.log('newITem :: ', newITem);
        excelList.push(newITem);
      } else {
        sale.salesOption?.map(option => {
          if(_.startsWith(option.externalCode, providerCode)){
            const newSalesMenu = {...sale, externalCode:option.externalCode, menuName:option.optionName , salesOption:[]};
            const newITem = {...item, salesMenu: [{...newSalesMenu}]}
            excelList.push(newITem);
          }
      });
      }
    })
  })
  return excelList;
}

export const findTlcMenuList = (list:IOoUnospayDelivery[] , providerCode:string) => {
  const tlcMenuList =  list.filter((item) =>
    item.transportInfo.some((i) =>_.startsWith(i.externalCode, 'OF12'))
  );
  if(tlcMenuList.length > 0){
    return filterProviderMenuList(tlcMenuList , providerCode);
  }else{
    return;
  }
}

export const exCodeNumToStr = (num: number) => {
  let retStr = "";
  console.log(num);
  switch (num) {
    case 1:
      return "OF04";
    case 2:
      return "OF10";
    case 3:
      return "OF05";
    case 4:
      return "OF07";
    case 5:
      return "OF13";
    case 6:
      return "OF18";
    case 7:
      return "OF26";
    case 8:
      return "OF27";
    case 9:
      return "OF29";
    case 10:
      return "OF30";
    case 11:
      return "OF31";
    case 12:
      return "OF32";
    case 14:
      return "OF39";
    case 15:
      return "OF48";
    case 16:
      return "OF41";
    case 17:
      return "OF45";
    case 18:
      return "OF40";
    case 19:
      return "OF50";
    case 20:
      return "OF53";
    case 27: // 덜다
      return "OF46";
    case 28: // 매일유업
      return "OF08";
    case 29: // 유디라이프케어
      return "OF63";
    case 30: // 이롬
      return "OF74";
    case 31: // 신앙촌
      return "OF70";
    case 32: // 쎈닭
      return "OF71";
    default:
      return retStr
  }
}



export const ExcelDownloadModal = ({
  modalVisible,
  title,
  contents,
  cancelButtonName,
  submitButtonName,
  selectValue,
  onChangedVisible,
  onChangedTrigger,
  list,
}: Props) => {
  const [handleVisible, setHandleVisible] = useState<boolean>(modalVisible);
  const [submit, setSubmit] = useState<boolean>(false);
  // const [providerCodeList, setProviderCodeList] = useState<IOoProducts[]|string[]>([]);
  const [orgProductList, setOrgProductList] = useState<IOoProducts[]>([]);
  const [providerList , setProviderList] = useState<IOoUnospayProvider[]>();
  const [selectOrderNumber, setSelectOrderNumber] = useState<number>(
    selectValue ?? 0
  );

  const { data: productList, isLoading: isProductLoading, refetch: refetchProducts } = useProductListQuery();


const providerReplaceText = (providerName:string) => {
  if(orgProductList){
    return orgProductList?.filter(item => item.providerName.replace('\t','').replace(' ','') === providerName);
  }
}

const getProductSettingList = async () => {
  try {
      const response = await DoctorOnAPI.shared.productSetting.list();
      if(response.status === 'ok'){
        setOrgProductList( response.data!);
      }
  } catch (error : any) {
      console.log(error.message);
  }
}

  const handleDownload = () => {
    if (selectOrderNumber === OoProvider.hdProvider) {
      hdOrder(list, selectOrderNumber);
    } else if (selectOrderNumber === OoProvider.dsProvider) {
      dsOrder(list, selectOrderNumber);
    } else if (selectOrderNumber === OoProvider.ssProvider) {
      ssOrder(list, selectOrderNumber);
    } else if (selectOrderNumber === OoProvider.pmoProvider) {
      pmoOrder(list, selectOrderNumber);
    } else if (selectOrderNumber === OoProvider.mdslProvider) {
      mdslOrder(list, selectOrderNumber);
    } else if (selectOrderNumber === OoProvider.empProvider) {
      empOrder(list, selectOrderNumber);
    } else if (selectOrderNumber === OoProvider.nbProvider) {
      nbOrder(list, selectOrderNumber);
    } else if (selectOrderNumber === OoProvider.tktProvider) {
      tktOrder(list, selectOrderNumber);
    } else if (selectOrderNumber === OoProvider.jspProvider) {
      jspOrder(list, orgProductList);
    } else if (selectOrderNumber === OoProvider.mkProvider) {
      mkOrder(list);
    } else if (selectOrderNumber === OoProvider.smProvider) {
      smOrder(list);
    } else if (selectOrderNumber === OoProvider.mhdProvider) {
      mhdOrder(list, orgProductList);
    } else if (selectOrderNumber === OoProvider.buyerProvider) {
      const mtsProductList = orgProductList?.filter(item => item.isConsignment === true);
      buyerOrder(list, mtsProductList);
    } else if (selectOrderNumber === OoProvider.ddProvider) {
      ddOrder(list);
    } else if (selectOrderNumber === OoProvider.mocProvider) {
      mocOrder(list, orgProductList);
    } else if (selectOrderNumber === OoProvider.mmxProvider) {
      const productSettingList = providerReplaceText('모먼트엑스')!;
      mmxOrder(list, productSettingList);
    } else if (selectOrderNumber === OoProvider.nutritionProvider) {
      nutritionOrder(list);
    } else if (selectOrderNumber === OoProvider.foremeProvider) {
      foremeOrder(list);
    } else if (selectOrderNumber === OoProvider.bicnuProvider) {
      bicnuOrder(list);
    }else if(selectOrderNumber === OoProvider.dongaotsukaProvider){
      dongaotsukaOrder(list, productList || []);
    }else if(selectOrderNumber === OoProvider.overMaxProvider){
      overMaxOrder(list);
    }else if(selectOrderNumber === OoProvider.yuhanProvider){
      pharmanetOrder(list,'유한양행','OF33');
    }else if(selectOrderNumber === OoProvider.dxvxProvider){
      pharmanetOrder(list,'DXVX','OF35');
    }else if(selectOrderNumber === OoProvider.femininedayProvider){
      idoctorOrder(list, '페미라이드', 'OF34');
    }else if(selectOrderNumber === OoProvider.momProvider){
      idoctorOrder(list, '맘앙떼', 'OF36');
    }else if(selectOrderNumber === OoProvider.btyProvider){
      idoctorOrder(list, '배러유', 'OF37');
    }else if(selectOrderNumber === OoProvider.duldaProvider){
      const productSettingList = providerReplaceText('덜다')!;
      duldaOrder(list, productSettingList);
    }else if(selectOrderNumber === OoProvider.maeilProvider){
      const productSettingList = providerReplaceText('매일유업')!;
      maeilOrder(list, productSettingList);
    }else if(selectOrderNumber === OoProvider.udLifeProvider){
      udLifeOrder(list, orgProductList);
    }else if(selectOrderNumber === OoProvider.eromProvider){
      eromOrder(list, productList || []);
    }else if(selectOrderNumber === OoProvider.shinangchonProvider){
      shinangchonOrder(list, productList || []);
    }else if(selectOrderNumber === OoProvider.ssentakProvider){
      ssentakOrder(list, productList || []);
    }
  };

  const onSelectFilterOrder = (e: any) => {
    const { value } = e.target;
    setSelectOrderNumber(_.toNumber(value));
  };

  const handleDownloadAndCloseModal = () => {
    handleDownload();
    setHandleVisible(false);
  };


  const getFoodProviderList = async () => {
    const response = await DoctorOnAPI.shared.unospay.foodProviderList();
    if(response.status ==='ok'){
      setProviderList(response.data);
    }
  }

  useEffect(() => {
    setHandleVisible(modalVisible);
    setSelectOrderNumber(selectValue!);
    getProductSettingList();
    getFoodProviderList();
  }, [modalVisible]);

  useEffect(() => {
    onChangedVisible(handleVisible);
  }, [handleVisible]);

  useEffect(() => {
    if (submit) {
      onChangedTrigger(submit, selectOrderNumber);
      setSubmit(false);
    }
  }, [submit]);

  return (
    <ModalOverlay visible={handleVisible}>
      <Wrapper>
        <Contents>
          <div className="contentsTitle">{title}</div>
          <div className="contents">{contents}</div>
          <SelectContainer>
            <select
              className="select"
              onChange={onSelectFilterOrder}
              value={selectOrderNumber}
            >
              <option value={OoProvider.hdProvider}>현대그리팅</option>
              <option value={OoProvider.dsProvider}>대상</option>
              <option value={OoProvider.ssProvider}>순수본</option>
              <option value={OoProvider.pmoProvider}>풀무원</option>
              <option value={OoProvider.mdslProvider}>메디솔라</option>
              <option value={OoProvider.empProvider}>잇마플</option>
              <option value={OoProvider.nbProvider}>나비푸드</option>
              <option value={OoProvider.tktProvider}>팀키토</option>
              <option value={OoProvider.jspProvider}>정식품</option>
              <option value={OoProvider.mkProvider}>멜킨</option>
              <option value={OoProvider.smProvider}>식스밀</option>
              <option value={OoProvider.mhdProvider}>닥터다이어리</option>
              <option value={OoProvider.buyerProvider}>사입업체</option>
              <option value={OoProvider.ddProvider}>디에트데이</option>
              <option value={OoProvider.mocProvider}>마이오케어</option>
              <option value={OoProvider.mmxProvider}>모먼트엑스</option>
              <option value={OoProvider.nutritionProvider}>뉴트리션팩토리</option>
              <option value={OoProvider.foremeProvider}>포르미</option>
              <option value={OoProvider.bicnuProvider}>빅뉴트리션</option>
              <option value={OoProvider.dongaotsukaProvider}>동아오츠카</option>
              <option value={OoProvider.overMaxProvider}>오버맥스</option>
              <option value={OoProvider.yuhanProvider}>파마인넷_유한양행</option>
              <option value={OoProvider.dxvxProvider}>파마인넷_DXVX</option>
              <option value={OoProvider.femininedayProvider}>아이닥터샵_페미라이드</option>
              <option value={OoProvider.momProvider}>아이닥터샵_맘앙떼</option>
              <option value={OoProvider.btyProvider}>아이닥터샵_배러유</option>
              <option value={OoProvider.duldaProvider}>덜다</option>
              <option value={OoProvider.maeilProvider}>매일유업</option>
              <option value={OoProvider.udLifeProvider}>유디라이프케어</option>
              <option value={OoProvider.eromProvider}>이롬</option>
              <option value={OoProvider.shinangchonProvider}>신앙촌</option>
              <option value={OoProvider.ssentakProvider}>쎈닭</option>
            </select>
          </SelectContainer>
        </Contents>
        <ButtonContainer>
          <CancelButton type="button" onClick={() => setHandleVisible(false)}>
            {cancelButtonName}
          </CancelButton>
          <SubmitButton type="button" onClick={handleDownloadAndCloseModal}>
            {submitButtonName}
          </SubmitButton>
        </ButtonContainer>
      </Wrapper>
    </ModalOverlay>
  );
};
const Wrapper = styled.div`
  width: 556px;
  height: 312px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Contents = styled.div`
  width: 100%;
  height: 32px;
  font-weight: 700;
  font-size: 20px;
  text-align: center;

  & .contentsTitle {
    width: 100%;
    height: 32px;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 36px;
  }

  & .contents {
    width: 100%;
    color: #000;
    text-align: center;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    margin-top: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 184px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 222px;
  height: 60px;
  background: #6e8ed1;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;
`;

const CancelButton = styled.button`
  width: 222px;
  height: 60px;
  background: #cf5d5d;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border: none;
  margin-right: 10px;
`;

const SelectContainer = styled.div`
  width: 100%;
  height: 52px;
  flex-shrink: 0;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 36px;
  .select {
    border-radius: 8px;
    border: 0.5px solid #c2c1bd;
    width: 240px;
    height: 52px;
    padding: 5px;
  }
`;

