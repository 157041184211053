import styled from 'styled-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import CustomedPagination from '@components/pagination/CustomedPagination';
import { ClientNoticeListTableProps } from '@api/models/notice/clientNotice/notice';
import moment from 'moment';
import { ListTable } from '@styles/tableStyles';
import { MdAttachFile } from 'react-icons/md';

const NoticesTable = ({ data, currentPage, sectionIndex, totalPages, onChangeCurrentPage, onChangeSectionIdx, type }: ClientNoticeListTableProps) => {
    // const itemsPerPage = 10;

    return (
        <ListTable.Wrapper type={type}>
            {data.map((item, index) => {
                // const itemNumber = totalCount - (currentPage - 1) * itemsPerPage - index;
                return (
                    item.isActive && (
                        <Link key={index} to={`/detailNotice`} state={{ id: item._id }} style={{ textDecoration: 'none', color: 'black', overflow: 'visible' }}>
                            <ListTable.ListContainer type={type}>
                                <div className='flexNone' style={{ width: '50%' }}>
                                    {item.title}
                                </div>
                                <div style={{ width: '25%' }}>{item.files.length > 0 ? <MdAttachFile size={20} /> : ''}</div>
                                <div style={{ width: '25%' }}>{moment(item.timestamp).format('YYYY/MM/DD')}</div>
                            </ListTable.ListContainer>
                        </Link>
                    )
                );
            })}
            <CustomedPagination currentPage={currentPage} onChangeCurrentPage={onChangeCurrentPage} onChangeSectionIdx={onChangeSectionIdx} sectionIndex={sectionIndex} totalPages={totalPages} />
        </ListTable.Wrapper>
    );
};

export default NoticesTable;

const FlexWrapper = styled.div`
    display: flex;
    gap: 10px;
`;
