import { CustomPaginationProps, ITPagination } from '@api/models/pagination/customPaginationType';

export interface ITClientNotice {
    _id: string;
    title: string;
    content: string;
    isActive: boolean;
    isPopup: boolean;
    visibilityTarget: string[];
    startDate: string;
    endDate: string;
    files: any;
    timestamp: number;
    __v: number;
}

export interface GetClientNoticeApiResponse {
    list: ITClientNotice[];
    pagination: ITPagination;
}

// export interface ClientCreateRqData {
//     name: string; // required
//     code: string; // required
//     tel: string; // required
//     address: string; // required
//     addressDetail: string;
//     zipCode: string; // required
//     bank: string;
//     bankAccount: string;
//     bankHolderName: string;
//     referrerList: string[];
//     clientType: number;
//     businessNumber: string;
// }

export interface ClientNoticeListTableProps extends CustomPaginationProps {
    type?: string;
    data: ITClientNotice[];
}

export interface VisibleNoticeData {
    data: ITClientNotice;
    onModal: boolean;
}

export interface HiddenItemsType {
    _id: string;
    timestamp: number;
}

export namespace Quill {
    export const formats: string[] = [
        'header',
        'size',
        'font',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'color',
        'background',
        'align',
        'script',
        'code-block',
        'clean',
    ];
}
