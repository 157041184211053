import _ from 'lodash';
import styled from 'styled-components';
import { IOoUnospayDelivery } from '@api/models';
import { useOrderPlatformStatus } from '@api/models/unospay/functions/fnOrderPlatform';
import { CustomStateCard } from '@components/custom/customStateCard';

interface Props {
    data: IOoUnospayDelivery;
    flex?: number;
}

export const OrderPlatformField = ({ data, flex }: Props) => {
    const { filteredOrderPlatformStatus } = useOrderPlatformStatus(data);

    enum ButtonBgColor {
        kiosk = 'rgba(11, 171, 56, 0.08)',
        mtmMall = 'rgba(62, 123, 205, 0.08)',
        telephone = 'rgba(233, 89, 149, 0.08)',
        tlcMall = 'rgba(218, 140, 72, 0.08)',
        test = 'rgba(142, 62, 205, 0.08)',
    }

    enum ButtonFontColor {
        kiosk = '#0BAB38',
        mtmMall = '#3E7BCD',
        telephone = '#E95995',
        tlcMall = '#ED7014',
        test = '#8E3ECD',
    }

    return (
        <Wrapper flex={flex ?? 0}>
            {filteredOrderPlatformStatus().includes('키오스크') ? (
                <CustomStateCard id={`${data.id}`} bgColor={`${ButtonBgColor.kiosk}`} color={`${ButtonFontColor.kiosk}`}>
                    키오스크
                </CustomStateCard>
            ) : filteredOrderPlatformStatus().includes('MTM 몰') ? (
                <CustomStateCard id={`${data.id}`} bgColor={`${ButtonBgColor.mtmMall}`} color={`${ButtonFontColor.mtmMall}`}>
                    MTM 몰
                </CustomStateCard>
            ) : filteredOrderPlatformStatus().includes('전화주문') ? (
                <CustomStateCard id={`${data.id}`} bgColor={`${ButtonBgColor.telephone}`} color={`${ButtonFontColor.telephone}`}>
                    전화주문
                </CustomStateCard>
            ) : filteredOrderPlatformStatus().includes('TLC 몰') ? (
                <CustomStateCard id={`${data.id}`} bgColor={`${ButtonBgColor.tlcMall}`} color={`${ButtonFontColor.tlcMall}`}>
                    TLC 몰
                </CustomStateCard>
            ) : (
                <CustomStateCard id={`${data.id}`} bgColor={`${ButtonBgColor.test}`} color={`${ButtonFontColor.test}`}>
                    TEST
                </CustomStateCard>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div<{ flex: number }>`
    flex: ${(props) => props.flex};
    display: flex;
    justify-content: center;
    align-items: center;
`;
