import styled from "styled-components";
import { formatTel } from "@hooks/format";
import { deliveryProcessingStatus, IOoUnospayDelivery } from "@models/unospay";
import { OrderDetailInfo } from "../OrderDetailInfo";
import { useEffect, useState } from "react";
import { OrderPlatformModal } from "../CustomModal/OrderPlatformModal";
import { useUnosOrderDetailPageSettingHook } from "../../hooks/useUnosOrderDetailPageSettingHook";
import { useUnospayFunctionHook } from "@hooks/unospay";
import { OrderPlatformField } from "../../../UnospayDashBoardPage/components/OrderPlatformField";
import { formatDateMDhhmm } from "@hooks/format";
import { PurchasePathModal } from "../CustomModal/PurchasePathInfoModal";
import { useRecoilValue } from "recoil";
import { selectedUserState } from "@recoils/oauth";

interface Props {
  data: IOoUnospayDelivery;
}

export interface ChangeOrderPlatform {
  id: string;
  type: number;
}

export interface ChangeHospitalInfo {
  id: string;
  code: string;
}

const AUTHORIZED_USER_EMAIL = ['dev@theopusone.com', 'operator2@theopusone.com'];

const IS_DEV = false;
const baseURL = IS_DEV
  ? `http://localhost:3000/unospayOrderDetail`
  : "https://doctor-on-react.web.app/unospayOrderDetail";

export const RecipientInfo = ({ data }: Props) => {
  const url = `${baseURL}/${data.id}`;
  const onHandleOpenPopUp = () => {
    const width = 800;
    const height = 700;

    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    window.open(
      url,
      "상세페이지",
      `width=${width},height=${height},left=${left},top=${top},scrollbars=yes`
    );
  };

  const user = useRecoilValue(selectedUserState);
  const hasEditPermission = AUTHORIZED_USER_EMAIL.includes(user?.user.email || "");

  const {
    changeOrderPlatformQueryTrigger,
    setChangeOrderPlatformQueryTrigger,
    orderPlatformModalVisible,
    setOrderPlatformModalVisible,
    changeOrderPlatformValue,
    setChangeOrderPlatformValue,
    purchasePathModalVisible,
    setPurchasePathModalVisible,
    changePurchasePathTrigger,
    setChangePurchasePathTrigger,
    handleChangeOrderPlatform,
    handleChangePurchasePath,
    setSelectedHospitalCode,
    selectedHospitalCode,
  } = useUnosOrderDetailPageSettingHook();

  const { changeOrderPlatform,
    updateHospitalInfo
   } = useUnospayFunctionHook(data.id || "");

  const param: ChangeOrderPlatform = {
    id: data.id || "",
    type: changeOrderPlatformValue,
  };

  const updateHospitalParam: ChangeHospitalInfo = {
    id: data.id || "",
    code: selectedHospitalCode,
  };

  const deliveryStatusData = deliveryProcessingStatus(data);

  useEffect(() => {
    changeOrderPlatformQueryTrigger && changeOrderPlatform(param);
    setChangeOrderPlatformQueryTrigger(false);
  }, [changeOrderPlatformQueryTrigger]);

  useEffect(() => {
    changePurchasePathTrigger && updateHospitalInfo(updateHospitalParam);
    setChangePurchasePathTrigger(false);
  }, [changePurchasePathTrigger]);

  return (
    <Wrapper>
      <OrderPlatformModal
        modalVisible={orderPlatformModalVisible}
        selectValue={data.orderPlatform || 0}
        cancelButtonName="취소"
        submitButtonName="수정하기"
        onChangedVisible={(status) => setOrderPlatformModalVisible(status)}
        onChangedTrigger={function (status, value) {
          setChangeOrderPlatformQueryTrigger(status);
          setChangeOrderPlatformValue(value);
        }}
        title="구매경로 수정"
        contents="지정할 구매경로를 선택해주세요."
      ></OrderPlatformModal>
      <PurchasePathModal
        selectValue={selectedHospitalCode || ""}
        modalVisible={purchasePathModalVisible}
        cancelButtonName="취소"
        submitButtonName="수정하기"
        onChangedVisible={(status) => setPurchasePathModalVisible(status)}
        onChangedTrigger={function (status, code) {
          setChangePurchasePathTrigger(status);
          setSelectedHospitalCode(code);
        }}
      ></PurchasePathModal>
      <Table>
        <thead></thead>
        <tbody>
          <tr>
            <td>주문일시</td>
            <td>{formatDateMDhhmm(data.timestamp)}</td>
            <td>주문상태</td>
            <td>
              <StatusTitle color={deliveryStatusData.color}>
                {deliveryStatusData.title}
              </StatusTitle>
            </td>
          </tr>
          <tr>
            <td>구매경로</td>
            <td>
              <OrderPlatformInfo>
                <OrderPlatformField data={data} flex={0.9}></OrderPlatformField>
                <EditButton onClick={handleChangeOrderPlatform}>
                    수정하기
                </EditButton>
              </OrderPlatformInfo>
            </td>
            <td>회원등급</td>
            <td>{data.group_name || "-"}</td>
          </tr>
          <tr>
            <td>전화번호</td>
            <td>{formatTel(data.phoneNumber || "-")}</td>
            <td>병원 정보</td>
            <td>
              <PurchasePathInfo>
                {data.hospitalName || "-"}
                {hasEditPermission && (
                <EditButton onClick={handleChangePurchasePath}>
                  수정하기
                </EditButton>
                )}
              </PurchasePathInfo>
            </td>
          </tr>
          <tr>
            <td>재구매여부</td>
            <td>
              {data.reorderCount! || 0 > 0
                ? `재구매(${data.reorderCount!}회)`
                : "첫구매"}
            </td>
            <td>
              재구매 <br /> 알림톡 동의
            </td>
            <td>
              {data.noticeConsent!
                ? `동의`
                : "미동의"}
            </td>
          </tr>
        </tbody>
      </Table>
      {/*<OrderDetailInfo data={data}></OrderDetailInfo>*/}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  //padding-bottom: 10px;
  margin-top: 8px;
`;

const OrderPlatformInfo = styled.div`
  display: flex;
  float: left;
`;

const PurchasePathInfo = styled.div`
  display: flex;
  float: left;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 7px;
`;

const Table = styled.table`
  border-top: 2px solid #c8d6f8;
  border-bottom: 1px solid #c8d6f8;
  width: 100%;
  height: auto;
  font-size: 13px;
  white-space: pre-wrap;

  & tbody tr {
    border-bottom: 1px solid #c8d6f8;
  }

  & tbody tr td:nth-child(2n-1) {
    background: #f4f6fb;
    text-align: center;

    min-width: 100px;
    height: 48px;
    font-weight: 700;
  }

  & tbody tr td:nth-child(2n) {
    padding-left: 20px;
    width: 45%;
  }
`;

const EditButton = styled.button`
  border: 0.5px solid #c2c1bd;
  border-radius: 8px;
  font-weight: 400;
  align-items: center;
  background: #ffffff;
  color: #000;
  margin-left: 16px;
  width: 95px;
  padding: 5px 0px 7px 0px;
  font-size: 13px;
  line-height: normal;
  text-align: center;
  justify-content: center;
  flex-shrink: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const StatusTitle = styled.div`
  color: ${(props) => props.color};
  font-weight: 700;
  font-size: 13px;
  line-height: normal;
`;
