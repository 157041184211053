import styled from 'styled-components';
import React from 'react';
import { Body1_Bold, Body2_Bold, Body3_Bold, Body5_Medium } from '@styles/typography';

interface Props {
    children: JSX.Element;
    title: string;
    totalCount?: number;
    titleWidth?: string;
}

export const ListTitle = ({ children, title, totalCount, titleWidth }: Props) => {
    return (
        // <Wrapper>
        <Container>
            <Title $titleWidth={titleWidth}>{title}</Title>
            {totalCount !== undefined && <TotalCount>Total: {totalCount}</TotalCount>}
            <ChildrenWrapper> {children}</ChildrenWrapper>
        </Container>
        // </Wrapper>
    );
};

const Wrapper = styled.div`
    // width: 100%;
    // z-index: 10;
`;

const Container = styled.div`
    display: flex;
    width: 1024px;
    height: 80px;
    background-color: white;
    border: 0.5px solid #c2c1bd;
    // justify-content: space-between;
    // align-content: center;
`;

const Title = styled(Body1_Bold)<{ $titleWidth?: string }>`
    width: ${(props) => props.$titleWidth || '10%'};
    display: flex;
    align-items: center;
    margin-left: 32px;
`;

const TotalCount = styled(Body5_Medium)`
    width: 15%;
    display: flex;
    align-items: center;
    padding-top: 4px;
`;
const ChildrenWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
