import { DropdownCellProps, DropdownLayoutProps } from '@api/models/common/dropdownType';
import { BackgroundColor, TextColor } from '@styles/colorSemantic';
import { font, palette, shadow, zIndex } from '@styles/globalStyles';
import { Body5_Medium_button } from '@styles/typography';
import styled from 'styled-components';

export const DropdownLayout = ({ children, $position, $height, $padding, $top, $width, $gap, $right, $left, $bottom, $borderRadius }: DropdownLayoutProps) => {
    return (
        <Layout $position={$position} $height={$height} $padding={$padding} $top={$top} $width={$width} $gap={$gap} $right={$right} $left={$left} $bottom={$bottom} $borderRadius={$borderRadius}>
            {children}
        </Layout>
    );
};

export const DropdownCell = ({ children, $height, $padding, onClick, $selected, $fontSize, $justifyContent }: DropdownCellProps) => {
    return (
        <Cell $height={$height} $padding={$padding} onClick={onClick} $selected={$selected} $fontSize={$fontSize} style={{ justifyContent: $justifyContent }}>
            {children}
        </Cell>
    );
};

const Layout = styled.div<DropdownLayoutProps>`
    position: ${(props) => props.$position};
    display: flex;
    flex-direction: column;
    width: ${(props) => props.$width || '100%'};
    height: ${(props) => (props.$height ? `calc(${props.$height} + 2px)` : 'auto')};
    padding: ${(props) => props.$padding};
    border: 1px solid ${palette.black};
    border-radius: ${(props) => props.$borderRadius || '4px'};
    background-color: ${palette.white};
    top: ${(props) => props.$top};
    right: ${(props) => props.$right};
    left: ${(props) => props.$left};
    bottom: ${(props) => props.$bottom};
    z-index: ${zIndex.dropdown};
    gap: ${(props) => props.$gap};
    box-shadow: ${shadow.shadowDropdown};
    overflow-y: auto;
    box-shadow: rgba(16, 16, 16, 0.12);

    /* 스크롤바 스타일 개선 */
    &::-webkit-scrollbar {
        width: 8px;
        display: block;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        border: 2px solid transparent;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 10px;
    }
`;

const Cell = styled(Body5_Medium_button)<DropdownCellProps>`
    display: flex;
    align-items: center;

    width: 100%;
    min-height: ${(props) => props.$height};
    font-weight: ${(props) => props.$selected && font.weight.semiBold};
    font-size: ${(props) => props.$fontSize};
    padding: ${(props) => props.$padding};
    color: ${TextColor.color_text_normal};
    background-color: ${(props) => props.$selected && BackgroundColor.color_background_primary_normal};
    &:hover {
        background-color: ${(props) => (props.$selected ? BackgroundColor.color_background_primary_strong : BackgroundColor.color_background_neutral)};
    }
    &:active {
        background-color: ${(props) => (props.$selected ? BackgroundColor.color_background_primary_heavy : BackgroundColor.color_background_normal)};
    }
    &:focus {
        background-color: ${(props) => (props.$selected ? BackgroundColor.color_background_primary_heavy : BackgroundColor.color_background_normal)};
    }
`;
