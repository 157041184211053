import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList';
import { List } from '@recoils/display';
import { RawExcelExportModel } from 'pages/Operator/UnospayDashBoardPage/components/ExcelDownloadButton/rawModel';
import _ from 'lodash';
import moment from 'moment';
import FileSaver from 'file-saver';
import { OoOrderPlatformType } from '@api/models';
import { clientTypeList } from 'models/clients/addClientPageTypes';
import { ITClient } from '@api/models/client/client';
import { ITProduct } from '@api/models/product/product';
import { formatTel } from '@hooks/format';
const ExcelJS = require('exceljs');

const DownloadCalculateExcel = async (list: Array<IOoCalculateMonthList>, productList: Array<ITProduct>, clientList: Array<ITClient>) => {
    let data_array: Array<RawExcelExportModel> = [];

    console.log(productList, 'productList');

    const findOrderPlatform = (type: number) => {
        switch (type) {
            case OoOrderPlatformType.kiosk:
                return '키오스크';
            case OoOrderPlatformType.mtmMall:
                return 'MTM몰';
            case OoOrderPlatformType.telephone:
                return '전화주문';
            case OoOrderPlatformType.test:
                return 'Test주문';
            default:
                return '-';
        }
    };

    const findProductType = (excode: string) => {
        const result = productList.find((item) => item.externalCode === excode);
        return result?.productType;
    };

    const getProductType = (type: number | undefined) => {
        switch (type) {
            case 0:
                return 'MTM';
            case 1:
                return 'MTE';
            case 2:
                return 'MTS';
            default:
                return '-';
        }
    };

    const getProductPeriod = (item: IOoCalculateMonthList, product: ITProduct) => {
        if (!_.isUndefined(item.salesOption) && !_.isEmpty(item.salesOption[0]) && product.optionList) {
            return product.optionList.find((option) => option.optionCode === item.salesOption![0].externalCode)?.optionPeriod ?? 0;
        } else {
            return product.productPeriod ?? 0;
        }
    };

    const getProductCount = (item: IOoCalculateMonthList, product: ITProduct) => {
        if (!_.isUndefined(item.salesOption) && !_.isEmpty(item.salesOption[0]) && product.optionList) {
            return product.optionList.find((option) => option.optionCode === item.salesOption![0].externalCode)?.optionProductCount ?? 0;
        } else {
            return product.productCount ?? 0;
        }
    };

    const getProductName = (product: ITProduct, item: IOoCalculateMonthList) => {
        console.log(item, 'item ::::: ');
        if (!_.isUndefined(item.salesOption) && !_.isEmpty(item.salesOption[0]) && product.optionList) {
            return `${product.productName ?? ''} ${product.optionList.find((option) => option.optionCode === item.salesOption![0].externalCode)?.optionName ?? ''}`;
        } else {
            return product.productName ?? '';
        }
    };

    list.forEach((item) => {

        const foundProduct = productList.find((product) => product.externalCode === item.externalCode);

        if (item.orderPlatform === OoOrderPlatformType.test) return;
        if (!_.isEmpty(item.cancelDateTime) || (item.operatorCancelTimestamp && item.operatorCancelTimestamp > 0)) {
            return;
        }

        const model = new RawExcelExportModel();
        const type = findProductType(item.externalCode);
        model.id = item.id;
        model.orderId = item.orderId;
        model.payerName = item.payerName;
        model.payerMobile = item.payerMobile;
        model.dateTime = moment(item.dateTime).format('YYYY-MM-DD HH:mm:ss');
        model.hospitalCode = item.hospitalCode;
        model.hospitalName = item.hospitalName;
        model.orderPlatform = findOrderPlatform(item.orderPlatform);
        model.hospitalCalPrice = item.hospitalCalPrice ? item.hospitalCalPrice : 0;
        model.externalCode = item.externalCode;
        model.goods = foundProduct ? getProductName(foundProduct, item) : '';
        model.productType = getProductType(type);
        model.paymentPrice = item.paymentPrice;
        model.providerName = item.providerName;
        model.refererList = item.refererList ? item.refererList?.join(', ') : '';
        model.refererCalPrice = item.refererCalPrice ? item.refererCalPrice?.join(', ') : '';
        model.cancelDateTime = item.cancelDateTime;
        model.operatorCancelTimestamp = item.operatorCancelTimestamp;
        const client = clientList.find((client) => client.code === item.hospitalCode);
        model.clientType = client ? clientTypeList[client.clientType] : '';
        model.mainCat = foundProduct?.mainCat;
        model.subCat = foundProduct?.subCat;
        model.mtsCat1 = foundProduct?.mtsCat1;
        model.mtsCat2 = foundProduct?.mtsCat2;
        model.mtsCat3 = foundProduct?.mtsCat3;
        model.marginRate = foundProduct?.marginRate;
        model.reorderCount = item.reorderCount;
        model.group_name = item.group_name;
        model.productSettleRate = foundProduct?.settleRate ?? 0;
        model.recipientName = item.recipientName;
        // 제품을 한 번만 찾고 변수에 저장
        // const foundProduct = productList.find((p) => p.externalCode === item.externalCode);
        
        // 제품이 존재할 경우에만 getProductPeriod와 getProductCount 함수 호출
        model.productPeriod = foundProduct ? getProductPeriod(item, foundProduct) : 0;
        model.productCount = foundProduct ? getProductCount(item, foundProduct) : 0;
        model.supplyPrice = foundProduct ? foundProduct.supplyPrice : 0;
        model.totalPrice = item.paymentPrice + item.optionPrice;
        data_array.push(model);
    });

    const excelFileExtension = '.xlsx';
    const excelFileName = `tlc헬스케어 정산 엑셀파일`;

    const arrayBufferRaw = await (await fetch('/assets/raw.xlsx')).arrayBuffer();
    const workbookRaw = new ExcelJS.Workbook();

    await workbookRaw.xlsx.load(arrayBufferRaw);
    const worksheet = workbookRaw.getWorksheet('정산');

    const rowNumber = 2;
    data_array.forEach((data: RawExcelExportModel, index: number) => {
        const row = worksheet.getRow(rowNumber + index);
        [
            moment(data.dateTime).format('YYYY'),
            moment(data.dateTime).format('MM'),
            moment(data.dateTime).format('DD'),
            data.clientType,
            data.hospitalName,
            data.productType,
            data.mainCat,
            data.subCat,
            data.providerName,
            data.goods,
            data.paymentPrice,
            data.orderPlatform,
            data.recipientName,
            formatTel(data.payerMobile!),
            data.reorderCount ?? '',
            data.group_name ?? '',
            data.productSettleRate ?? '',
            data.hospitalCalPrice ?? '',
            data.supplyPrice ?? '',
            data.marginRate ?? '',
            data.marginRate ? data.paymentPrice * (data.marginRate / 100) : 0,
            data.productPeriod ?? '',
            data.productCount ?? '',
            data.mtsCat1,
            data.mtsCat2,
            data.mtsCat3,
            clientList.find((client) => client.code === data.hospitalCode)?.regionKeywordList?.[0] || '',
            clientList.find((client) => client.code === data.hospitalCode)?.regionKeywordList?.[1] || '',
            clientList.find((client) => client.code === data.hospitalCode)?.departments?.join(',') || '',
            data.orderPlatform === 'Test주문' ? '무상' : '유상',
        ].forEach((value, index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            };
        });
    });
    const excelBuffer = await workbookRaw.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(excelFile, excelFileName + '_' + moment().format('YYYYMMDD') + excelFileExtension);
};

export default DownloadCalculateExcel;
