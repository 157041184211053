import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { StatusField } from '../../../DiagnosisDashBoardPage/components/StatusField';
import { deliveryProcessingStatus, IOoUnospayDelivery } from '@api/models';
import { FlexDiv } from '@components/flexDiv';
import _ from 'lodash';
import { formatTel } from '@hooks/format';
import { useRecoilValue } from 'recoil';
import { unospaySearchByNameState } from '@recoils/search/unospaySearchByNameState';
import { unopayCalendarParamState } from '@recoils/unospay/unopayCalendarParamState';
import { OrderPlatformField } from '../OrderPlatformField';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import { auto } from '@popperjs/core';

interface Props {
    list: Array<IOoUnospayDelivery>;
}

export const List = ({ list }: Props) => {
    const calendarParam = useRecoilValue(unopayCalendarParamState);
    const searchList = useRecoilValue(unospaySearchByNameState);

    return (
        <Wrapper>
            {_.isEmpty(list) ? (
                <EmptyDiagnosisList>
                    <div>오늘의 주문 내역이 없습니다.</div>
                    <div>주문이 들어올 경우 자동 업데이트됩니다.</div>
                </EmptyDiagnosisList>
            ) : (
                <>
                    {_.sortBy(list, ['timestamp'])
                        .reverse()
                        .map((item) => {
                            const transport = _.head(item.transportInfo);
                            return (
                                <StatusRow key={item.id}>
                                    <Link
                                        to={`/unospay/unospayOrderDetail`}
                                        state={{
                                            data: item,
                                            calendarParam: calendarParam,
                                            searchList: searchList.list,
                                        }}
                                    >
                                        <StatusField data={deliveryProcessingStatus(item)} flex={1} />
                                        <OrderPlatformField data={item} flex={0.5}></OrderPlatformField>
                                        <FlexDiv flex={0.9}>{_.isEmpty(item.hospitalName) ? '-' : item.hospitalName}</FlexDiv>
                                        <FlexDiv flex={0.5}>{_.isEmpty(item.recipientName) ? '-' : item.recipientName}</FlexDiv>
                                        <FlexDiv flex={1}>{_.isEmpty(item.mobile) ? '-' : formatTel(item.mobile || '')}</FlexDiv>
                                        <FlexDiv flex={1.5}>
                                            <div>{_.isEmpty(item.address) ? '-' : item.address}</div>
                                        </FlexDiv>
                                        <FlexDiv flex={1}>
                                            <MenuContainer title={_.isEmpty(item.transportInfo) ? '-' : item.transportInfo!.map((transport: any) => `${transport.providerName}`).join('\n')}>
                                                {_.isEmpty(item.transportInfo)
                                                    ? '-'
                                                    : `${item.transportInfo![0].providerName} ${item.transportInfo!.length > 1 ? `외 ${item.transportInfo!.length - 1}개` : ''}`}
                                            </MenuContainer>
                                        </FlexDiv>
                                        <FlexDiv flex={1.5}>
                                            <MenuContainer title={_.isEmpty(item.salesMenu) ? '-' : item.salesMenu!.map((menu: any) => `${menu.menuName}(${menu.menuCount}개)`).join('\n')}>
                                                {_.isEmpty(item.salesMenu) ? '-' : `${item.salesMenu![0].menuName} ${item.salesMenu!.length > 1 ? `외 ${item.salesMenu!.length - 1}개` : ''}`}
                                            </MenuContainer>
                                        </FlexDiv>
                                    </Link>
                                </StatusRow>
                            );
                        })}
                </>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    min-width: 1024px;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;

    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

const StatusRow = styled.div`
    background: #ffffff;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 3px -2px;
    text-align: center;
    font-size: 12px;
    margin-bottom: 4px;
    width: 100%;

    & > a:first-child {
        width: 100%;
        height: 64px;
        color: #030303;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const EmptyDiagnosisList = styled.div`
    width: 1024px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const MenuContainer = styled.div`
    width: 100%;
    cursor: default;
    white-space: pre-line;
`;
