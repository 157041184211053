import { atom, atomFamily, DefaultValue, selectorFamily } from 'recoil';
import { IOoDoctor, IOoHospital } from '@api/models';
import { IOoPharmacy } from '@models/pharmacy';
import { recoilPersist } from 'recoil-persist';
import { isEmpty } from 'lodash';
import { IOoCalculateList } from '@api/models/calculate/calculateList';
import { IOoCalculateMonthList } from '@api/models/calculate/calculateMonthList';
import { ITClient } from '@api/models/client/client';

export interface SearchInfo {
    id: string;
    userName?: string;
    list?: Array<List>;
}

export type List = IOoPharmacy | IOoDoctor | IOoHospital | IOoCalculateList | IOoCalculateMonthList;

const KEY = 'listState';

const { persistAtom } = recoilPersist({
    key: KEY,
    storage: localStorage,
});

const listSearchAtom = atom<Array<SearchInfo>>({
    key: KEY,
    default: [],
    effects_UNSTABLE: [persistAtom],
});

const listSearchAtomFamily = atomFamily<SearchInfo, string>({
    key: 'listSearchAtomFamily',
    default: (id) => {
        const item: SearchInfo = {
            id,
            userName: '',
            list: [],
        };
        return item;
    },
});

export const listState = selectorFamily<SearchInfo, string>({
    key: 'listState',
    get:
        (id: string) =>
        ({ get }) =>
            get(listSearchAtomFamily(id)),
    set:
        (id: string) =>
        ({ get, set, reset }, SearchInfo) => {
            if (SearchInfo instanceof DefaultValue) {
                reset(listSearchAtomFamily(id));
                set(listSearchAtom, (prevValue) => prevValue.filter((item) => item.id !== id));
                return;
            }
            set(listSearchAtomFamily(id), SearchInfo);
            set(listSearchAtom, (prev) => filteredArr(prev, SearchInfo));
        },
});

// 중복을 제거하고, 최신 데이터로만 넣기
function filteredArr(prev: SearchInfo[], next: SearchInfo) {
    const filterEmpty = prev.filter((item) => !isEmpty(item.id) && !isEmpty(item.list));
    const index = filterEmpty.findIndex((item) => item.id === next.id);
    if (index < 0) {
        return Array.from(new Set([...filterEmpty, next]));
    } else {
        filterEmpty.splice(index, 1, next);
        return filterEmpty;
    }
}
