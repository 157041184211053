import { ITPagination } from '../pagination/customPaginationType';

export interface ITReorderAlimTalk {
    id: string;
    orderId: string;
    reorderId?: string;
    sendDate: string;
    productName: string;
    externalCode: string;
    mobile: string;
    sendStatus: number;
    sendTimestamp: number;
    reorderTimestamp: number;
    timestamp: number;
}

export interface GetReorderMessageListApiResponse {
    messages: ITReorderAlimTalk[];
    pagination: ITPagination;
}

export interface ReorderAlimtalkListTableProps {
    data: ITReorderAlimTalk[];
    // totalCount: number;
    currentPage: number;
    sectionIndex: number;
    totalPages: number;
    onChangeCurrentPage: (page: number, newGroup?: number) => void;
    onChangeSectionIdx: (group: number, newPage: number) => void;
    clickList: (id: string) => void;
    clickChangeReorderStatusButton: (id: string) => void;
    handleUpdateReorder: (id: string, mobile?: string) => void;
    editStates: Record<string, boolean>;
    mobileValues: Record<string, string>;
    handleEditClick: (id: string) => void;
    handleInputChange: (id: string, value: string) => void;
}

export type ChangeReorderMessageStatusRqData = {
    id: string;
    status: number;
};
export type UpdateReorderMessageRqData = {
    id: string;
    mobile?: string;
};

export interface ReorderAlimtalkDropdownProps {
    selected: number;
    handleDropdown: (idx: number, type?: string) => void;
    list: string[];
    type?: string;
    $height?: string;
    $fontSize?: string;
    $padding?: string;
    $justifyContent?: string;
}

export const ReorderAlimtalkFiltering: string[] = ['전체', '전송전', '전송됨', '취소'];

export enum ReorderStatus {
    STANDBY = 0,
    SENT = 1,
    CANCELED = 2,
}
