import { IOoUnospayDelivery, IOoUnospaySalesMenu, IOoUnospaySalesMenuOption, OoDeliveryStatus, OoProvider } from '@api/models'
import React from 'react'
import { exCodeNumToStr, listProviderFilter } from '../../ExcelDownloadModal';
import { ExcelExportModel } from '../model';
import _ from 'lodash';
import FileSaver from 'file-saver';
import moment from 'moment';
import { IOoProducts } from '@api/models/productSetting/product';
import { ITProduct } from '@api/models/product/product';
const ExcelJS = require("exceljs");

/**
 * @description 동아오츠카 발주서 양식 다운로드
 */

const createModel = (e:IOoUnospayDelivery, salesItem:IOoUnospaySalesMenu) => {
    const model = new ExcelExportModel();
                  model.name = e.recipientName;
                  model.address1 = e.address;
                  model.address2 = e.addressDetail;
                  model.tel1 = e.mobile?.replace("\t", "").split('-').join('');
                  model.zipCode = e.zipCode;
                  model.memo = e.memo;
                  model.subMemo = e.subMemo;
                  model.quantity = "1";
                  model.deliveryCost = e.id;
    return model;
  }

const  findProductInfo = (productList:ITProduct[], salesItem: IOoUnospaySalesMenu) => {
    const productInfo = productList.filter(item => item.externalCode === `${salesItem.externalCode}`)[0];
    return productInfo;
}

const dongaotsukaOrder = async (list:IOoUnospayDelivery[], productList:ITProduct[]) => {
    
    const excelList = listProviderFilter(list , exCodeNumToStr(OoProvider.dongaotsukaProvider));
    let data_array: Array<ExcelExportModel> = [];

    console.log('excelList :: ', excelList);
    
    excelList.forEach((e, index) => {
        if(e.status === OoDeliveryStatus.existAddress || e.status === OoDeliveryStatus.sendPackage){
            e.salesMenu?.forEach(salesItem => {
                if(!_.isUndefined(salesItem.salesOption) && salesItem.salesOption.length > 0){
                    const findProductData = findProductInfo(productList, salesItem);
                    let model = createModel(e, salesItem);
                    model.product = findProductData.productName;
                    salesItem.salesOption.forEach((option, index) => {
                        const optionInfo = findProductData.optionList?.find(item => item.optionCode === option.externalCode);
                        if(!_.isEmpty(optionInfo) && !_.isUndefined(optionInfo.optionName)){
                            model.product += ` ${optionInfo.optionOrderName}`;
                            model.price = findProductData.retailPrice.toString();
                            model.productCode =  optionInfo.optionOrderCode;
                            model.deliveryDate = moment(e.timestamp).format('YYYYMMDD');
                            data_array.push(model);
                        }
                    })
                }else{
                    const findProductData = findProductInfo(productList, salesItem);
                    
                    if(!_.isUndefined(findProductData)){
                        let model = createModel(e, salesItem);
                        model.product = _.isEmpty(findProductData.productName) ? findProductData.orderProductName : findProductData.productName;
                        model.price = findProductData.retailPrice.toString();
                        model.productCode = findProductData.orderProductCode;
                        model.deliveryDate = moment(e.timestamp).format('YYYYMMDD');
                        data_array.push(model);
                    }
                }
            })
        }
    });

    
    const excelFileExtension = ".xlsx";
    const excelFileName = `tlc헬스케어 발주서`;

    const arrayBufferDAOTSK = await (await fetch("/assets/daostk.xlsx")).arrayBuffer();
    const workbookDAOTSK = new ExcelJS.Workbook();
    await workbookDAOTSK.xlsx.load(arrayBufferDAOTSK);
    const ws_DAOTSK = workbookDAOTSK.getWorksheet("양식");
    console.log(data_array);
    
    data_array.map((data: ExcelExportModel, index: number) => {
        const row = ws_DAOTSK.getRow(index + 2);
        [
            index+1,
            data.name,
            data.tel1 ? `${data.tel1?.substring(0, 3)}-${data.tel1?.substring(3, 7)}-${data.tel1?.substring(7)}` : "",
            "",
            data.zipCode,
            data.address1 + " " + data.address2,
            data.product,
            data.quantity,
            data.memo,
            "",
            "",
            "",
            "",
            data.price!.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            `TLC${data.deliveryDate}${String(index+1).padStart(4,'0')}`,
            data.productCode,
            "티엘씨 헬스케어"
        ].forEach((value , index) => {
            const cell = row.getCell(index + 1);
            cell.value = value;
            cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            };
        })
    });
    const excelBuffer = await workbookDAOTSK.xlsx.writeBuffer();
    const excelFile = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(
      excelFile,
      excelFileName+"_"+moment().format("YYYYMMDD")+ "(동아오츠카)" + excelFileExtension
    );
}

export default dongaotsukaOrder