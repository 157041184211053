import {AbstractOoParam} from "../base";

class OoUnospaySendTransportAlimtalk implements AbstractOoParam {
    // 주문자 식별 id
    id: string = '';

    orderNum: number = 0;

    getPathParams(): string {
        return "";
    }
}


export {OoUnospaySendTransportAlimtalk};