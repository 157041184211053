import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListTitle } from '@components/listTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import _, { last } from 'lodash';
import { useRecoilValue } from 'recoil';
import { selectedUserState } from '@recoils/oauth';
import 'react-calendar/dist/Calendar.css';
import { DoctorOnAPI } from '@api/request';
import { ListSubTitle } from '../ListPage/components';
import { isRequestSucceed } from '@api/reponses';
import BasicButton from '@components/editButton/BasicButton';
import { ITClientNotice } from '@api/models/notice/clientNotice/notice';
import ClientNoticeListTable from './ClientNoticeListTable';
import usePaginationStateHooks from '@hooks/common/usePaginationStateHooks';

const ClientNoticeListPage = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [clientTarget, setClientTarget] = useState<string>('');
    const [target, setTarget] = useState<string>('');
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>();
    const [clientNoticeData, setClientNoticeData] = useState<ITClientNotice[]>();

    const { setPage, currentPage, pageGroup, handlePageGroup } = usePaginationStateHooks(clientNoticeData);

    const selectedUser = useRecoilValue(selectedUserState);

    useEffect(() => {
        clientNoticeListSearchApi(currentPage, 10, clientTarget);
    }, [currentPage]);

    useEffect(() => {
        const path = last(pathname.split('/') || '') || '';
        setTarget(path);
    }, [pathname]);

    // clientNotice list search api func
    const clientNoticeListSearchApi = async (page: number, limit: number, target?: string) => {
        try {
            const response = await DoctorOnAPI.shared.smsHospital.clientNoticeList(page, limit, target);

            if (isRequestSucceed(response)) {
                setClientNoticeData(response.data?.list);
                setTotalPages(response.data?.pagination.totalPages || 0);
                setTotalCount(response.data?.pagination.totalCount || 0);
            }
            return response;
        } catch (e: any) {
            console.log(e);
        }
    };

    // 등록하기 버튼
    const clickRegisterButton = () => {
        navigate('/clientNoticeList/addClientNotice');
    };

    return (
        <Wrapper>
            <ListTitle title='공지사항' totalCount={totalCount}>
                <SearchContainer>
                    <BasicButton $width='100px' $height='40px' $type='outLine' onClick={clickRegisterButton}>
                        등록
                    </BasicButton>
                </SearchContainer>
            </ListTitle>
            <ListSubTitle target={target} level={selectedUser && selectedUser.user.level} />
            {clientNoticeData && clientNoticeData.length > 0 ? (
                <ClientNoticeListTable
                    data={clientNoticeData}
                    currentPage={currentPage}
                    onChangeCurrentPage={setPage}
                    onChangeSectionIdx={handlePageGroup}
                    sectionIndex={pageGroup}
                    totalPages={totalPages}
                />
            ) : (
                <NoListContainer>표시 할 내용이 없습니다.</NoListContainer>
            )}
        </Wrapper>
    );
};

export default ClientNoticeListPage;

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 4px;
`;

const SearchContainer = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 24px;
`;

const NoListContainer = styled.div`
    height: 100%;
    @media (min-height: 768px) {
        height: 585px;
    }

    @media (min-height: 1024px) {
        height: 75%;
    }
`;
