import React, { Dispatch, SetStateAction } from 'react';
import { DropdownWithLabel } from '@components/dropdown/clientTypeDropdown';
import BasicButton from '@components/editButton/BasicButton';
import { ColumnInputWithLabel } from '@components/input/input';
import { ColumnDivider } from '@styles/common/commonStyles';
import { Body3_SemiBold } from '@styles/typography';
import styled from 'styled-components';
import { ClientInfoProps, clientTypeList } from 'models/clients/addClientPageTypes';
import { PostCode } from '@components/postCode';

const ClientInfo = ({
    onHospitalInfoChange,
    name,
    inputEdit,
    code,
    tel,
    zipCode,
    address,
    addressDetail,
    bank,
    bankAccount,
    bankHolderName,
    referrerList,
    businessNumber,
    clientType,
    handleDropdown,
    onDropdown,
    setOnDropdown,
    state,
    clickCancelButton,
    createClientApi,
    setInputEdit,
    clickEditButton,
}: ClientInfoProps) => {
    return (
        <>
            <ColumnDivider>
                <Title>거래처 기본 정보</Title>
                <RowWrapper>
                    <ColumnDivider>
                        <ColumnInputWithLabel label='병원명' placeholder='병원명을 입력해주세요.' onChange={onHospitalInfoChange} value={name} type='name' inputEdit={inputEdit} $info={true} />
                        {state && <ColumnInputWithLabel label='코드' value={code} type='code' inputEdit={false} $info={true} />}
                        <ColumnInputWithLabel label='전화번호' placeholder='전화번호를 입력해주세요.' onChange={onHospitalInfoChange} value={tel} type='tel' inputEdit={inputEdit} $info={true} />
                        <PostCode type='zipCode' inputEdit={inputEdit} zipCode={zipCode} onHospitalInfoChange={onHospitalInfoChange} />
                        <ColumnInputWithLabel label='주소' placeholder='주소를 입력해주세요.' onChange={onHospitalInfoChange} value={address} type='address' inputEdit={inputEdit} $info={true} />
                        <ColumnInputWithLabel
                            label='상세주소'
                            placeholder='상세주소를 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={addressDetail}
                            type='addressDetail'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                        {!state && <div style={{ width: '100%', height: '50px' }} />}
                    </ColumnDivider>
                    <ColumnDivider>
                        <ColumnInputWithLabel label='은행명' placeholder='은행명을 입력해주세요.' onChange={onHospitalInfoChange} value={bank} type='bank' inputEdit={inputEdit} $info={true} />
                        <ColumnInputWithLabel
                            label='계좌번호'
                            placeholder='계좌번호를 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={bankAccount}
                            type='bankAccount'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                        <ColumnInputWithLabel
                            label='예금주명'
                            placeholder='예금주명을 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={bankHolderName}
                            type='bankHolderName'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                        <ColumnInputWithLabel
                            label='추천인'
                            placeholder='추천인 / 추천병원을 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={referrerList[0]}
                            type='referrerList'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                        <ColumnInputWithLabel
                            label='사업자번호'
                            placeholder='사업자번호를 입력해주세요.'
                            onChange={onHospitalInfoChange}
                            value={businessNumber}
                            type='businessNumber'
                            inputEdit={inputEdit}
                            $info={true}
                        />
                        <DropdownWithLabel
                            label='병원 타입'
                            selected={clientType}
                            handleDropdown={handleDropdown}
                            type='clientType'
                            list={clientTypeList}
                            onDropdown={onDropdown}
                            setOnDropdown={setOnDropdown}
                            buttonEdit={inputEdit}
                            $info={true}
                        />
                    </ColumnDivider>
                </RowWrapper>
            </ColumnDivider>
            {!state && (
                <ButtonWrapper $position='absolute' $width='auto'>
                    <BasicButton $type='warning' $width='100px' $height='30px' onClick={clickCancelButton}>
                        취소하기
                    </BasicButton>
                    <BasicButton $type='fill' $width='100px' $height='30px' onClick={createClientApi} disabled={!name || !tel || !address || !zipCode}>
                        등록하기
                    </BasicButton>
                </ButtonWrapper>
            )}
            {state && (
                <ButtonWrapper $position='absolute' $width='auto'>
                    {!inputEdit ? (
                        <>
                            <BasicButton $type='warning' $width='100px' $height='30px' onClick={() => clickEditButton('delete')}>
                                삭제하기
                            </BasicButton>
                            <BasicButton $type='outLine' $width='100px' $height='30px' onClick={() => setInputEdit(true)}>
                                수정하기
                            </BasicButton>
                        </>
                    ) : (
                        <>
                            <BasicButton $type='warning' $width='100px' $height='30px' onClick={() => clickEditButton('cancel')}>
                                취소하기
                            </BasicButton>
                            <BasicButton $type='fill' $width='100px' $height='30px' onClick={() => clickEditButton('edit')} disabled={!name || !tel || !address || !zipCode}>
                                수정하기
                            </BasicButton>
                        </>
                    )}
                </ButtonWrapper>
            )}
        </>
    );
};

export default ClientInfo;

const RowWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled(Body3_SemiBold)`
    line-height: none;
    margin-bottom: 20px;
`;

const ButtonWrapper = styled.div<{ $position?: string; $width?: string }>`
    position: ${(props) => props.$position};
    right: 40px;
    top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.$width || '400px'};
    gap: 20px;
`;
