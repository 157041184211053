import { ITClient } from '@api/models/client/client';
import { LocationState } from 'models/common/commonTypes';
import { Dispatch, SetStateAction } from 'react';

export interface ClientInfoProps {
    onHospitalInfoChange: (e: string, type: string) => void;
    inputEdit: boolean;
    name: string;
    code: string;
    tel: string;
    zipCode: string;
    address: string;
    addressDetail: string;
    bank: string;
    bankAccount: string;
    bankHolderName: string;
    referrerList: string[];
    businessNumber: string;
    clientType: number;
    handleDropdown: (idx: number, type?: string) => void;
    onDropdown: boolean;
    setOnDropdown: Dispatch<SetStateAction<boolean>>;
    state: LocationState;
    clickCancelButton: () => void;
    createClientApi: () => void;
    setInputEdit: Dispatch<SetStateAction<boolean>>;
    clickEditButton: (type: string) => void;
}

export interface AdminInfoProps {
    onHospitalInfoChange: (e: string, type: string) => void;
    initData?: ITClient;
    isUserInfoEdit: boolean;
    clickUserInfoEditButton: (type: string) => void;
    email: string;
    password: string;
    passwordConfirmation: string;
    acceptSMS: number;
    alimTalkReceivers: string[];
    setAlimTalkReceivers: Dispatch<SetStateAction<string[]>>;
    handleDropdown: (idx: number, type?: string) => void;
    onSMSDropdown: boolean;
    setOnSMSDropdown: Dispatch<SetStateAction<boolean>>;
    passwordVisible: boolean;
    setPasswordVisible: Dispatch<SetStateAction<boolean>>;
    passwordErr: string;
    passwordConfirmationErr: string;
}

export const acceptSMSList = ['Y', 'N'];

export const clientTypeList = ['병의원', '한의원', 'B2B', '피트니스'];

export const filterClientTypeList = ['전체', '병의원', '한의원', 'B2B', '피트니스'];
