import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { DoctorOnAPI } from '@request/doctorOnAPI';
import { isRequestSucceed } from '../../api';
import { useSuspenseHook } from '@hooks/suspense';

export const useAlimtalkListQuery = (page: number, limit: number, sendStatus: number | null, keyword?: string) => {
    const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
    return useQuery(
        ['alimtalkListQuery', page, limit, sendStatus, keyword],
        async () => {
            try {
                setLoading();
                const response = await DoctorOnAPI.shared.unospay.reorderMessageList(page, limit, sendStatus);
                setStopLoading();
                if (isRequestSucceed(response)) {
                    return response.data;
                }
                return undefined;
            } catch (e: any) {
                setErrorStatus(e);
            }
        },
        {
            // enabled: _.isEmpty(list), // 리스트가 비어있을 때만 요청 실행
            select: (data) => {
                return {
                    alimtalks: data?.messages,
                    pagination: data?.pagination,
                };
            },
            retry: true,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        }
    );
};
