import { DropdownWithLabel } from '@components/dropdown/clientTypeDropdown';
import BasicButton from '@components/editButton/BasicButton';
import { AddAndDeleteColumnInputWithLabel, ColumnInputWithLabel } from '@components/input/input';
import { ColumnDivider } from '@styles/common/commonStyles';
import { Body3_SemiBold, Body4_Medium } from '@styles/typography';
import { acceptSMSList, AdminInfoProps } from 'models/clients/addClientPageTypes';
import styled from 'styled-components';

const AdminInfo = ({
    initData,
    isUserInfoEdit,
    onHospitalInfoChange,
    email,
    password,
    setPasswordVisible,
    passwordVisible,
    passwordErr,
    passwordConfirmation,
    passwordConfirmationErr,
    acceptSMS,
    handleDropdown,
    onSMSDropdown,
    setOnSMSDropdown,
    alimTalkReceivers,
    clickUserInfoEditButton,
    setAlimTalkReceivers,
}: AdminInfoProps) => {
    return (
        <>
            <Title>계정 기본 정보</Title>
            <ColumnDivider>
                {initData?.adminAccount || isUserInfoEdit ? (
                    <>
                        <ColumnInputWithLabel label='계정ID' onChange={onHospitalInfoChange} value={email} type='계정아이디' inputEdit={false} $info={true} />
                        {isUserInfoEdit && !initData?.adminAccount && (
                            <>
                                <ColumnInputWithLabel
                                    label='비밀번호'
                                    placeholder='비밀번호를 입력해주세요.'
                                    onChange={onHospitalInfoChange}
                                    value={password}
                                    type='password'
                                    inputEdit={isUserInfoEdit}
                                    setIsPasswordVisible={() => setPasswordVisible(!passwordVisible)}
                                    isPasswordVisible={passwordVisible}
                                    errMsg={passwordErr}
                                    $info={true}
                                />
                                <ColumnInputWithLabel
                                    label='비밀번호 확인'
                                    placeholder='비밀번호 다시 한 번 입력해주세요.'
                                    onChange={onHospitalInfoChange}
                                    value={passwordConfirmation}
                                    type='passwordConfirmation'
                                    inputEdit={isUserInfoEdit}
                                    setIsPasswordVisible={() => setPasswordVisible(!passwordVisible)}
                                    isPasswordVisible={passwordVisible}
                                    errMsg={passwordConfirmationErr}
                                    $info={true}
                                />
                            </>
                        )}
                        <DropdownWithLabel
                            label='알림톡 수신'
                            selected={acceptSMS}
                            handleDropdown={handleDropdown}
                            type='acceptSMS'
                            list={acceptSMSList}
                            onDropdown={onSMSDropdown}
                            setOnDropdown={setOnSMSDropdown}
                            buttonEdit={isUserInfoEdit}
                            $dropdownHeight='88px'
                            $info={true}
                        />
                        {acceptSMS === 0 && (
                            <AddAndDeleteColumnInputWithLabel
                                label='수신 번호'
                                placeholder='번호를 입력해주세요.'
                                onChange={onHospitalInfoChange}
                                value={alimTalkReceivers}
                                setValue={setAlimTalkReceivers}
                                type='alimTalkReceivers'
                                inputEdit={isUserInfoEdit}
                            />
                        )}
                    </>
                ) : (
                    <Body4_Medium>등록된 정보가 없습니다.</Body4_Medium>
                )}
            </ColumnDivider>
            <ButtonWrapper $position='absolute' $width='auto'>
                {!isUserInfoEdit ? (
                    <>
                        {initData?.adminAccount && (
                            <BasicButton $type='warning' $width='100px' $height='30px' onClick={() => clickUserInfoEditButton('delete')}>
                                삭제하기
                            </BasicButton>
                        )}
                        <BasicButton $type='outLine' $width='100px' $height='30px' onClick={clickUserInfoEditButton}>
                            {!initData?.adminAccount ? '등록하기' : '수정하기'}
                        </BasicButton>
                    </>
                ) : (
                    <>
                        <BasicButton $type='warning' $width='100px' $height='30px' onClick={() => clickUserInfoEditButton('cancel')}>
                            취소하기
                        </BasicButton>
                        <BasicButton
                            $type='fill'
                            $width='100px'
                            $height='30px'
                            onClick={() => clickUserInfoEditButton(!initData?.adminAccount ? 'create' : 'update')}
                            disabled={passwordErr.length !== 0 || passwordConfirmationErr.length !== 0 || (acceptSMS === 0 && alimTalkReceivers.length === 0)}
                        >
                            {!initData?.adminAccount ? '등록하기' : '수정하기'}
                        </BasicButton>
                    </>
                )}
            </ButtonWrapper>
        </>
    );
};

export default AdminInfo;

const Title = styled(Body3_SemiBold)`
    line-height: none;
    margin-bottom: 20px;
`;
const ButtonWrapper = styled.div<{ $position?: string; $width?: string }>`
    position: ${(props) => props.$position};
    right: 40px;
    top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.$width || '400px'};
    gap: 20px;
`;
