import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { filterFamilyDiagnosisReversed, IOoDiagnosis, isMyFamilyMember } from '../../api';
import { originDiagnosisRtdbState } from '@recoils/realtimeDatabase';
import { UseDiagnosisRtdbHook } from '@hooks/realtimeDatabase';
import { SearchParamProps } from '@recoils/doctor';
import { useDisplayHook } from '@hooks/common/useDisplayHook';
import { CalendarParamProp } from '@recoils/unospay';
import { useOperatorSearchQuery } from '@queries/search';
import { useDailyDiagnosisList } from '@queries/operator';

interface Props {
    list: Array<IOoDiagnosis>;
    calendarParam: CalendarParamProp;
    searchParam: SearchParamProps;
}

export const useDiagnosisHook = ({ list, calendarParam, searchParam }: Props) => {
    const target = 'operator';


    const { connect, disconnect } = UseDiagnosisRtdbHook();

    const { displayList, updateDisplay } = useDisplayHook(target);

    const rtdbList = useRecoilValue(originDiagnosisRtdbState);

    const [queryTrigger, setQueryTrigger] = useState({
        findQueryTrigger: true,
        searchQueryTrigger: false,
        calendarQueryTrigger: false,
    });

    const searchQueryResp = useOperatorSearchQuery({
        searchName: searchParam.searchName,
        searchQueryTrigger: queryTrigger.searchQueryTrigger,
        target: target,
    });

    const selectedDiagnosisQueryResp = useDailyDiagnosisList({
        beginTimestamp: calendarParam.beginTimestamp,
        endTimestamp: calendarParam.endTimestamp,
        count: calendarParam.count || '100',
        diagnosisId: calendarParam.diagnosisId || '',
        updateQueryTrigger: queryTrigger.calendarQueryTrigger,
    });

    useEffect(() => {
        connect('allTeleMedicine');
        return () => disconnect();
    }, []);

    useEffect(() => {
        setQueryTrigger({
            findQueryTrigger: true,
            searchQueryTrigger: false,
            calendarQueryTrigger: false,
        });
    }, []);

    useEffect(() => {
        const calendarCopied = _.cloneDeep(calendarParam);
        const searchCopied = _.cloneDeep(searchParam);

        const filteredCalendarObj = _.omit(calendarCopied, ['diagnosisId']);
        const filteredSearchObj = _.omit(searchCopied, ['id']);

        if (!_.every(filteredCalendarObj, (param) => _.isEmpty(param))) {
            setQueryTrigger({ ...queryTrigger, calendarQueryTrigger: true });
            return;
        } else if (!_.every(filteredSearchObj, (param) => _.isEmpty(param))) {
            setQueryTrigger({ ...queryTrigger, searchQueryTrigger: true });
            return;
        } else {
            const diagnosisList = makeDiagnosisList(_.head(list)!.id || '', rtdbList);
            updateDisplay(diagnosisList);
            return;
        }
    }, [list, calendarParam, searchParam, rtdbList]);

    useEffect(() => {
        if (!_.isUndefined(searchQueryResp.data)) {
            const searchDiagnosisList = makeDiagnosisList(_.head(list)!.id || '', searchQueryResp.data || []);
            updateDisplay(searchDiagnosisList);
            setQueryTrigger({ ...queryTrigger, searchQueryTrigger: false });
            return;
        }
        if (!_.isUndefined(selectedDiagnosisQueryResp.data)) {
            const selectedDiagnosisList = makeDiagnosisList(_.head(list)?.id || '', selectedDiagnosisQueryResp.data || []);
            updateDisplay(selectedDiagnosisList);
            setQueryTrigger({ ...queryTrigger, calendarQueryTrigger: false });
            return;
        }
        const diagnosisList = makeDiagnosisList(_.head(list)!.id || '', rtdbList);
        updateDisplay(diagnosisList);
    }, [queryTrigger.findQueryTrigger, searchQueryResp.data, selectedDiagnosisQueryResp.data]);

    // 진료 리스트 생성
    function makeDiagnosisList(id: string, diagnosisList: Array<IOoDiagnosis>): Array<IOoDiagnosis> {
        const target = findTarget(id, diagnosisList);
        if (_.isUndefined(target)) return [];
        const targetFamily = findTargetFamily(target, diagnosisList);
        return _.concat(target, targetFamily);
    }

    // 진료 데이터
    function findTarget(id: string, diagnosisList: Array<IOoDiagnosis>): IOoDiagnosis | undefined {
        return _.find(diagnosisList, (d) => _.isEqual(d.id || '', id));
    }

    // 가족 진료 데이터
    function findTargetFamily(target: IOoDiagnosis, diagnosisList: Array<IOoDiagnosis>) {
        const predicate = (item: IOoDiagnosis, target: IOoDiagnosis) => isMyFamilyMember(target, item);
        const findFamilyList = (target: IOoDiagnosis) => filterFamilyDiagnosisReversed(diagnosisList, (d) => predicate(d, target));
        return findFamilyList(target);
    }

    return {
        displayList,
        findTarget,
        findTargetFamily,
    };
};
