import { Link } from 'react-router-dom';
import _ from 'lodash';
import CustomedPagination from '@components/pagination/CustomedPagination';
import { ListTable } from '@styles/tableStyles';
import { ReorderAlimtalkListTableProps } from '@api/models/reorderAlimtalk/reorderAlimtalkModels';
import styled from 'styled-components';
import { ReorderAlimtalkStateBadge } from '@components/badge/Badge';
import { Accent, BackgroundColor } from '@styles/colorSemantic';
import { Red } from '@styles/colorPrimitive';
import { formatTel, formatYyyyMMDD } from '@hooks/format';
import { ReorderAlimtalkStatus } from '@api/models/reorderAlimtalk/reorderAlimtalkEnum';
import BasicButton from '@components/editButton/BasicButton';
import IcArrowLineRoundRight from '@assets/icons/pagination/IcArrowLineRoundRight';
import { MdCancel, MdEdit, MdOutlineCancel, MdOutlineDone } from 'react-icons/md';
import { Input } from '@styles/input';
import { font } from '@styles/globalStyles';

const ReorderAlimTalkListTable = ({
    data,
    currentPage,
    sectionIndex,
    totalPages,
    onChangeCurrentPage,
    onChangeSectionIdx,
    clickList,
    clickChangeReorderStatusButton,
    handleUpdateReorder,
    editStates,
    mobileValues,
    handleEditClick,
    handleInputChange,
}: ReorderAlimtalkListTableProps) => {
    return (
        <ListTable.Wrapper>
            {data.map((item, index) => {
                const backgroundColor = () => {
                    if (item.sendStatus === ReorderAlimtalkStatus.STANDBY) {
                        return Accent.color_accent_yellow_weak;
                    } else if (item.sendStatus === ReorderAlimtalkStatus.SEND) {
                        return BackgroundColor.color_background_primary_strong;
                    } else {
                        return Red.color_red_200;
                    }
                };

                const text = () => {
                    if (item.sendStatus === ReorderAlimtalkStatus.STANDBY) {
                        return '전송전';
                    } else if (item.sendStatus === ReorderAlimtalkStatus.SEND) {
                        return '완료';
                    } else {
                        return '취소';
                    }
                };

                return (
                    // <SpareTag key={index} style={{ textDecoration: 'none', color: 'black', overflow: 'visible' }} onClick={() => clickList(item.orderId)}>
                    <SpareTag key={index} style={{ textDecoration: 'none', color: 'black', overflow: 'visible' }}>
                        {/* <Link key={index} to={`/productList/addProduct`} state={{ id: item._id }} style={{ textDecoration: 'none', color: 'black', overflow: 'visible' }}> */}
                        <ListTable.NoHoverListContainer>
                            <div style={{ position: 'relative' }}>
                                {item.externalCode}
                                {item.reorderId && (
                                    <ReorderAlimtalkStateBadge position='absolute' backgroundColor={Accent.color_accent_lime_light}>
                                        재구매
                                    </ReorderAlimtalkStateBadge>
                                )}
                            </div>
                            <div>{item.productName}</div>
                            <FlexWrapper>
                                <Input
                                    name='mobile'
                                    $fontSize={font.size[12]}
                                    $padding='0 0 0 2px'
                                    $width='100px'
                                    $height='20px'
                                    value={editStates[item.id] ? mobileValues[item.id] : formatTel(item.mobile)}
                                    onChange={(e) => handleInputChange(item.id, e.target.value)}
                                    readOnly={!editStates[item.id]}
                                    $state={editStates[item.id]}
                                    maxLength={13}
                                />

                                {editStates[item.id] ? (
                                    <>
                                        <BasicButton $type='fill' $width='20px' $height='20px' onClick={() => handleUpdateReorder(item.id)}>
                                            <MdOutlineDone />
                                        </BasicButton>
                                        <BasicButton $type='warning' $width='20px' $height='20px' onClick={() => handleEditClick(item.id)}>
                                            <MdOutlineCancel />
                                        </BasicButton>
                                    </>
                                ) : (
                                    <BasicButton $type='anotherFill' $width='20px' $height='20px' onClick={() => handleEditClick(item.id)}>
                                        <MdEdit />
                                    </BasicButton>
                                )}
                            </FlexWrapper>
                            <div>{formatYyyyMMDD(item.sendDate)}</div>
                            <FlexWrapper>
                                <ReorderAlimtalkStateBadge backgroundColor={backgroundColor()}>{text()}</ReorderAlimtalkStateBadge>
                                {item.sendStatus === ReorderAlimtalkStatus.STANDBY && (
                                    <BasicButton $type='warning' $width='20px' $height='20px' onClick={() => clickChangeReorderStatusButton(item.id)}>
                                        <MdCancel />
                                    </BasicButton>
                                )}
                            </FlexWrapper>
                            <IconWrapper onClick={() => clickList(item.orderId)}>
                                <IcArrowLineRoundRight />
                            </IconWrapper>
                        </ListTable.NoHoverListContainer>
                    </SpareTag>
                );
            })}
            <CustomedPagination currentPage={currentPage} onChangeCurrentPage={onChangeCurrentPage} onChangeSectionIdx={onChangeSectionIdx} sectionIndex={sectionIndex} totalPages={totalPages} />
        </ListTable.Wrapper>
    );
};

export default ReorderAlimTalkListTable;

const SpareTag = styled.div`
    text-decoration: none;
    color: black;
    overflow: visible;
`;

const FlexWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
`;

const IconWrapper = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100%;
    cursor: pointer;
`;
