import React from 'react';
import styled from 'styled-components';
import DataCheckListDashBoard from '../CalculatePage/components/DataCheckListDashBoard';

const CalculateDataListPage = () => {
    return (
        <Layout>
            <TopContainer>
                <DataCheckListDashBoard />
            </TopContainer>
        </Layout>
    );
};

export default CalculateDataListPage;

const Layout = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

const TopContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    top: 130px;
    align-items: center;
`;
