import React from 'react';
import styled from 'styled-components';
import ListDashBoard from '../CalculatePage/components/ListDashBoard';

const CalculateListPage = () => {
    return (
        <Layout>
            <TopContainer>
                <ListDashBoard />
            </TopContainer>
        </Layout>
    );
};

export default CalculateListPage;

const Layout = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

const TopContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    top: 130px;
    align-items: center;
`;
