import { useSuspenseHook } from "../../hooks";
import { useMutation } from "react-query";
import { DoctorOnAPI, isRequestSucceed } from "../../api";

interface Props {
  id: string;
  date: string;
}

export const useUpdatePaymentCancelInfoQuery = () => {
  const { setLoading, setStopLoading, setErrorStatus } = useSuspenseHook();
  return useMutation(
    "updatePaymentCancelInfo",
    async (param: {id: string, date: string}) => {   
      try {
        setLoading();
        const response =
          await DoctorOnAPI.shared.unospay.updatePaymentCancelInfo(
            param.id, param.date
          );
        setStopLoading();
        if (isRequestSucceed(response)) {
          return response;
        }
        return undefined;
      } catch (e: any) {
        setErrorStatus(e);
      }
    },
    {
      retry: true,
    }
  );
};
