import { IOoUnospayDelivery } from '@models/unospay';
import { unospayListState } from '@recoils/unospay';
import _ from 'lodash';
import moment from 'moment';
import { useSetRecoilState } from 'recoil';
import { UnospayRtdbSubscriber } from '@realtimeDatabase/unospayRtdb';

export const useUnospayRtdbHook = () => {
    const setList = useSetRecoilState(unospayListState);
    // 약배송 신청 전인 상태는 오늘 데이터만 표시, 약배송 신청 상태는 약배송 신청 날짜로 표시
    const isTodayDelivery = (delivery: IOoUnospayDelivery) => {
        const startOfDay = moment().startOf('day').toDate().getTime();
        const endOfDay = moment().endOf('day').toDate().getTime();

        return delivery.timestamp >= startOfDay && delivery.timestamp <= endOfDay;
    };

    const onValue = (deliveryList: Array<IOoUnospayDelivery>) => {
        const todayList = _.chain(deliveryList)
            .filter((d) => isTodayDelivery(d))
            .sortBy((d) => d.timestamp)
            .reverse()
            .value();
        setList(todayList);
    };

    const connect = (path: string) => {
        UnospayRtdbSubscriber.shared().subscribe(path, onValue);
    };

    const disconnect = () => {
        UnospayRtdbSubscriber.shared().unsubscribe();
    };

    return {
        connect,
        disconnect,
    };
};
